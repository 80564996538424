import { useFormContext } from "react-hook-form";

import { FormControl, FormHelperText, Grid, Select, TextField, Typography } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";

import { useState } from "react";
import { changeCategory } from "../../../context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "../../../context/hooks/hooks";
import { TInscriptionData } from "../../../utils/schemas/inscription";
import Button from "../../button/Button";
import { toastMessageError, toastMessageSuccess } from "../../common/toastMessage";
import CustomDialog from "../../customDialog/CustomDialog";
import dayjs from "dayjs";

type Props = {
  stocks?: {
    eventId: string;
    categoryId: string;
    stock: number;
  }[];
  categoryId?: string;
  inscriptionId?: string;
  isRealized?: boolean;
  weighingChangeLimitDate?: string;
};

export default function SportInfo({
  stocks,
  categoryId,
  inscriptionId,
  isRealized,
  weighingChangeLimitDate,
}: Props) {
  const { register, formState, watch, setValue } = useFormContext<TInscriptionData>();
  const { errors } = formState;
  const dispatch = useAppDispatch();
  const { categories } = useAppSelector((state) => state.formsOptions);
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [openDialog, setOpenDialog] = useState<boolean>(false);

  const handleButtonClick = () => {
    if (isEdit) {
      setOpenDialog(true);
    }
    setIsEdit(!isEdit);
  };

  const handleChangeCategory = async () => {
    const changeResponse = await dispatch(
      changeCategory({
        data: { newCategoryId: watch("categoryId") },
        id: inscriptionId || "",
      })
    );
    if (changeResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Categoría cambiada correctamente");
    } else if (changeResponse.type.includes("rejected")) {
      setValue("categoryId", categoryId || "");
      toastMessageError(changeResponse.payload ?? "Error al cambiar la categoría");
    }
    setIsEdit(false);
    setOpenDialog(false);
  };

  const canChangeCategory = () => {
    return isRealized && dayjs().isBefore(dayjs(weighingChangeLimitDate));
  };

  return (
    <>
      <Typography className="contact-info-title">Mis datos personales</Typography>
      <Grid container justifyContent="space-between" height="100%" columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Federación"
              error={!!errors.federation}
              helperText={errors.federation?.message}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              {...register("federation.name")}
              className="input-text text-field"
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              label="Club"
              error={!!errors.club}
              helperText={errors.club?.message}
              fullWidth
              margin="normal"
              InputProps={{
                readOnly: true,
              }}
              className="input-text text-field"
              {...register("club.0.name")}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl variant="outlined" fullWidth margin="normal">
            <InputLabel id="category-select-label">Categoría</InputLabel>
            <Select
              defaultValue=""
              id="grouped-select"
              labelId="category-select-label"
              value={watch("categoryId") || ""}
              onChange={(e) => setValue("categoryId", e.target.value)}
              error={!!errors.category}
              fullWidth
              label="Categoría"
              className="input-select form-select-field"
              slotProps={{ input: { readOnly: !!categoryId && !isEdit } }}
            >
              {categoryId && categories?.find((category) => category.id === categoryId) && (
                <MenuItem value={categoryId}>
                  {categories.find((category) => category.id === categoryId)?.weight}
                </MenuItem>
              )}
              {categories
                ?.filter((category) =>
                  stocks?.find(
                    (stock) => stock.categoryId === category.id && category.genre === watch("genre")
                  )
                )
                .map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.weight}
                  </MenuItem>
                ))}
            </Select>
            {errors.category && <FormHelperText error>{errors.category.message}</FormHelperText>}
          </FormControl>
        </Grid>
        {canChangeCategory() && (
          <Grid container justifyContent="center">
            <Button onClick={handleButtonClick}>{isEdit ? "Guardar" : "Cambio categoría"}</Button>
          </Grid>
        )}
      </Grid>
      <CustomDialog
        content="¿Estás seguro de que quieres cambiar de categoría?"
        isOpen={openDialog}
        onAccept={handleChangeCategory}
        onClose={() => setOpenDialog(false)}
        title="Cambio de categoría"
      />
    </>
  );
}
