import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getEventResults } from "../../services/events";
import { TEventResults } from "../schemas/results";

export default function useQueryEventResults(eventId: string) {
  return useQuery<TEventResults[], Error>(
    [RQ_KEY.EVENT_RESULTS, eventId],
    () => getEventResults(eventId),
    {
      refetchOnWindowFocus: false,
    }
  );
}
