import { AlternateEmailOutlined, LocalPhoneOutlined } from "@mui/icons-material";
import {
  FormControl,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TUser } from "../../../../utils/schemas/user";

type Props = {
  isDisabled: boolean;
};

const ContactInfo = ({ isDisabled }: Props) => {
  const { register, formState, control, watch } = useFormContext<TUser>();
  const errors = formState.errors;

  const { countries, provinces, municipalities, autonomies } = useAppSelector(
    (state) => state.formsOptions
  );

  return (
    <>
      <Typography variant="h4" component="h4" className="personalData-title">
        Contacto
      </Typography>
      <Grid container columnSpacing={4} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Número de teléfono*"
            {...register("phone")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <LocalPhoneOutlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Email*"
            {...register("email")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AlternateEmailOutlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Dirección*</Typography>
            <TextField
              className="input-text text-field"
              variant="outlined"
              placeholder="Dirección"
              required
              defaultValue={""}
              error={!!errors?.address?.address}
              {...register("address.address")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
            {errors?.address && (
              <Typography className="form-input-error">{errors?.address?.message}</Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">País*</Typography>
            <Controller
              name="address.countryCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.countryCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  readOnly={isDisabled}
                  //slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>País</em>
                  </MenuItem>
                  {countries?.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.countryCode && (
              <Typography className="form-input-error">
                {errors?.address?.countryCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>

        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Autonomía*</Typography>
            <Controller
              name="address.autonomyCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.autonomyCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  readOnly={isDisabled}
                  //slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Autonomía</em>
                  </MenuItem>
                  {autonomies?.map((autonomy) => (
                    <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                      {autonomy.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.autonomyCode && (
              <Typography className="form-input-error">
                {errors?.address?.autonomyCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Provincia*</Typography>
            <Controller
              name="address.provinceCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.provinceCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  readOnly={isDisabled}
                  //slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Provincia / Región</em>
                  </MenuItem>
                  {provinces
                    ?.filter((province) => province.autonomy_code === watch("address.autonomyCode"))
                    .map((province) => (
                      <MenuItem key={province.province_code} value={province.province_code}>
                        {province.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.provinceCode && (
              <Typography className="form-input-error">
                {errors?.address.provinceCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Ciudad*</Typography>
            <Controller
              name="address.municipalityCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.municipalityCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  readOnly={isDisabled}
                  //slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Ciudad</em>
                  </MenuItem>
                  {municipalities
                    ?.filter(
                      (municipality) => municipality.province_code === watch("address.provinceCode")
                    )
                    .map((municipality) => (
                      <MenuItem
                        key={municipality.municipality_code}
                        value={municipality.municipality_code}
                      >
                        {municipality.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.municipalityCode && (
              <Typography className="form-input-error">
                {errors?.address.municipalityCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item md={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CP*</Typography>
            <TextField
              className="input-text text-field"
              variant="outlined"
              placeholder="CP"
              required
              defaultValue={""}
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.address?.postalCode}
              {...register("address.postalCode")}
            />
            {errors?.address?.postalCode && (
              <Typography className="form-input-error">
                {errors?.address?.postalCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </>
  );
};

export default ContactInfo;
