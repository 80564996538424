import { useId, useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";

type MenuPopoverItemProps = {
  title: string;
  options?: { value: string; label: string }[];
  onSelectionChange?: (value: string | undefined) => void;
  selectedValue?: string;
};

function MenuPopoverItem({
  title,
  options,
  onSelectionChange,
  selectedValue = "",
}: MenuPopoverItemProps) {
  const customId = useId();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedValue);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectionChange = (option: { value: string; label: string }) => {
    let newValue = "";
    if (option.value !== selected) {
      newValue = option.value;
    }

    setSelected(newValue);
    onSelectionChange && onSelectionChange(newValue);
  };

  const selectedOptionLabel = options
    ? Object.values(options).filter((option) => option.value === selected)[0]?.label
    : "";

  return (
    <>
      <ListItemButton className="menu-popover-item" onClick={handleClick}>
        <ListItemText primary={title} className="menu-popover-item-title" />
        <ListItemText
          primary={selectedOptionLabel ? selectedOptionLabel : ""}
          className="menu-popover-item-select"
        />

        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options?.map((option) => {
            return (
              <ListItemButton
                key={`${customId} ${option.value}`}
                sx={{ pl: 4 }}
                className={`menu-popover-item-list-item ${
                  option.value === selected ? "selected" : ""
                }`}
                onClick={() => handleSelectionChange(option)}
              >
                <ListItemText primary={option.label} />
              </ListItemButton>
            );
          })}
        </List>
      </Collapse>
    </>
  );
}

export default MenuPopoverItem;
