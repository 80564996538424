import dayjs, { Dayjs } from "dayjs";
import { useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText } from "@mui/material";
import { MobileDatePicker } from "@mui/x-date-pickers";

type MenuPopoverDateItemProps = {
  title?: string;
  onSelectionChange?: (value: string | undefined) => void;
  selectedValue?: string | Dayjs;
  disableFuture?: boolean;
  disablePast?: boolean;
};

function MenuPopoverDateItem({
  title = "Fecha",
  onSelectionChange,
  selectedValue = undefined,
  disableFuture = false,
  disablePast = false,
}: MenuPopoverDateItemProps) {
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState<string | undefined | Dayjs>(selectedValue);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectionChange = (date: Dayjs | null) => {
    let newValue = undefined;

    if (date && date.toString() !== selected) {
      newValue = date.toString();
    }

    setSelected(newValue);
    onSelectionChange && onSelectionChange(newValue);
  };

  return (
    <>
      <ListItemButton className="menu-popover-item" onClick={handleClick}>
        <ListItemText primary={title} className="menu-popover-item-title" />
        {selected ? dayjs(selected).format("DD/MM/YYYY") : <p></p>}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          <MobileDatePicker
            sx={{ pl: 4, color: "white" }}
            value={selected ? dayjs(selected) : null}
            format="DD/MM/YYYY"
            onChange={(value: Dayjs | null) => handleSelectionChange(value)}
            className="menu-popover-item-list-item menu-popover-date-picker"
            disablePast={disablePast}
            disableFuture={disableFuture}
          />
        </List>
      </Collapse>
    </>
  );
}

export default MenuPopoverDateItem;
