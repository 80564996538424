import { z } from "zod";

import { TCategory } from "./category";
import { TClub } from "./club";
import { TFederation } from "./federation";
import { MESSAGE } from "../../constants/errorMessages";

export type TUserSimple = {
  id: string;
  name: string;
  surname: string;
  email: string;
  phone: string;
  federationId: string;
  federationName: string;
  clubId: string;
  clubName: string;
  cnr: string;
  bornAt: string;
  logoUrl: string;
  isActive: boolean;
  lastAccess: string;
};

export type TUserData = {
  id: string;
  address: {
    address: string;
    autonomyCode: string;
    countryCode: string;
    municipalityCode: string;
    postalCode: string;
    provinceCode: string;
    countryId: string;
  };
  identityNumber: string;
  currentBeltId: string;
  bornAt: string;
  category: TCategory;
  club: TClub;
  cnr: string;
  email: string;
  federation: TFederation;
  isActive: boolean;
  name: string;
  phone: string;
  responsibles: {
    dni: string;
    email: string;
    familiarRelationship: string;
    name: string;
    phone: string;
    surname: string;
  }[];
  surname: string;
  allergy: string;

  sport: string;
  federationId: string;
  federationName: string;
  clubId: string;
  clubName: string;
  logoUrl: string;
  lastAccess: string;
  avatarUrl: string;
  nationality: string;
};

export const addUserSchema = z.object({
  id: z.string().optional(),
  isActive: z.union([z.number(), z.boolean()]).optional(),
  //Step 1
  name: z.string().min(1, MESSAGE.REQUIRED).max(255, { message: MESSAGE.MAX_255 }),
  bornAt: z.string().optional(),
  surname: z.string().min(1, MESSAGE.REQUIRED).max(255, MESSAGE.MAX_255),
  identityNumber: z.string().max(255, MESSAGE.MAX_255).optional(),
  nationality: z.string().max(255, MESSAGE.MAX_255).optional(),
  currentCategoryId: z.string().max(100, MESSAGE.MAX_100).optional(),
  category: z
    .object({
      genre: z.string().optional(),
      groupAge: z.string().optional(),
      id: z.string().optional(),
      name: z.string().optional(),
      weight: z.string().optional(),
      categorySport: z.string().optional(),
    })
    .optional(),
  genre: z.string().max(255, MESSAGE.MAX_255).optional(),
  address: z
    .object({
      address: z.string().optional(),
      countryCode: z.string().optional(),
      autonomyCode: z.string().optional(),
      provinceCode: z.string().optional(),
      municipalityCode: z.string().optional(),
      postalCode: z.string().optional(),
    })
    .optional(),
  phone: z.string(),
  email: z.string().max(255, MESSAGE.MAX_255).email(MESSAGE.EMAIL),
  responsibles: z
    .array(
      z
        .object({
          name: z.string().optional(),
          dni: z.string().optional(),
          surname: z.string().optional(),
          email: z.string().optional(),
          familiarRelationship: z.string().optional(),
          phone: z.string().optional(),
          id: z.string().optional(),
        })
        .optional()
    )
    .optional(),
  //Solo admin
  roleIds: z.array(z.string().optional()).optional(),

  //Step 2
  federationId: z.string().max(255, MESSAGE.MAX_255).optional(),
  clubId: z.string().max(255, MESSAGE.MAX_255).optional(),
  currentBeltId: z.string().max(100, MESSAGE.MAX_100).optional(),
  cnr: z.string().max(255, MESSAGE.MAX_255).optional(),
  coachType: z.string().optional().nullable(),
  refereeType: z.string().optional().nullable(),
  avatarUrl: z.string().optional().nullable(),
  allergy: z.string().max(100, MESSAGE.MAX_100).optional().nullable(),
  profession: z.string().max(255, MESSAGE.MAX_255).optional().nullable(),

  federation: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
  }),
  club: z.object({
    id: z.string().optional(),
    name: z.string().optional(),
  }),
});

export type TUser = z.infer<typeof addUserSchema>;

export type TUserSheet = {
  userInfo: {
    age: number;
    genre: string;
    name: string;
    surname: string;
    belt_name: string;
    club_code: string;
    club_id: string;
    federation_code: string;
    federation_id: string;
  };
  resultsData: {
    sport: string;
    belt: string;
    club_code: string;
    weights: string[];
  }[];
};

export type TResultsQuery = {
  birth_year: string;
  category_code: string;
  category_id: string;
  created_at: string;
  event_code: string;
  event_id: string;
  event_name: string;
  event_type: string;
  federation_code: string;
  has_won_any_combat: number;
  id: string;
  modified_at: string;
  name: string;
  operation_id: string;
  position: string;
  position_numeric: number;
  surname: string;
  user_id: string;
  weight: string;
  points: string;
};
