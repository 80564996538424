import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getEvents } from "../../services/events";
import { TEventFilters, TEventSimple } from "../schemas/event";

export default function useQueryEvents(filters?: TEventFilters) {
  return useQuery<Array<TEventSimple>, Error>([RQ_KEY.EVENTS, filters], () => getEvents(filters), {
    refetchOnWindowFocus: false,
  });
}
