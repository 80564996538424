import { CancelOutlined, Check, Password, Visibility, VisibilityOff } from "@mui/icons-material";
import { Container, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { FormEvent, useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Button from "../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import { resetPassword } from "../../context/forms/formsActions";
import { useAppDispatch } from "../../context/hooks/hooks";
import "./new-password.scss";

const passwordRules = [
  {
    rule: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+{}[\]:;<>,.?~\\/-]).*$/,
    label: "Debe contener letras mayúsculas, letras minúsculas, números y/o caracteres especiales",
  },
  { rule: /^.{8,15}$/, label: "Mínimo 8 caracteres y máximo 15 caracteres" },
];

const NewPassword = () => {
  const formSchema = z
    .object({
      password: z.string().regex(/^(?=.*[A-Z])(?=.*[-!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/, {
        message: "La contraseña no cumple los requisitos",
      }),
      repeatPassword: z.string(),
    })
    .refine((data) => data.password === data.repeatPassword, {
      message: "Las contraseñas no coinciden",
      path: ["repeatPassword"],
    });

  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [errors, setErrors] = useState<Record<string, string>>({});
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setIsLoading(true);
    setErrors({});
    const data = { repeatPassword, password };
    try {
      const validatedData = formSchema.parse(data);
      if (!validatedData) return;
      const response = await dispatch(
        resetPassword({
          password: validatedData.password,
          token: new URLSearchParams(window.location.search).get("token") ?? "",
        })
      );
      if (response.type.includes("fulfilled")) {
        toastMessageSuccess("Se ha establecido una nueva contraseña.");
        setTimeout(() => {
          navigate("/login");
        }, 2000);
      } else {
        setIsLoading(false);
        toastMessageError(response.payload.response.data.error ?? "Error al cambiar la contraseña");
      }
    } catch (error: any) {
      setIsLoading(false);
      if (error instanceof z.ZodError) {
        const errors: Record<string, string> = {};
        for (const issue of error.issues) {
          if (issue.path[0]) {
            errors[issue.path[0]] = issue.message;
          }
        }
        setErrors(errors);
      } else {
        console.log("error forget password", error);
        toastMessageError(error.message ?? "Error al cambiar la contraseña");
      }
    }
  };

  return (
    <Container
      className="new-password-container"
      maxWidth="xl"
      component="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <Container className="new-password-logo" />

      <Typography className="new-password-title">Establecer nueva contraseña</Typography>
      <TextField
        className="text-field new-password-text-field"
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        placeholder="Contraseña*"
        error={!!errors.password}
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Password />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowPassword((show) => !show)}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      <TextField
        className="text-field new-password-text-field"
        type={showRepeatPassword ? "text" : "password"}
        id="repeat-password"
        name="repeat-password"
        placeholder="Repetir Contraseña*"
        error={!!errors.repeatPassword}
        value={repeatPassword}
        onChange={(e) => setRepeatPassword(e.target.value)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Password />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={() => setShowRepeatPassword((show) => !show)}
                edge="end"
              >
                {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />

      {passwordRules.map((rule, i) => {
        const matchRule = password.match(rule.rule);
        return (
          <Typography key={`error-${i}`} className="new-password-rule-text" component="p">
            {matchRule ? (
              <Check className="new-password-rule-check" />
            ) : (
              <CancelOutlined className="new-password-rule-cross" />
            )}
            {rule.label}
          </Typography>
        );
      })}
      <Typography className="new-password-rule-text" component="p">
        {!!repeatPassword && password === repeatPassword ? (
          <Check className="new-password-rule-check" />
        ) : (
          <CancelOutlined className="new-password-rule-cross" />
        )}
        Las contraseñas coinciden
      </Typography>
      {Object.values(errors).map((error, i) => (
        <Typography key={`error-${i}`} className="new-password-error-text" component="p">
          {error}
        </Typography>
      ))}
      <Button
        size="large"
        type="submit"
        rounded="md"
        width="fit-content"
        className="new-password-button-new-password"
        disabled={!repeatPassword || password !== repeatPassword || isLoading}
      >
        {isLoading ? "Cargando..." : "Confirmar"}
      </Button>
    </Container>
  );
};

export default NewPassword;
