import { WorkOutline } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Button from "../../../components/button/Button";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
};

function OptionalInfo({ data, handleStep }: Props) {
  const { register, formState, trigger, setValue } = useFormContext<TUserSchema>();

  const handleButtonClick = async () => {
    // let isValid = await trigger(["categoryId", "gender", "nationality"]);

    // if (isValid) {
    //   handleStep();
    // }
    handleStep();
  };

  //Checkbox allergies
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>([]);
  const handleCheckboxChange = (value: string) => {
    if (selectedAllergies.includes(value)) {
      setSelectedAllergies(selectedAllergies.filter((item) => item !== value));
      setValue("allergy", selectedAllergies.join(","));
    } else {
      setSelectedAllergies([...selectedAllergies, value]);
      setValue("allergy", selectedAllergies.join(","));
    }
  };

  return (
    <Box className="register-form-data-container">
      <FormControl fullWidth></FormControl>
      <FormControl fullWidth>
        <Typography className="form-input-title">Profesión</Typography>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="Profesión"
          {...register("profession")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <WorkOutline />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.profession && (
          <Typography className="register-input-error">
            {formState.errors?.profession?.message}
          </Typography>
        )}
      </FormControl>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <Typography className="form-input-title">Alergias(para picnics)</Typography>
          <FormGroup>
            <FormControlLabel
              className="form-input-text"
              control={
                <Checkbox
                  className="check-radio-orange"
                  onChange={() => handleCheckboxChange("lactosa")}
                  checked={selectedAllergies.includes("lactosa")}
                />
              }
              label="Lactosa"
            />
            <FormControlLabel
              className="form-input-text"
              control={
                <Checkbox
                  className="check-radio-orange"
                  onChange={() => handleCheckboxChange("gluten")}
                  checked={selectedAllergies.includes("gluten")}
                />
              }
              label="Gluten"
            />
          </FormGroup>
          {formState.errors?.allergy && (
            <Typography className="form-input-error">
              {formState.errors?.allergy?.message}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Button className="register-button" onClick={handleButtonClick} rounded="md">
        Siguiente
      </Button>
    </Box>
  );
}

export default OptionalInfo;
