export const ROUTE_LINKS = {
  HOME: "/",
  LOGIN: "/login",
  REGISTER: "/register",
  EVENTS: "/events",
  RANKING: "/ranking",
  PROFILE: "/profile",
  CALENDAR: "/calendar",
  ORDERS: "/profile/orders",
  RESULTS: "/results",
  CONTACT: "/contact",
  OFICIALWEB: "https://www.rfejudo.com/",
  NORMS: "https://www.rfejudo.com/documentos-efn-normativas/",
  FEDERATION: "/federation",
  CLUBS: "/club",
};
