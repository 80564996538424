import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";

export const getClubs = async (parameters: string) => {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.CLUBS}${parameters ? `?${new URLSearchParams(parameters)}` : ""}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};

export async function getClub(clubId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS}/${clubId}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export const getClubsList = async (federationId: string) => {
  try {
    const federationParam = federationId ? `?federationId=${federationId}` : "";
    const { data } = await http.get(`${API_URL}${SERVICE.CLUBS_LIST}${federationParam}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};
