import { AlternateEmail, KeyboardBackspace } from "@mui/icons-material";
import { Container, Grid, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { z } from "zod";
import Button from "../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import { forgetPassword } from "../../context/forms/formsActions";
import { useAppDispatch } from "../../context/hooks/hooks";
import "./forget-password.scss";

const ForgetPassword = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);

  const formSchema = z.object({
    email: z.string().email({ message: "Ingrese un mail valido" }),
  });

  const handleBackButton = () => {
    navigate(-1);
  };

  const handleSendButtonClick = async () => {
    setError("");
    setLoading(true);
    try {
      const validatedData = formSchema.parse({ email });
      const body = {
        email: validatedData.email,
        site_verification_url: window.location.origin,
      };
      const response = await dispatch(forgetPassword(body));
      if (response.type.includes("fulfilled")) {
        toastMessageSuccess("Revise su correo para modificar su contraseña.");
        setTimeout(() => navigate("/login"), 5000);
      } else {
        console.log("error forget password", response);
        setError(response.payload);
        toastMessageError(response.payload ?? "Error al enviar el email");
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      if (error instanceof z.ZodError) {
        const validationErrors: Record<string, string> = {};
        error.errors.forEach((err) => {
          if (err.path) {
            validationErrors[err.path[0]] = err.message;
          }
        });
        setError(validationErrors.email ?? "Ocurrió un error al procesar la solicitud.");
      } else {
        setError("Ocurrió un error al procesar la solicitud.");
      }
    }
  };

  return (
    <Container className="forget-container" maxWidth="xl">
      <IconButton className="forget-back-button" onClick={handleBackButton}>
        <KeyboardBackspace className="forget-back-icon" />
      </IconButton>

      <Container className="forget-logo" />
      <Typography className="forget-text forget-title">¿Olvidaste tu contraseña?</Typography>
      <Typography className="forget-text forget-subtitle">
        Escribe tu correo y te enviamos un mail para restablecer la contraseña.
      </Typography>
      <TextField
        className="text-field forget-text-field"
        name="email"
        id="email"
        autoComplete="email"
        autoFocus
        variant="outlined"
        placeholder="Email*"
        onChange={(e) => setEmail(e.target.value)}
        error={!!error}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AlternateEmail />
            </InputAdornment>
          ),
        }}
      />
      {error && <Typography className="forget-error">{error}</Typography>}
      <Button
        size="large"
        rounded="md"
        width="fit-content"
        onClick={handleSendButtonClick}
        className="forget-button"
        disabled={loading}
      >
        Enviar email
      </Button>
      <Grid container justifyContent="space-between" className="forget-logos-container">
        <Grid item xs={6}>
          <img
            src="images/logos/EU_financiation.png"
            alt="Imagen Financiado por la Unión Europea"
            className="forget-image"
          />
        </Grid>
        <Grid item xs={6}>
          <img
            src="images/logos/Plan_recuperacion.png"
            alt="Plan de recuperación, Transformación y Resiliencia"
            className="forget-image"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ForgetPassword;
