export const mockUser = {
  name: "Francisco",
  surname: "Garrigos",
  gender: "Hombre",
  age: "29",
  countryCode: "ARA",
  sports: [
    {
      sport: "Judo",
      categories: ["~55kg", "~60kg", "~66kg"],
      belt: "verde",
      club: "JCZ",
      position: [
        { category: "~60kg", position: "1", points: "5999" },
        { category: "~66kg", position: "448", points: "5999" },
      ],
    },
    {
      sport: "Jiujutsu",
      categories: ["~60kg"],
      belt: "azul",
      club: "XXX",
      position: [{ category: "~60kg", position: "448", points: "5999" }],
    },
  ],
};

export const mockResults = [
  {
    position: "1st",
    points: "200",
    competitionName: "Nombre de la competencia y año",
  },
  {
    position: "1st",
    points: "200",
    competitionName: "Nombre de la competencia y año",
  },
  {
    position: "1st",
    points: "200",
    competitionName: "Nombre de la competencia y año",
  },
];
