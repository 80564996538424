import { Search, TuneOutlined } from "@mui/icons-material";
import { Grid, IconButton, InputAdornment, TablePagination, TextField } from "@mui/material";
import { TQueryRanking, TRankingFilters } from "../../utils/schemas/rankings";

type Props = {
  handleFilterChange: (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => void;
  handleFilterClick: (event: React.MouseEvent<HTMLElement>) => void;
  handleSearchChange: (e: any) => void;
  data: TQueryRanking | undefined;
};

const RankingHeader = ({
  handleFilterChange,
  handleFilterClick,
  handleSearchChange,
  data,
}: Props) => {
  return (
    <Grid container className="ranking-header">
      <Grid container justifyContent="space-between" item xs={12} md={8} columnSpacing={2}>
        <Grid item xs={11}>
          <TextField
            placeholder="Buscar..."
            className="input-text text-field ranking-search"
            variant="outlined"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
            }}
            fullWidth
            onChange={handleSearchChange}
          />
        </Grid>
        <Grid item xs={1}>
          <IconButton onClick={handleFilterClick}>
            <TuneOutlined className="ranking-filter-icon" />
          </IconButton>
        </Grid>
      </Grid>
      {data && (
        <Grid item xs={12} md={4}>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            count={data?.total || 0}
            rowsPerPage={data?.perPage || 10}
            page={data?.page - 1}
            onPageChange={(_, newPage) => handleFilterChange("page", newPage + 1)}
            onRowsPerPageChange={(e) => {
              handleFilterChange("perPage", e.target.value);
              handleFilterChange("page", 1);
            }}
            labelRowsPerPage="Usuarios por página"
          />
        </Grid>
      )}
    </Grid>
  );
};

export default RankingHeader;
