import axios from "axios";
import bcrypt from "bcryptjs";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { API_URL, SALT, SERVICE } from "../../constants/api";
import { getUser } from "../../services/users";
import { setHttpBearerToken } from "../../constants/axios";

export const loginUser = createAsyncThunk(
  "auth/loginUser",
  async (
    data: { data: { email: string; password: string }; remember: boolean },
    { rejectWithValue }
  ) => {
    try {
      const { email, password } = data.data;
      const dataToSend = { email, password };
      //encode con el salt
      dataToSend.password = bcrypt.hashSync(password, SALT);

      //solicitud
      const request = await axios.post(`${API_URL}${SERVICE.AUTH}`, dataToSend);

      const response = await request.data.token;

      //decodifico el token
      const userInfo = { ...decodeJWT(response) };

      localStorage.setItem("user", JSON.stringify(userInfo));
      localStorage.setItem("token", response);
      setHttpBearerToken(response);
      if (data.remember) {
        localStorage.setItem("remember", "true");
      }

      //Info of the user
      const userData = await getUser(userInfo.id);

      return { userInfo, token: response, userData };
    } catch (error) {
      return rejectWithValue("Email / Contraseña incorrectas");
    }
  }
);

function decodeJWT(token: string) {
  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );
  return JSON.parse(jsonPayload);
}
