import { useFormContext } from "react-hook-form";

import {
  Box,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Stack,
  Switch,
  Typography,
} from "@mui/material";

import { useState } from "react";
import Button from "../../../components/button/Button";
import useQueryClubsList from "../../../utils/hooks/useQueryClubList";
import useQueryFederationsList from "../../../utils/hooks/useQueryFederationsList";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
};

function SportData({ data, handleStep }: Props) {
  const { belts } = data;

  const { register, formState, trigger, watch, setValue } = useFormContext<TUserSchema>();

  const { data: federationsData } = useQueryFederationsList();
  const { data: clubsData } = useQueryClubsList(watch("federationId"));

  const [viewTrainer, setViewTrainer] = useState(false);
  const [viewReferee, setViewReferee] = useState(false);

  const handleSwitchTrainerChange = (e: boolean) => {
    if (e) {
      setViewTrainer(true);
    } else {
      setViewTrainer(false);
      setValue("coachType", "");
    }
  };
  const handleSwitchRefereeChange = (e: boolean) => {
    if (e) {
      setViewReferee(true);
    } else {
      setViewReferee(false);
      setValue("refereeType", "");
    }
  };

  const handleButtonClick = async () => {
    let isValid = await trigger(["federationId", "clubId", "belt", "coachType", "refereeType"]);

    if (isValid) {
      handleStep();
    }
  };

  return (
    <Box className="register-form-data-container">
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("federationId")}
          placeholder="Federación autonómica*"
        >
          <MenuItem value="">
            <em>Federación autonómica*</em>
          </MenuItem>
          {federationsData?.map((federation) => (
            <MenuItem key={federation?.id} value={federation.id}>
              {federation.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.federationId && (
          <Typography className="register-input-error">
            {formState.errors?.federationId?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("clubId")}
        >
          <MenuItem value="">
            <em>Club*</em>
          </MenuItem>
          {clubsData?.map((club) => (
            <MenuItem key={club?.id} value={club.id}>
              {club.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.clubId && (
          <Typography className="register-input-error">
            {formState.errors?.clubId?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("belt")}
        >
          <MenuItem value="">
            <em>Cinturón*</em>
          </MenuItem>
          {belts
            ?.sort((a, b) => {
              const nameA = a.name.toUpperCase();
              const nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }

              return 0;
            })
            ?.map((belt) => (
              <MenuItem key={belt?.id} value={belt.id}>
                {belt.name}
              </MenuItem>
            ))}
        </Select>
        {formState?.errors?.belt && (
          <Typography className="register-input-error">
            {formState.errors?.belt?.message}
          </Typography>
        )}
      </FormControl>
      <Grid container columnSpacing={2} rowSpacing={2}>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <Typography className="form-input-label">Entrenador*</Typography>
            <Switch
              onChange={(e) => handleSwitchTrainerChange(e.target.checked)}
              className="form-switch"
            />
          </Stack>
          {viewTrainer && (
            <FormControl fullWidth>
              <RadioGroup
                name="radio-buttons-group"
                value={watch("coachType")}
                onChange={(e) => setValue("coachType", e.target.value)}
              >
                <FormControlLabel
                  className="form-input-label"
                  value="regional"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Regional"
                />
                <FormControlLabel
                  className="form-input-label"
                  value="autonomic"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Autonómico"
                />
                <FormControlLabel
                  className="form-input-label"
                  value="nacional"
                  control={<Radio className="check-radio-red" />}
                  label="Entrenador Nacional"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <Stack direction="row" justifyContent="flex-start" alignItems="center" gap={2}>
            <Typography className="form-input-label">Arbitro*</Typography>
            <Switch
              onChange={(e) => handleSwitchRefereeChange(e.target.checked)}
              className="form-switch"
            />
          </Stack>
          {viewReferee && (
            <FormControl fullWidth>
              <RadioGroup
                name="radio-buttons-group"
                value={watch("refereeType")}
                onChange={(e) => setValue("refereeType", e.target.value)}
              >
                <FormControlLabel
                  className="form-input-label"
                  value="regional"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Regional"
                />
                <FormControlLabel
                  className="form-input-label"
                  value="autonomic"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Autonómico"
                />
                <FormControlLabel
                  className="form-input-label"
                  value="nacional"
                  control={<Radio className="check-radio-red" />}
                  label="Arbitro Nacional"
                />
              </RadioGroup>
            </FormControl>
          )}
        </Grid>
      </Grid>
      <Button className="register-button" onClick={handleButtonClick} rounded="md">
        Siguiente
      </Button>
    </Box>
  );
}

export default SportData;
