import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

import { Autoplay, Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useWindowSize } from "../../utils/hooks/useWindowSize";

type Props = {
  data: { image: string; url: string }[];
};

const Patrocinadores = ({ data }: Props) => {
  const { width } = useWindowSize();
  return (
    <Swiper
      slidesPerView={Number(width) > 765 ? 8 : 4}
      spaceBetween={0}
      freeMode={true}
      loop={true}
      autoplay={{
        delay: 550,
        disableOnInteraction: false,
      }}
      modules={[Autoplay, Pagination, Navigation]}
      className="mySwiper"
    >
      {data &&
        data.map((data, index) => {
          return (
            <SwiperSlide key={`sponsor-${index}`}>
              <a href={data.url} target="_blank" rel="noopener noreferrer">
                <img src={data.image} alt="logo" style={{ maxWidth: "100px" }} />
              </a>
            </SwiperSlide>
          );
        })}
    </Swiper>
  );
};

export default Patrocinadores;
