import "./calendario.scss";

import dayjs, { Dayjs } from "dayjs";

import { useEffect, useRef, useState } from "react";

import { Button, Container, Grid, Typography } from "@mui/material";
import { PickersDay, PickersDayProps } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers/DateCalendar";
import { DayCalendarSkeleton } from "@mui/x-date-pickers/DayCalendarSkeleton";

import Loading from "../../components/loader/Loading";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import { TEventFilters } from "../../utils/schemas/event";

import "./calendario.scss";

function ServerDay(props: PickersDayProps<Dayjs> & { highlightedDays?: number[] }) {
  const { highlightedDays = [], day, outsideCurrentMonth, ...other } = props;

  const isSelected = !props.outsideCurrentMonth && highlightedDays.indexOf(props.day.date()) >= 0;

  return (
    <div className={`day-container ${isSelected ? "selected" : ""}`}>
      <PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} />
    </div>
  );
}

const Calendario = () => {
  const requestAbortController = useRef<AbortController | null>(null);
  const [highlightedDays, setHighlightedDays] = useState<number[]>([]);
  const initialValue = dayjs();
  const [firstDay, setFirstDay] = useState(initialValue);
  const [lastDay, setLastDay] = useState(initialValue);
  const [selectedDay, setSelectedDay] = useState<Dayjs | null>(initialValue);

  const { data, isLoading, isRefetching } = useQueryEvents({
    fromDate: firstDay,
    toDate: lastDay,
  } as TEventFilters);

  const fetchHighlightedDays = (date: Dayjs) => {
    const firstDay = date.startOf("month").format("YYYY-MM-DD");
    const lastDay = date.endOf("month").format("YYYY-MM-DD");
    setFirstDay(dayjs(firstDay));
    setLastDay(dayjs(lastDay));
    setSelectedDay(date);
  };

  const handleMonthChange = (date: Dayjs) => {
    if (requestAbortController.current) {
      requestAbortController.current.abort();
    }
    setHighlightedDays([]);
    fetchHighlightedDays(date);
  };

  useEffect(() => {
    if (data && data.length > 0) {
      const days = data.flatMap((event) => {
        const startDay = dayjs(event.startDate).date();
        const endDay = dayjs(event.endDate).date();
        const daysInRange = [];
        daysInRange.push(startDay);
        if (startDay !== endDay) {
          daysInRange.push(endDay);
        }
        if (endDay - startDay > 1) {
          for (let i = startDay + 1; i < endDay; i++) {
            daysInRange.push(i);
          }
        }
        return daysInRange;
      });

      setHighlightedDays(days);
    }
  }, [data]);

  return (
    <Container className="calendario-container">
      <DateCalendar
        defaultValue={initialValue}
        onMonthChange={handleMonthChange}
        renderLoading={() => <DayCalendarSkeleton />}
        loading={isLoading || isRefetching}
        slots={{
          day: ServerDay,
        }}
        slotProps={{
          day: {
            highlightedDays,
          } as any,
        }}
        value={selectedDay}
      />
      <Grid container flexDirection="column" className="events-container">
        <Grid item container justifyContent="flex-start" sx={{ paddingLeft: "5%" }}>
          <Typography>{dayjs(selectedDay).locale("es").format("MMMM").toUpperCase()}</Typography>
        </Grid>
        {isLoading || isRefetching ? (
          <Loading />
        ) : data && data.length > 0 ? (
          data.map((event) => (
            <Grid item key={event.id} container className="event-info">
              <p>
                {dayjs(event.startDate).date()}-{dayjs(event.endDate).date()} {event.name}
              </p>
              <Button className="button-show-event">
                <a href={`/events/${event.id}`}>Ir al evento</a>
              </Button>
            </Grid>
          ))
        ) : (
          <Grid item>
            <p>No hay eventos</p>
          </Grid>
        )}
      </Grid>
    </Container>
  );
};

export default Calendario;
