import dayjs, { Dayjs } from "dayjs";
import { useFormContext } from "react-hook-form";

import { FormControl, Grid, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";

import { TInscriptionData } from "../../../utils/schemas/inscription";

type Props = {
  isDisabled?: boolean;
};

export default function PersonalInfo({ isDisabled }: Props) {
  const { register, formState, setValue, getValues } = useFormContext<TInscriptionData>();
  const { errors } = formState;

  const formatBornAt = getValues("bornAt") ? dayjs(getValues("bornAt")) : null;

  const handleSetDate = (position: "bornAt", value: Date | null) => {
    setValue(position, value ? value : new Date());
  };
  return (
    <Grid>
      <Typography className="contact-info-title">Mis datos personales</Typography>
      <Grid justifyContent="space-between" height="100%" container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              className="input-text text-field"
              label="Nombre"
              error={!!errors.name}
              helperText={errors.name?.message}
              fullWidth
              margin="normal"
              {...register("name")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              className="input-text text-field"
              label="Email"
              id="email"
              error={!!errors.email}
              helperText={errors.email?.message}
              fullWidth
              margin="normal"
              {...register("email")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              className="input-text text-field"
              label="Apellidos"
              error={!!errors.surname}
              helperText={errors.surname?.message}
              fullWidth
              margin="normal"
              {...register("surname")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <TextField
              className="input-text text-field"
              label="DNI / Pasaporte"
              error={!!errors.identityNumber}
              helperText={errors.identityNumber?.message}
              fullWidth
              margin="normal"
              {...register("identityNumber")}
              InputProps={{
                readOnly: isDisabled,
              }}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={6}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Fecha Nacimiento*</Typography>
            <DatePicker
              className="form-date-picker input-text text-field"
              format="DD/MM/YYYY"
              value={formatBornAt}
              onChange={(value: Dayjs | null) => handleSetDate("bornAt", value?.toDate() ?? null)}
              readOnly={isDisabled}
            />
            {errors?.bornAt && (
              <Typography className="form-input-error">{errors?.bornAt?.message}</Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
