import React, { KeyboardEvent, useEffect, useState } from "react";

import CloseIcon from "@mui/icons-material/Close";
import SearchIcon from "@mui/icons-material/Search";
// Importaciones de Material UI
import { IconButton, InputAdornment, TextField } from "@mui/material";

import { useDebounceFunction } from "../../utils/hooks/useDebounceFunction";

// Hook personalizado (Ajusta la importación según tu estructura)

type SearchBarProps = {
  placeholder?: string;
  autoSearch?: boolean;
  onSearch?: (value: string) => void;
  onPressEnter?: boolean;
  className?: string;
};

export default function SearchBar({
  placeholder,
  autoSearch,
  onPressEnter,
  onSearch,
  className,
}: SearchBarProps) {
  // Suponiendo que tienes una forma de obtener y manipular la query de búsqueda
  // Aquí simplemente iniciamos el estado
  const [searchValue, setSearchValue] = useState("");
  // Función para manejar el debounce de la búsqueda
  const pushQuerySearch = useDebounceFunction((search: any) => {
    // Lógica para actualizar la ruta con el valor de búsqueda
    // Esto dependerá de cómo manejes las rutas en tu aplicación
    onSearch && onSearch(search);
  }, 500);

  useEffect(() => {
    // Llamada a la función debounced con el valor actual de búsqueda
    (searchValue.length > 2 || searchValue.length === 0) && pushQuerySearch(searchValue);
    // Dependencias del efecto
  }, [searchValue]);

  // Manejador para el cambio en el input
  const handleSearchChange = (event: { target: { value: React.SetStateAction<string> } }) => {
    setSearchValue(event.target.value);
  };

  const handleSearch = () => {
    onSearch && onSearch(searchValue);
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (onPressEnter && onSearch) e.key === "Enter" && onSearch(searchValue);
  };

  return (
    // <div style={{ display: 'flex', alignItems: 'center', margin: '8px' }}>
    <TextField
      className={`input-text text-field ${className}`}
      fullWidth
      value={searchValue}
      onChange={handleSearchChange}
      placeholder={placeholder}
      variant="outlined"
      onKeyDown={handleKeyDown}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        ),
        endAdornment: searchValue && (
          <InputAdornment position="end">
            <IconButton onClick={() => setSearchValue("")}>
              <CloseIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
    // </div>
  );
}
