import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import "./event-results.scss";
import useQueryEventResults from "../../../utils/hooks/useQueryEventResults";
import Loading from "../../loader/Loading";
import { TEventResults } from "../../../utils/schemas/results";
import { Link } from "react-router-dom";

type Props = {
  eventId: string | undefined;
};

const EventResults = ({ eventId }: Props) => {
  const { data: eventResults, isLoading, isRefetching } = useQueryEventResults(eventId!);

  return (
    <Grid container justifyContent="center">
      {isLoading || isRefetching ? (
        <Loading />
      ) : eventResults && eventResults.length > 0 ? (
        <Grid item component={Paper} className="container-results" xs={11} lg={12}>
          <TableContainer>
            <Table>
              <TableHead className="container-results__head">
                <TableRow className="container-results__no-wrap">
                  <TableCell>Puesto</TableCell>
                  <TableCell>Nombre</TableCell>
                  <TableCell>Organizador</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {eventResults.map((result: TEventResults) => (
                  <TableRow key={result.id} className="container-results__no-wrap">
                    <TableCell className="container-results__no-wrap">{result.position}</TableCell>
                    <TableCell className="container-results__no-wrap">
                      {result.name ? result.name : result.userName},
                      {result.surname ? result.surname : result.userSurname}
                    </TableCell>
                    <TableCell className="container-results__no-wrap">
                      {result.organizator_type === "federation" ||
                      result.organizator_type === "club" ? (
                        <Link
                          to={`/${
                            result.organizator_type === "federation"
                              ? "federation"
                              : result.organizator_type === "club"
                              ? "club"
                              : ""
                          }/${result.organizator_id}`}
                          style={{ textDecoration: "none", color: "#000" }}
                        >
                          {result.organizator_short_code}
                        </Link>
                      ) : (
                        result.organizator_name
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Grid>
      ) : (
        <Typography className="container-results__no-results">
          No hay resultados disponibles
        </Typography>
      )}
    </Grid>
  );
};

export default EventResults;
