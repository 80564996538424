import { Fragment, useId, useState } from "react";

import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Collapse, List, ListItemButton, ListItemText, ListSubheader } from "@mui/material";

type MenuPopoverItemProps = {
  title: string;
  subtitles?: string[];
  options?: string[][] | undefined;
  onSelectionChange?: (value: string) => void;
  selectedValue?: string;
};

function MenuPopoverMultiple({
  title,
  subtitles = [],
  options,
  onSelectionChange,
  selectedValue = "",
}: MenuPopoverItemProps) {
  const customId = useId();
  const [open, setOpen] = useState(false);
  const [selected, setSelected] = useState(selectedValue);

  const handleClick = () => {
    setOpen(!open);
  };

  const handleSelectionChange = (option: string) => {
    let newValue = "";
    if (option !== selected) {
      newValue = option;
    }

    setSelected(newValue);
    onSelectionChange && onSelectionChange(newValue);
  };

  return (
    <>
      <ListItemButton className="menu-popover-item" onClick={handleClick}>
        <ListItemText primary={title} className="menu-popover-item-title" />
        {selected ? selected : <p></p>}
        {open ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {options?.map((option, index) => (
            <Fragment key={`${subtitles[index]} ${option} ${index}`}>
              <ListSubheader className="menu-popover-item-list-item">
                {subtitles[index]}
              </ListSubheader>
              {option?.map((opt, i) => (
                <Fragment key={`${subtitles[index]} ${opt}-${i}`}>
                  <ListItemButton
                    key={`${customId} ${opt}`}
                    sx={{ pl: 4 }}
                    className={`menu-popover-item-list-item ${opt === selected ? "selected" : ""}`}
                    onClick={() => handleSelectionChange(opt)}
                  >
                    <ListItemText primary={opt} />
                  </ListItemButton>
                </Fragment>
              ))}
            </Fragment>
          ))}
        </List>
      </Collapse>
    </>
  );
}

export default MenuPopoverMultiple;
