import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getInscriptionsByToken } from "../../services/users";
import { TInscriptionFilters, TInscriptionsQuery } from "../schemas/inscription";

export default function useQueryInscriptions({ search, types, status }: TInscriptionFilters) {
  return useQuery<TInscriptionsQuery, Error>([RQ_KEY.USER, search], () =>
    getInscriptionsByToken({ search, types, status })
  );
}
