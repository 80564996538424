import { ManageAccounts } from "@mui/icons-material";
import { Grid, Tab, Tabs } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../components/common/button/Button";
import EventInfo from "../../components/events/info/EventInfo";
import EventResults from "../../components/events/results/EventResults";
import Loading from "../../components/loader/Loading";
import { INSCRIPTION_STATUS } from "../../constants/constants";
import useQueryEvent from "../../utils/hooks/useQueryEvent";
import useQueryInscriptions from "../../utils/hooks/useQueryInscriprions";
import { downloadPdfFromBase64 } from "../../utils/utils";
import "./event-detail.scss";

export default function EventDetails() {
  const { eventId } = useParams();
  const { data, isLoading, error } = useQueryEvent(eventId!);
  const checkEventDate = data?.startDate && new Date(data?.startDate) <= new Date();
  const navigate = useNavigate();

  const [selectedTab, setSelectedTab] = useState<number>(checkEventDate ? 0 : 1);

  const handleChangeTab = (_: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  let user = localStorage.getItem("user");

  const { data: userInscriptions } = useQueryInscriptions({ search: "", types: "", status: "" });

  const inscriptionEvent = userInscriptions?.data.find(
    (inscription) => inscription.eventId === data?.id
  );

  useEffect(() => {
    if (data) {
      localStorage.setItem("event", JSON.stringify(data));
    }
  }, [data]);

  // Condición para manejar la lógica de carga o la ausencia de clubId
  if (isLoading) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      {data && (
        <Grid mb={10}>
          {data && (
            <Grid container className="header">
              <Grid item xs={12} sm={6} className="headerContainerImage">
                <img
                  src={data?.posterUrl}
                  height="0"
                  width="0"
                  sizes="100%"
                  className="image"
                  alt={data?.name}
                />
              </Grid>
              <Grid item xs={12} sm={6} className="headerContainerInfo">
                <p className="mb-3 text-shadow">
                  {data?.startDate} | {data?.location}
                </p>
                {data?.name && <h3 className="mb-3 headerTitle">{data.name}</h3>}
                {data?.subtitle && <h4 className="mb-3 text-shadow">{data.subtitle}</h4>}
                {data?.description && <p className="mb-3">{data?.description}</p>}

                <Grid container className="buttonsContainer" columnSpacing={4}>
                  {user && inscriptionEvent ? (
                    <Button
                      onClick={() => navigate("/profile/my_inscriptions")}
                      className="clubcard-buttons"
                      rounded="md"
                    >
                      Ver inscripción (
                      {inscriptionEvent.status === INSCRIPTION_STATUS.APPROVED
                        ? "Aprobada"
                        : inscriptionEvent.status === INSCRIPTION_STATUS.PENDING
                        ? "Pendiente"
                        : "Rechazado"}
                      )
                    </Button>
                  ) : data?.stocks && data?.stocks?.length > 0 ? (
                    <Button
                      onClick={() => navigate("inscription")}
                      className="clubcard-buttons"
                      rounded="md"
                      startIcon={<ManageAccounts />}
                    >
                      Inscribirme
                    </Button>
                  ) : null}
                  {data && data.circularPdf !== undefined && (
                    <Button
                      className="clubcard-buttons"
                      rounded="md"
                      startIcon={<ManageAccounts />}
                      onClick={() =>
                        downloadPdfFromBase64(data?.circularPdf!!, "nombre_del_archivo.pdf")
                      }
                    >
                      Descargar PDF
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          )}
          {checkEventDate && (
            <Grid container justifyContent="center" mb={2}>
              <Tabs
                value={selectedTab}
                onChange={handleChangeTab}
                aria-label="basic tabs example"
                className="event-tabs"
                TabIndicatorProps={{
                  className: "event-tab-indicator",
                }}
              >
                <Tab label="Resultados" className="event-tab" />
                <Tab label="Información del evento" className="event-tab" />
              </Tabs>
            </Grid>
          )}
          {selectedTab === 0 && <EventResults eventId={eventId} />}
          {selectedTab === 1 && <EventInfo data={data} />}
        </Grid>
      )}
    </>
  );
}
