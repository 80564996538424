/* eslint-disable no-restricted-globals */
import { useEffect } from "react";

export default function useConcentScript() {
  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://consentimientos.com/webservice/gfm_consentimientos_b.js";
    script.async = true;
    script.onload = () => {
      // @ts-ignore
      includejQuery();
    };
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const recogerConsentimiento = (form: HTMLElement | null, dominio: string, url: string) =>
    // @ts-ignore
    window.recogerConsentimiento(form, dominio, url);

  return { recogerConsentimiento };
}
