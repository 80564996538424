import { AssignmentIndOutlined, BadgeOutlined, Person2Outlined } from "@mui/icons-material";
import { Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TUser } from "../../../../utils/schemas/user";

type Props = {
  isDisabled: boolean;
};

const BasicInfo = ({ isDisabled }: Props) => {
  const { categories } = useAppSelector((state) => state.formsOptions);
  const { register, control, watch, formState, setValue } = useFormContext<TUser>();
  const { errors } = formState;

  const handleSetDate = (value: Date | null) => {
    setValue("bornAt", value?.toDateString() || "");
  };

  return (
    <>
      <Typography variant="h4" component="h4" className="personalData-title">
        Datos personales
      </Typography>
      <Grid container columnSpacing={4} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Nombre*"
            {...register("name")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person2Outlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Apellidos*"
            {...register("surname")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Person2Outlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <DatePicker
            className="input-text text-field"
            format="DD/MM/YYYY"
            maxDate={dayjs()}
            value={watch("bornAt") ? dayjs(watch("bornAt")) : null}
            onChange={(value: Dayjs | null) => handleSetDate(value?.toDate() ?? null)}
            readOnly={isDisabled}
          />
          {errors?.bornAt && (
            <Typography className="form-input-error">{errors?.bornAt?.message}</Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register("identityNumber")}
            placeholder="DNI*"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <AssignmentIndOutlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register("cnr")}
            placeholder="CNR*"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeOutlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="currentCategoryId"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                className="input-select results-select-field personal-data-select-field"
                variant="outlined"
                fullWidth
                placeholder="Categoría*"
                {...field}
                readOnly={isDisabled}
              >
                <MenuItem value="">Peso*</MenuItem>
                {categories
                  ?.filter((category) => category.genre === watch("genre"))
                  .map((category) => (
                    <MenuItem key={category.id} value={category.id}>
                      {category.weight}-{category.sportCode}
                    </MenuItem>
                  ))}
              </Select>
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            {...register("nationality")}
            placeholder="Nacionalidad*"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BadgeOutlined />
                </InputAdornment>
              ),
              readOnly: isDisabled,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
      </Grid>{" "}
    </>
  );
};

export default BasicInfo;
