import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";

export const getFederations = async (parameters: string) => {
  try {
    const { data } = await http.get(
      `${API_URL}${SERVICE.FEDERATIONS}${parameters ? `?${new URLSearchParams(parameters)}` : ""}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};

export async function getFederation(federationId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.FEDERATIONS}/${federationId}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export const getAllFederationsList = async () => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.FEDERATIONS}/list`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};
