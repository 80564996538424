import "./register-layout.scss";

import { ReactNode } from "react";

import { Container, Grid } from "@mui/material";
import { useNavigate } from "react-router-dom";

type RegisterLayoutProps = {
  children: ReactNode;
};

function RegisterLayout({ children }: RegisterLayoutProps) {
  const navigate = useNavigate();

  return (
    <Container className="register-layout-container">
      <Container className="register-layout-logo" component={"div"} onClick={() => navigate("/")} />
      {children}
      <Grid container className="register-logos-container" columnSpacing={4}>
        <Grid item xs={5} sm={4} md={2}>
          <img
            src="images/logos/EU_financiation.png"
            alt="Imagen Financiado por la Unión Europea"
            className="register-image"
          />
        </Grid>
        <Grid item xs={5} sm={4} md={2}>
          <img
            src="images/logos/Plan_recuperacion.png"
            alt="Plan de recuperación, Transformación y Resiliencia"
            className="register-image"
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default RegisterLayout;
