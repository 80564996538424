import { useQuery } from "react-query";
import { RQ_KEY } from "../../../constants/query";
import { getRankings } from "../../../services/rankings";
import { TQueryRanking, TRankingFilters } from "../../schemas/rankings";

export default function useQueryRankings(rankingFilters: TRankingFilters) {
  return useQuery<TQueryRanking, Error>(
    [RQ_KEY.SPORT_CARD, rankingFilters],
    () => getRankings(rankingFilters),
    {
      refetchOnWindowFocus: false,
    }
  );
}
