const BASE_API_URL =
  process.env.NEXT_PUBLIC_BASE_API_URL ?? "https://63571hrcdf.execute-api.eu-west-1.amazonaws.com";

const APP_URL = "/prod/v1";
const S3_URL = "/api/s3";

export const API_URL = `${BASE_API_URL}`;
export const SALT = "$2a$10$9477qIFU5ZgiVoMyxWCrU.";

export const SERVICE = {
  All_UTILS: `${APP_URL}/utils/all-lists`,
  AUTH: `${APP_URL}/users/login`,
  BASE: APP_URL,
  CATEGORIES: `${APP_URL}/utils/categories`,
  CITIES_UTILS: `${APP_URL}/utils/cities`,
  CITIES: `${APP_URL}/cities`,
  CLUBS_LIST: `${APP_URL}/clubs/list`,
  CLUBS: `${APP_URL}/clubs`,
  COUNTRIES: `${APP_URL}/utils/countries`,
  EVENTS: `${APP_URL}/events`,
  FEDERATIONS: `${APP_URL}/federations`,
  FORGET_PASSWORD: `${APP_URL}/users/forgot-password`,
  IMAGES: `${APP_URL}/images`,
  INSCRIPTIONS: `${APP_URL}/inscriptions`,
  LANGUAGES: `${APP_URL}/languages`,
  RANKING: `${APP_URL}/ranking`,
  RESET_PASSWORD: `${APP_URL}/users/reset-password`,
  STUDENT_PHOTO: `${S3_URL}/student_profile`,
  SUBMIT_DOCUMENTATION: `${APP_URL}/submit_documentation`,
  USERS: `${APP_URL}/users`,
  RESULTS: `${APP_URL}/results`,
  RANKINGS: `${APP_URL}/rankings`,
};

export const TOKEN_KEY = "token";
