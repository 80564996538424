import { createSlice } from "@reduxjs/toolkit";
import { loginUser } from "./authActions";
import { TUserData } from "../../utils/schemas/user";

type InitialStateType = {
  loading: boolean;
  userInfo: {
    claims: [];
    id: string;
    roles: string[];
    email: string;
    federationId: string;
    clubsIds: string[];
  } | null;
  userData: TUserData | null;
  userToken: string | null;
  error: string | null;
  success: boolean;
};

const initialState: InitialStateType = {
  loading: false,
  userInfo: null,
  userData: null,
  userToken: null,
  error: null,
  success: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setUser: (state, action) => ({ ...state, ...action.payload }),
    removeUser: () => ({ ...initialState }),
  },
  extraReducers(builder) {
    builder
      .addCase(loginUser.pending, (state) => {
        state.loading = true;
      })
      .addCase(loginUser.fulfilled, (state, { payload }) => {
        state.loading = false;
        state.userInfo = payload.userInfo;
        state.userToken = payload.token;
        state.userData = payload.userData;
        state.success = true;
      })
      .addCase(loginUser.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message ?? null;
      });
  },
});

export const { setUser, removeUser } = authSlice.actions;

export default authSlice.reducer;
