import "./contact-info.scss";

import { Fragment, useEffect } from "react";
import { useFormContext } from "react-hook-form";

import { Box, FormControl, Grid, Stack, TextField, Typography } from "@mui/material";

import { TInscriptionData } from "../../../utils/schemas/inscription";

type Props = {
  isDisabled?: boolean;
};

export default function ContactInfo({ isDisabled }: Props) {
  const { register, formState, setValue, watch } = useFormContext<TInscriptionData>();
  const { errors } = formState;

  const responsables = watch("responsibles");

  useEffect(() => {
    if (responsables) {
      //setValue("responsables", watch("responsables"));
      // setValue("fiscalAutonomy", watch("autonomy"));
      // setValue("fiscalProvince", watch("province"));
      // setValue("fiscalCity", watch("city"));
      // setValue("fiscalCp", watch("cp"));
    }
  }, [responsables, setValue, watch]);

  return (
    <Box>
      {responsables && (
        <Grid item md={7} xs={12}>
          {responsables &&
            responsables.map((_, index) => (
              <Fragment key={`responsable - ${index}`}>
                <Typography className="contact-info-title">
                  Datos de contacto {index + 1}
                </Typography>
                <Stack justifyContent="space-between" height="100%">
                  <FormControl fullWidth>
                    <TextField
                      label="Nombre"
                      error={!!errors?.responsibles?.[index]?.name}
                      helperText={errors?.responsibles?.[index]?.name?.message}
                      fullWidth
                      margin="normal"
                      {...register(`responsibles.${index}.name`)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      className="input-text text-field"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Surname"
                      error={!!errors?.responsibles?.[index]?.surname}
                      helperText={errors?.responsibles?.[index]?.surname?.message}
                      fullWidth
                      margin="normal"
                      {...register(`responsibles.${index}.surname`)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      className="input-text text-field"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Email"
                      error={!!errors?.responsibles?.[index]?.email}
                      helperText={errors?.responsibles?.[index]?.email?.message}
                      fullWidth
                      margin="normal"
                      {...register(`responsibles.${index}.email`)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      className="input-text text-field"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="Phone"
                      error={!!errors?.responsibles?.[index]?.phone}
                      helperText={errors?.responsibles?.[index]?.phone?.message}
                      fullWidth
                      margin="normal"
                      {...register(`responsibles.${index}.phone`)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      className="input-text text-field"
                    />
                  </FormControl>
                  <FormControl fullWidth>
                    <TextField
                      label="DNI"
                      error={!!errors?.responsibles?.[index]?.dni}
                      helperText={errors?.responsibles?.[index]?.dni?.message}
                      fullWidth
                      margin="normal"
                      {...register(`responsibles.${index}.dni`)}
                      InputProps={{
                        readOnly: isDisabled,
                      }}
                      className="input-text text-field"
                    />
                  </FormControl>
                </Stack>
              </Fragment>
            ))}
        </Grid>
      )}
    </Box>
  );
}
