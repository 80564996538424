import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getInfoUserToken } from "../../services/users";
import { TUserSimple } from "../schemas/user";

export default function useQueryInfoUserToken(token: string) {
  return useQuery<TUserSimple, Error>([RQ_KEY.USER], () => getInfoUserToken(token), {
    refetchOnWindowFocus: false,
  });
}
