import { QueryClient } from "react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      cacheTime: 2 * 1000,
      retry: false,
      refetchOnWindowFocus: false,
    },
  },
});

export const RQ_KEY = {
  EVENTS: "EVENTS",
  CLUBS: "CLUBS",
  CLUB: "CLUB",
  FEDERATIONS: "FEDERATIONS",
  FEDERATION: "FEDERATION",
  USERS: "USERS",
  EVENT: "EVENT",
  COUNTRIES: "COUNTRIES",
  FAVORITES: "FAVORITES",
  USER: "USER",
  CITY: "CITY",
  PREMISES: "ORGANIZATIONS",
  STUDENT: "STUDENT",
  PROFILE_PIC: "PROFILE_PIC",
  REQUEST_CARD: "REQUEST_CARD",
  CONSUME_TICKET: "CONSUME_TICKET",
  CITIES: "CITIES",
  CATEGORY: "CATEGORY",
  REGIONS: "REGIONS",
  UTILS: "UTILS",
  CLUB_LIST: "CLUB_LIST",
  FEDERATIONS_LIST: "FEDERATIONS_LIST",
  SPORT_CARD: "SPORT_CARD",
  INSCRIPTION_ID: "INSCRIPTION_ID",
  USER_RESULTS: "USER_RESULTS",
  EVENT_RESULTS: "EVENT_RESULTS",
};
