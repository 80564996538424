import "./set-password.scss";

import { useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";

import { zodResolver } from "@hookform/resolvers/zod";
import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Container,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import RegisterLayout from "../../components/registerLayout/RegisterLayout";
import { useAppDispatch } from "../../context/hooks/hooks";
import { setUserPassword } from "../../context/register/registerActions";
import useConcentScript from "../../utils/hooks/useConcent";
import useQueryInfoUserToken from "../../utils/hooks/useQueryInfoUserToken";

const formSchema = z
  .object({
    password: z.string().min(3, { message: "Ingrese una contraseña valida" }),
    repeatPassword: z.string().min(3, { message: "Ingrese una contraseña valida" }),
    termsAndConditionsConsent: z.string(),
    dataTreatmentConsent: z.string(),
    name: z.string().optional(),
    surname: z.string().optional(),
  })
  .refine((data) => data.password === data.repeatPassword, {
    message: "Las contraseñas no coinciden",
    path: ["repeatPassword"],
  });

type FormSchema = z.infer<typeof formSchema>;

function SetPassword() {
  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const { register, getValues, formState, handleSubmit, watch, setValue } = useForm<FormSchema>({
    mode: "all",
    resolver: zodResolver(formSchema),
  });
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { recogerConsentimiento } = useConcentScript();
  const token = new URLSearchParams(window.location.search).get("token") || "";
  const today = dayjs().format("YYYY-MM-DD");

  const { data: userData } = useQueryInfoUserToken(token);

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const manageConcents = async () => {
    recogerConsentimiento(
      document.getElementById("rfejyda-register"),
      "rfejyda-register",
      "app.rfejudo.com/rfejyda-register"
    );
  };

  const onSubmit = async () => {
    const password = getValues("password");
    const data = {
      body: {
        password: password,
      },
      token: token,
    };
    const setResponse = await dispatch(setUserPassword(data));
    if (setResponse.type.includes("fulfilled")) {
      manageConcents();
      toastMessageSuccess("Contraseña establecida correctamente");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (setResponse.type.includes("rejected")) {
      toastMessageError("Error al establecer la contraseña");
    }
  };

  const disableButton = !formState.isValid;

  const isAllChecked = () => {
    return (
      watch("termsAndConditionsConsent") !== "" &&
      !!watch("termsAndConditionsConsent") &&
      watch("dataTreatmentConsent") !== "" &&
      !!watch("dataTreatmentConsent")
    );
  };

  return (
    <RegisterLayout>
      <Container className="set-password-container">
        <Box className="set-password-title-container">
          <Typography className="set-password-title">Establecer contraseña</Typography>
          <Typography className="set-password-subtitle">
            Estas registrándote en la app de la RFEJYDA
          </Typography>
        </Box>
        <form className="set-password-form-container" onSubmit={handleSubmit(onSubmit)}>
          <FormControl fullWidth>
            <TextField
              fullWidth
              className="text-field set-password-text-field"
              type={showPassword ? "text" : "password"}
              autoComplete="current-password"
              placeholder="Contraseña*"
              error={!!formState.errors.password}
              {...register("password")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      edge="end"
                    >
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formState?.errors?.password && (
              <Typography className="set-password-input-error">
                {formState.errors?.password?.message}
              </Typography>
            )}
          </FormControl>
          <FormControl fullWidth>
            <TextField
              fullWidth
              className="text-field set-password-text-field"
              type={showRepeatPassword ? "text" : "password"}
              id="repeat-password"
              placeholder="Repetir Contraseña*"
              error={!!formState.errors.repeatPassword}
              {...register("repeatPassword")}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Password />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowRepeatPassword}
                      edge="end"
                    >
                      {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
            {formState?.errors?.repeatPassword && (
              <Typography className="set-password-input-error">
                {formState.errors?.repeatPassword?.message}
              </Typography>
            )}
          </FormControl>

          <Box className="set-password-checkboxes-container">
            <FormControlLabel
              className="set-password
                           set-password-checkbox-container"
              control={
                <Checkbox
                  checked={
                    watch("termsAndConditionsConsent") !== "" &&
                    !!watch("termsAndConditionsConsent")
                  }
                  onChange={(e) =>
                    setValue("termsAndConditionsConsent", e.target.checked ? today : "")
                  }
                  className="set-password
            set-password-checkbox"
                />
              }
              label={
                <>
                  <Typography>Acepto las condiciones</Typography>
                  <a href="/#" target="_blank">
                    Haga click aquí para ver las condiciones
                  </a>
                </>
              }
            />
            <FormControlLabel
              className="set-password
                           set-password-checkbox-container"
              control={
                <Checkbox
                  checked={watch("dataTreatmentConsent") !== "" && !!watch("dataTreatmentConsent")}
                  onChange={(e) => setValue("dataTreatmentConsent", e.target.checked ? today : "")}
                  className="set-password
                  set-password-checkbox"
                />
              }
              label={
                <>
                  <Typography>
                    He leído y acepto la{" "}
                    <a href="/#" target="_blank">
                      Política de Privacidad
                    </a>
                  </Typography>
                </>
              }
            />
          </Box>
          <Button
            disabled={disableButton && !isAllChecked()}
            type="submit"
            rounded="md"
            className="set-password-button"
          >
            Registrarse
          </Button>
        </form>
      </Container>
      {/* Datos para enviar a consentimientos */}
      <form id="rfejyda-register" className="rfejyda-register-hidden">
        <label htmlFor="id">Id</label>
        <input name="id" id="id-rfejyda-register" value={userData?.id} />
        <label htmlFor="nombre">Nombre</label>
        <input name="nombre" id="name-refejyda-register" value={userData?.name}></input>
        <label htmlFor="email">Email</label>
        <input name="email" id="email" value={userData?.email}></input>
        <label htmlFor="termsAndConditionsConsent">Términos y Condiciones</label>
        <input
          name="termsAndConditionsConsent"
          id="termsAndConditionsConsent"
          value={watch("termsAndConditionsConsent")}
        ></input>
        <label htmlFor="dataTreatmentConsent">Tratamiento de datos</label>
        <input
          name="dataTreatmentConsent"
          id="dataTreatmentConsent"
          value={watch("dataTreatmentConsent")}
        ></input>
      </form>
    </RegisterLayout>
  );
}

export default SetPassword;
