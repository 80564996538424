import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getInscriptionById } from "../../services/users";
import { TInscriptionData } from "../schemas/inscription";

export default function useQueryInscriptionId(id: string) {
  return useQuery<TInscriptionData, Error>([RQ_KEY.INSCRIPTION_ID], () => getInscriptionById(id), {
    enabled: id !== "",
  });
}
