import {
  ButtonProps,
  Button as MuiButton,
  ButtonOwnProps,
} from "@mui/material";
import { BUTTONWIDTH, BUTTONRADIUS } from "../../constants/button";
import "./button.scss";

type Props = ButtonProps &
  ButtonOwnProps & {
    rounded?: "none" | "sm" | "md" | "xl";
    width?: "none" | "sm" | "md" | "full-width" | "fit-content";
  };

const Button = ({
  children,
  rounded = "sm",
  width = "md",
  className,
  sx,
  ...props
}: Props) => {
  return (
    <MuiButton
      className={`button ${className}`}
      variant="contained"
      sx={{
        borderRadius: BUTTONRADIUS[rounded],
        width: BUTTONWIDTH[width],
        ...sx,
      }}
      {...props}
    >
      {children}
    </MuiButton>
  );
};

export default Button;
