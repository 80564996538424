import { useQuery } from "react-query";
import { RQ_KEY } from "../../constants/query";
import { getUserSportCard } from "../../services/users";
import { TUserSheet } from "../schemas/user";

export default function useQuerySportCard(id: string) {
  return useQuery<TUserSheet, Error>([RQ_KEY.SPORT_CARD], () => getUserSportCard(id), {
    enabled: id !== "",
  });
}
