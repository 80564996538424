import "./layout.scss";

import { useMemo, useState } from "react";
import { Outlet } from "react-router-dom";

import { Box, Container, CssBaseline, Toolbar, useMediaQuery } from "@mui/material";

import { useAppSelector } from "../../context/hooks/hooks";
import routes from "../../routes/routes";
import Footer from "../footer/footer";
import FooterMobileBar from "../footerMobileBar/FooterMobileBar";
import AppBar from "./appBar/AppBar";

interface Props extends React.PropsWithChildren {}

export default function Layout({ children }: Props) {
  const matches = useMediaQuery("(max-width:769px)");
  const [open, setOpen] = useState(!matches);
  const { userInfo } = useAppSelector((state) => state.auth);

  const toggleNavDrawer = () => {
    setOpen(!open);
  };

  // Esto es una prueba para la autorización
  const routesMemo = useMemo(
    () => routes.filter((route) => route.roles.some((role) => userInfo?.roles.includes(role))),
    [userInfo]
  );

  return (
    <Box sx={{ display: "flex", flexDirection: "column" }}>
      <CssBaseline />
      <AppBar isDrawerOpen={open} toggleNavDrawer={toggleNavDrawer} userInfo={userInfo} />
      <Box
        component="main"
        sx={{
          backgroundColor: (theme) =>
            theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[900],
          flexGrow: 1,
          overflow: "hidden",
          minHeight: "calc(100vh - 130px)",
        }}
      >
        <Toolbar
          sx={(theme) => ({
            height: theme.spacing(9),
          })}
        />
        <Container className="layout-children-container" maxWidth={false}>
          <Outlet />
        </Container>
      </Box>
      <FooterMobileBar userInfo={userInfo} />
      <Footer />
    </Box>
  );
}
