import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";
import { TInscriptionFilters } from "../utils/schemas/inscription";

export const getUsers = async () => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};

export async function getUser(userId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/${userId}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getUserData() {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/bytoken`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getUserDataForInscription() {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/bytoken`);
    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getInscriptionsByToken({ search }: TInscriptionFilters) {
  try {
    const parameters = new URLSearchParams();
    if (search && search !== "") parameters.append("search", search);

    const { data } = await http.get(
      `${API_URL}${SERVICE.INSCRIPTIONS}/user?${parameters.toString()}`
    );

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getInscriptionById(id: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.INSCRIPTIONS}/${id}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function checkUserEmail(email: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/email-available/${email}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function checkUserCnr(cnr: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/cnr-available/${cnr}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getUserSportCard(userId: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/sports-sheet/${userId}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getUserResults() {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.RESULTS}/user`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}

export async function getInfoUserToken(token: string) {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.USERS}/info/${token}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
}
