import { useState } from "react";
import { useFormContext } from "react-hook-form";

import { Password, Visibility, VisibilityOff } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  FormControl,
  FormControlLabel,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";
import dayjs from "dayjs";

type Props = {
  data: TUtilsSchema;
  handleStep: (step: number) => void;
};

function SetPassword({ handleStep }: Props) {
  const { register, formState, trigger, getValues, setValue, watch } =
    useFormContext<TUserSchema>();

  const [showPassword, setShowPassword] = useState(false);
  const [showRepeatPassword, setShowRepeatPassword] = useState(false);
  const today = dayjs().format("YYYY-MM-DD");

  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleClickShowRepeatPassword = () => setShowRepeatPassword((show) => !show);

  const handleButtonClick = async () => {
    let isValid = await trigger(["password", "repeatPassword"]);
    if (isValid && getValues("password") === getValues("repeatPassword")) {
      handleStep(10);
    }
  };

  const isAllChecked = () => {
    return (
      watch("termsAndConditionsConsent") != "" &&
      !!watch("termsAndConditionsConsent") &&
      watch("dataTreatmentConsent") != "" &&
      !!watch("dataTreatmentConsent")
    );
  };

  return (
    <Box className="register-form-data-container">
      <FormControl fullWidth>
        <TextField
          fullWidth
          className="text-field register-text-field"
          type={showPassword ? "text" : "password"}
          autoComplete="current-password"
          placeholder="Contraseña*"
          error={!!formState.errors.password}
          {...register("password")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Password />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.password && (
          <Typography className="register-input-error">
            {formState.errors?.password?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          fullWidth
          className="text-field register-text-field"
          type={showRepeatPassword ? "text" : "password"}
          id="repeat-password"
          placeholder="Repetir Contraseña*"
          error={!!formState.errors.repeatPassword}
          value={watch("repeatPassword")}
          onChange={(e) => {
            setValue("repeatPassword", e.target.value);
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Password />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowRepeatPassword}
                  edge="end"
                >
                  {showRepeatPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.repeatPassword && (
          <Typography className="register-input-error">
            {formState.errors?.repeatPassword?.message}
          </Typography>
        )}
      </FormControl>

      <Box className="register-checkboxes-container">
        <FormControlLabel
          className="register
            register-checkbox-container"
          control={
            <Checkbox
              checked={
                watch("termsAndConditionsConsent") !== "" && !!watch("termsAndConditionsConsent")
              }
              onChange={(e) => setValue("termsAndConditionsConsent", e.target.checked ? today : "")}
              className="register
            register-checkbox"
            />
          }
          label={
            <>
              <Typography>Acepto las condiciones</Typography>
              <a href="/#" target="_blank">
                Haga click aquí para ver las condiciones
              </a>
            </>
          }
        />
        <FormControlLabel
          className="register
            register-checkbox-container"
          control={
            <Checkbox
              checked={watch("dataTreatmentConsent") != "" && !!watch("dataTreatmentConsent")}
              onChange={(e) => setValue("dataTreatmentConsent", e.target.checked ? today : "")}
              className="register
                register-checkbox"
            />
          }
          label={
            <>
              <Typography>
                He leído y acepto la{" "}
                <a href="/#" target="_blank">
                  Política de Privacidad
                </a>
              </Typography>
            </>
          }
        />
      </Box>
      <Button
        className="register-button"
        onClick={handleButtonClick}
        rounded="md"
        disabled={!isAllChecked()}
      >
        Siguiente
      </Button>
    </Box>
  );
}

export default SetPassword;
