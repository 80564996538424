import "./menu-popover.scss";

import { ReactNode } from "react";

import { Box, List, ListSubheader } from "@mui/material";

type MenuPopoverProps = {
  children: ReactNode;
  title: string;
};

function MenuPopover({ children, title }: MenuPopoverProps) {
  return (
    <Box className="menu-popover-container">
      <List
        sx={{ width: "100%" }}
        subheader={
          <ListSubheader component="div" className="menu-popover-title">
            {title}
          </ListSubheader>
        }
      >
        {children}
      </List>
    </Box>
  );
}

export default MenuPopover;
