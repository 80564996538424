import "./ranking.scss";

import {
  Avatar,
  Box,
  Container,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";

import dayjs from "dayjs";
import { useState } from "react";
import Loading from "../../components/loader/Loading";
import RankingFilters from "../../components/rankings/RankingFilters";
import RankingHeader from "../../components/rankings/RankingHeader";
import { GENRES } from "../../constants/constants";
import { useAppSelector } from "../../context/hooks/hooks";
import { useDebounceFunction } from "../../utils/hooks/useDebounceFunction";
import useQueryRankings from "../../utils/hooks/rankings/useQueryRankings";
import { TRanking, TRankingFilters } from "../../utils/schemas/rankings";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { Link } from "react-router-dom";

const Ranking = () => {
  const { width } = useWindowSize();
  const isMobile = width && width < 768;

  const { categories } = useAppSelector((state) => state.formsOptions);
  const [rankingFilters, setRankingFilters] = useState<TRankingFilters>({
    date: dayjs().toISOString(),
    weight: "",
    search: "",
    sportCode: "",
    genre: "",
    page: 1,
    perPage: 10,
    category: "",
  });
  const [selectedCategory, setSelectedCategory] = useState<string | null>("");

  const { data, isLoading, isRefetching } = useQueryRankings(rankingFilters);

  const handleFilterChange = (
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => {
    setRankingFilters((prev) => ({
      ...prev,
      [filter]: value,
    }));
  };

  const handleChangeCategory = (id: string) => {
    setSelectedCategory(id);
    const category = categories?.find((category) => category.id === id);
    handleFilterChange("genre", category?.genre);
    handleFilterChange("weight", category?.weight);
  };

  //Search optimization
  const searchFunction = (searchTerm: string) => {
    handleFilterChange("search", searchTerm);
  };

  const debouncedSearch = useDebounceFunction(searchFunction, 500);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    debouncedSearch(event.target.value);
  };

  //Responsive filters
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Container className="ranking-container">
      <RankingHeader
        handleFilterChange={handleFilterChange}
        data={data}
        handleFilterClick={handleFilterClick}
        handleSearchChange={handleSearchChange}
      />
      {isLoading || isRefetching ? (
        <Loading />
      ) : (
        <Box className="ranking-body">
          <Box className="ranking-table-top">
            <Box className="ranking-table-top-title">
              <Typography component="h4" className="ranking-title">
                Ranking{" "}
                {rankingFilters.genre === GENRES.MEN
                  ? "Hombres"
                  : rankingFilters.genre === GENRES.WOMEN
                  ? "Mujeres"
                  : ""}
              </Typography>
              <Typography component="h4" className="ranking-date">
                Version: {dayjs(rankingFilters.date).format("DD/MM/YYYY").toString()}
              </Typography>
            </Box>
            <RankingFilters
              handleChangeCategory={handleChangeCategory}
              handleFilterChange={handleFilterChange}
              rankingFilters={rankingFilters}
              selectedCategory={selectedCategory}
              anchorEl={anchorEl}
              handleClose={handleClose}
            />
          </Box>
          {data && data.data.length > 0 ? (
            <Box className="ranking-card-table-container">
              <TableContainer>
                <Table className="ranking-card-table" size="small">
                  {!isMobile && (
                    <TableHead className="ranking-card-table-head">
                      <TableRow>
                        <TableCell align="center">#</TableCell>
                        <TableCell align="center">Peso</TableCell>
                        <TableCell align="center">Imagen</TableCell>
                        <TableCell align="center">Nombre</TableCell>
                        <TableCell align="center">Apellidos</TableCell>
                        <TableCell align="center">Club</TableCell>
                        <TableCell align="center">Federación</TableCell>
                        <TableCell align="center">Puntuación</TableCell>
                      </TableRow>
                    </TableHead>
                  )}
                  <TableBody>
                    {data.data.map((row: TRanking, index: number) => (
                      <TableRow
                        hover
                        key={row.name + "-" + index}
                        className="ranking-card-table-row"
                        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                      >
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          {index + 1}
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          {row.weight}
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          <Avatar src={row.avatarUrl} />
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          {row.name}
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          {row.surname}
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          <Link
                            to={`/club/${row.clubId}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            {row.clubShortCode}
                          </Link>
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          <Link
                            to={`/federation/${row.federationId}`}
                            style={{ textDecoration: "none", color: "#000" }}
                          >
                            {row.federationShortCode}
                          </Link>
                        </TableCell>
                        <TableCell className="ranking-card-table-cell" size="small" align="center">
                          {row.score} pts
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ) : (
            <Typography>No hay rankings disponibles</Typography>
          )}
        </Box>
      )}
    </Container>
  );
};

export default Ranking;
