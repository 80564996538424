import "./app-bar.scss";

import { NavLink, useNavigate } from "react-router-dom";

import { CalendarMonth } from "@mui/icons-material";
import { Box, AppBar as MuiAppBar, Toolbar } from "@mui/material";

import { ROUTE_LINKS } from "../../../routes/route_links";

type Props = {
  isDrawerOpen: boolean;
  toggleNavDrawer: () => void;
  userInfo: { claims: []; id: string; roles: string[]; email: string } | null;
};

const AppBar = ({ userInfo }: Props) => {
  const navigate = useNavigate();

  return (
    <MuiAppBar position="absolute" className="appBar-container">
      <Toolbar className="appBar-toolbar">
        <Box className="appBar-logo" onClick={() => navigate("/")} />
        <Box className="appBar-link-container">
          <ul className="appBar-link-list">
            <li>
              <NavLink to={ROUTE_LINKS.HOME} className="appBar-link">
                Inicio
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTE_LINKS.RESULTS} className="appBar-link">
                Resultados
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTE_LINKS.RANKING} className="appBar-link">
                Ranking
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTE_LINKS.EVENTS} className="appBar-link">
                Calendario
              </NavLink>
            </li>
            <li>
              <NavLink to={ROUTE_LINKS.CONTACT} className="appBar-link">
                Contacto
              </NavLink>
            </li>
            <li>
              <a
                href={ROUTE_LINKS.NORMS}
                className="appBar-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Normativa
              </a>
            </li>
            <li>
              <a
                href={ROUTE_LINKS.OFICIALWEB}
                className="appBar-link"
                target="_blank"
                rel="noopener noreferrer"
              >
                Web Oficial
              </a>
            </li>
            <li>
              <NavLink
                to={ROUTE_LINKS.PROFILE}
                className={`appBar-link ${userInfo ? "login-ball-pc" : ""}`}
              >
                {userInfo ? "Mi Perfil" : "Iniciar Sesión"}
              </NavLink>
            </li>
          </ul>
        </Box>
        <Box className="appBar-calendar-container">
          <NavLink to="/calendar" style={{ borderBottom: "0px !important" }}>
            <CalendarMonth className="appBar-calendar-icon" />
          </NavLink>
        </Box>
      </Toolbar>
    </MuiAppBar>
  );
};

export default AppBar;
