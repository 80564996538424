import "./footer.scss";

import { Link } from "react-router-dom";

import { Box, Divider, Grid, Typography } from "@mui/material";

export default function Footer() {
  return (
    <footer className="footer-container">
      <Grid container columnSpacing={4}>
        <Grid item xs={12} sm={6}>
          <Box className="footer-logo-container">
            <img
              src="/images/logos/EU_financiation.png"
              alt="logo-nextGeneration"
              className="footer-logo"
            />
            <img
              src="/images/logos/Plan_recuperacion.png"
              alt="logo-nextGeneration"
              className="footer-logo"
            />
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className="footer-section">
          <Box className="footer-link-container">
            <Typography component="h4" className="footer-title">
              RFEJYDA
            </Typography>
            <ul>
              <li>
                <Link to="mailto:dpd@rfejudo.com" className="footer-link">
                  Contacto LOPD: dpd@rfejudo.com
                </Link>
              </li>
              <li>
                <Link to="/terms" className="footer-link">
                  Términos y condiciones
                </Link>
              </li>
            </ul>
          </Box>
        </Grid>
        <Grid item xs={12} sm={3} className="footer-section">
          <Divider orientation="vertical" flexItem className="footer-divider" />
          <Box className="footer-link-container">
            <Typography component="h4" className="footer-title">
              Redes sociales
            </Typography>
            <ul>
              <li>
                <a
                  href="https://www.facebook.com/RFEJYDA/"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/logos/Facebook_logo_square.png"
                    alt="logo-facebook"
                    className="footer-red-logo"
                  />
                  Facebook
                </a>
              </li>
              <li>
                <a
                  href="https://www.instagram.com/rfejyda/"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/logos/Instagram_icon.png"
                    alt="logo-instagram"
                    className="footer-red-logo"
                  />
                  Instagram
                </a>
              </li>
              <li>
                <a
                  href="https://www.youtube.com/c/RFEJYDAJudo"
                  className="footer-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <img
                    src="/images/logos/youtube_icon.png"
                    alt="logo-youtube"
                    className="footer-red-logo"
                  />
                  YouTube
                </a>
              </li>
            </ul>
          </Box>
        </Grid>
      </Grid>
    </footer>
  );
}
