export const MESSAGE = {
  MAX_255: "Máximo 255 caracteres",
  MAX_100: "Máximo 100 caracteres",
  REQUIRED: "Campo obligatorio",
  EMAIL: "Escribe un email valido",
  PHONE: "Teléfono no válido",
  PASSWORD:
    "La contraseña debe tener al menos 7 letras y contener al menos una mayúscula, un número y un carácter especial",
  EVENT_CODE:
    "El código solo puede contener caracteres en minúsculas, números, guiones bajos (_) y guiones (-).",
};
