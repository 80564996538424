import L, { LatLngExpression } from "leaflet";
import "leaflet/dist/leaflet.css";
import { MapContainer, Marker, TileLayer } from "react-leaflet";

L.Marker.prototype.options.icon = L.icon({
  iconUrl: "https://unpkg.com/leaflet@1.7.1/dist/images/marker-icon.png",
});

function LocationMarker({ position }: { position: LatLngExpression | null }) {
  return position === null ? null : <Marker position={position} />;
}

type Props = {
  lat: string;
  lng: string;
};

export default function Map({ lat, lng }: Props) {
  const position = { lat: parseFloat(lat ?? 0), lng: parseFloat(lng ?? 0) };
  return (
    <MapContainer center={position} zoom={18} style={{ height: "100%", width: "100%" }}>
      <TileLayer
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <LocationMarker position={position} />
    </MapContainer>
  );
}
