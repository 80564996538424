import { Box, Container, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import Button from "../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import RegisterLayout from "../../components/registerLayout/RegisterLayout";
import { useAppDispatch } from "../../context/hooks/hooks";
import { verifyUser } from "../../context/register/registerActions";

import "./verify-app.scss";

const VerifyUser = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleVerifyUser = async () => {
    const token = new URLSearchParams(window.location.search).get("token") || "";
    const setResponse = await dispatch(verifyUser(token));
    if (setResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Usuario verificado correctamente");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (setResponse.type.includes("rejected")) {
      toastMessageError("Error al verificar usuario");
    }
  };

  return (
    <RegisterLayout>
      <Container className="verify-container">
        <Box className="verify-title-container">
          <Typography className="verify-title">Verificar Email</Typography>
          <Typography className="verify-subtitle">
            Estas registrándote en la app de la RFEJYDA
          </Typography>
        </Box>
        <Button rounded="md" className="verify-button" onClick={handleVerifyUser}>
          Verificar cuenta
        </Button>
      </Container>
    </RegisterLayout>
  );
};
export default VerifyUser;
