import { Fragment } from "react";
import { useFormContext } from "react-hook-form";

import { AlternateEmail, BadgeOutlined, PersonOutline, PhoneOutlined } from "@mui/icons-material";
import {
  Box,
  Divider,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
};

function LegalResponsible({ handleStep }: Props) {
  const { register, formState, getValues, trigger } = useFormContext<TUserSchema>();

  const { responsibles } = getValues();

  const handleButtonClick = async () => {
    let validArray = responsibles?.map(async (_, index) => {
      let validation = await trigger([
        `responsibles.${index}.familiarRelationship`,
        `responsibles.${index}.name`,
        `responsibles.${index}.surname`,
        `responsibles.${index}.dni`,
        `responsibles.${index}.phone`,
        `responsibles.${index}.email`,
      ]);

      return validation;
    });
    let response = await Promise.all(validArray || []);

    if (response.every((resp) => resp)) {
      handleStep();
    }
  };

  return (
    <Box className="register-form-data-container">
      {responsibles?.map((_, index) => {
        return (
          <Fragment key={`responsible-${index}`}>
            <FormControl fullWidth>
              <Select
                fullWidth
                displayEmpty
                className="input-select register-select-field"
                defaultValue={""}
                {...register(`responsibles.${index}.familiarRelationship`, {
                  required: "Elija una opción",
                })}
                // renderValue={(value) => {
                //   return (
                //     <Box sx={{ display: "flex", gap: 1 }}>
                //       <SvgIcon color="primary">
                //         <EscalatorWarning className="icon-color-yellow" />
                //       </SvgIcon>
                //       {value}
                //     </Box>
                //   );
                // }}
              >
                <MenuItem disabled value="">
                  <em>Representante Legal*</em>
                </MenuItem>
                <MenuItem value="padre">Padre</MenuItem>
                <MenuItem value="madre">Madre</MenuItem>
                <MenuItem value="tutor">Tutor legal</MenuItem>
              </Select>
              {formState?.errors?.responsibles?.[index]?.familiarRelationship && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.familiarRelationship?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                className="text-field register-text-field"
                variant="outlined"
                placeholder="Nombre*"
                {...register(`responsibles.${index}.name`, {
                  required: "Escriba una respuesta valida",
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutline />
                    </InputAdornment>
                  ),
                }}
              />
              {formState?.errors?.phone && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.name?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                className="text-field register-text-field"
                variant="outlined"
                placeholder="Apellidos*"
                {...register(`responsibles.${index}.surname`, {
                  required: "Escriba una respuesta valida",
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PersonOutline />
                    </InputAdornment>
                  ),
                }}
              />
              {formState?.errors?.responsibles?.[index]?.surname && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.surname?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                className="text-field register-text-field"
                variant="outlined"
                placeholder="DNI*"
                {...register(`responsibles.${index}.dni`, {
                  required: "Escriba una respuesta valida",
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <BadgeOutlined className="icon-yellow" />
                    </InputAdornment>
                  ),
                }}
              />
              {formState?.errors?.responsibles?.[index]?.dni && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.dni?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                className="text-field register-text-field"
                variant="outlined"
                placeholder="Número de teléfono"
                {...register(`responsibles.${index}.phone`, {
                  required: "Escriba una respuesta valida",
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmail />
                    </InputAdornment>
                  ),
                }}
              />
              {formState?.errors?.responsibles?.[index]?.phone && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.phone?.message}
                </Typography>
              )}
            </FormControl>
            <FormControl fullWidth>
              <TextField
                className="text-field register-text-field"
                variant="outlined"
                placeholder="Email"
                {...register(`responsibles.${index}.email`, {
                  required: "Escriba una respuesta valida",
                })}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PhoneOutlined />
                    </InputAdornment>
                  ),
                }}
              />
              {formState?.errors?.responsibles?.[index]?.email && (
                <Typography className="register-input-error">
                  {formState.errors?.responsibles?.[index]?.email?.message}
                </Typography>
              )}
            </FormControl>

            <Divider />
          </Fragment>
        );
      })}

      <Button className="register-button" onClick={handleButtonClick} rounded="md">
        Siguiente
      </Button>
    </Box>
  );
}

export default LegalResponsible;
