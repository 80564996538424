import { useFormContext } from "react-hook-form";

import { GpsFixed, PhoneOutlined } from "@mui/icons-material";
import {
  Box,
  FormControl,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
};

function ContactInfo({ data, handleStep }: Props) {
  const { countries, provinces, municipalities, autonomies } = data;
  const { register, formState, watch, trigger } = useFormContext<TUserSchema>();

  const selectedAutonomy = watch("address.autonomyCode");
  const selectedProvince = watch("address.provinceCode");
  const selectedCountry = watch("address.countryCode");

  const availableProvinces = provinces?.filter((prov) => prov.autonomy_code === selectedAutonomy);
  const availableMunicipalities = municipalities?.filter(
    (mun) => mun.province_code === selectedProvince
  );

  const handleButtonClick = async () => {
    let isValid = await trigger([
      "phone",
      "address.address",
      "address.countryCode",
      "address.autonomyCode",
      "address.provinceCode",
      "address.municipalityCode",
      "address.postalCode",
    ]);

    if (isValid) {
      handleStep();
    }
  };

  return (
    <Box className="register-form-data-container">
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="Número de teléfono*"
          {...register("phone")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PhoneOutlined />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.phone && (
          <Typography className="register-input-error">
            {formState.errors?.phone?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="Dirección*"
          {...register("address.address")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <GpsFixed />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.address?.address && (
          <Typography className="register-input-error">
            {formState.errors?.address?.address?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("address.countryCode")}
        >
          <MenuItem disabled value="">
            <em>País*</em>
          </MenuItem>
          {countries?.map((country) => (
            <MenuItem key={`country-${country?.code}`} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.address?.countryCode && (
          <Typography className="register-input-error">
            {formState.errors?.address?.countryCode.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("address.autonomyCode")}
        >
          <MenuItem disabled value="">
            <em>Autonomía*</em>
          </MenuItem>
          {selectedCountry &&
            autonomies?.map((autonomy) => (
              <MenuItem key={`autonomy-${autonomy?.autonomy_code}`} value={autonomy.autonomy_code}>
                {autonomy.name}
              </MenuItem>
            ))}
        </Select>
        {formState?.errors?.address?.autonomyCode && (
          <Typography className="register-input-error">
            {formState.errors?.address?.autonomyCode.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("address.provinceCode")}
        >
          <MenuItem disabled value="">
            <em>Provincia*</em>
          </MenuItem>
          {availableProvinces?.map((province) => (
            <MenuItem key={`province-${province?.province_code}`} value={province.province_code}>
              {province.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.address?.provinceCode && (
          <Typography className="register-input-error">
            {formState.errors?.address?.provinceCode.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("address.municipalityCode")}
        >
          <MenuItem disabled value="">
            <em>Ciudad*</em>
          </MenuItem>
          {availableMunicipalities?.map((municipality) => (
            <MenuItem
              key={`municipality-${municipality?.municipality_code}`}
              value={municipality.municipality_code}
            >
              {municipality.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.address?.municipalityCode && (
          <Typography className="register-input-error">
            {formState.errors?.address?.municipalityCode.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field-no-icon"
          variant="outlined"
          placeholder="CP*"
          {...register("address.postalCode")}
        />
        {formState?.errors?.address?.postalCode && (
          <Typography className="register-input-error">
            {formState.errors?.address?.postalCode.message}
          </Typography>
        )}
      </FormControl>
      <Button className="register-button" onClick={handleButtonClick} rounded="md">
        Siguiente
      </Button>
    </Box>
  );
}

export default ContactInfo;
