import "./events.scss";

import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";

import { ChevronLeft, TuneOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";

import { DatePicker } from "@mui/x-date-pickers";
import Button from "../../components/button/Button";
import EventCard from "../../components/eventCard/EventCard";
import Loading from "../../components/loader/Loading";
import MenuPopover from "../../components/menuPopover/MenuPopover";
import MenuPopoverDateItem from "../../components/menuPopover/menuPopoverDateItem/MenuPopoverDateItem";
import MenuPopoverItem from "../../components/menuPopover/menuPopoverItem/MenuPopoverItem";
import SearchBar from "../../components/search-bar/search-bar";
import { useAppSelector } from "../../context/hooks/hooks";
import useQueryEvents from "../../utils/hooks/useQueryEvents";
import useSetPageName from "../../utils/hooks/useSetPageName";
import { TEventFilters } from "../../utils/schemas/event";

const Events = () => {
  useSetPageName([{ name: "Eventos", path: "/events" }]);

  const [filtersSelected, setFiltersSelected] = useState<TEventFilters>({
    search: "",
    categoryCode: "",
    fromDate: dayjs().toISOString(),
    toDate: "",
    type: "",
    sportCode: "",
  });

  const { data, isLoading, isRefetching } = useQueryEvents(filtersSelected);

  const {
    categories: categoryData,
    sports,
    eventTypes,
  } = useAppSelector((state) => state.formsOptions);

  const sportsOptions = sports?.map((sport) => ({
    value: sport.code,
    label: sport.name,
  }));

  const eventTypesOptions = eventTypes?.map((type) => ({
    value: type.name,
    label: type.name,
  }));

  const categories = Array.from(
    new Set(
      categoryData
        ?.map((category) => category.categoryName)
        .sort((a, b) => {
          const nameA = a.toUpperCase();
          const nameB = b.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        })
    )
  ).map((category) => ({
    value: category,
    label: category,
  }));

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  //Filter state change
  const handleSelectionChange = (
    position: "search" | "categoryCode" | "fromDate" | "type" | "sportCode" | "toDate",
    value: string | undefined | null
  ) => {
    const prevState = { ...filtersSelected };
    if (prevState[position] === value || !value) {
      prevState[position] = "";
    } else {
      prevState[position] = value;
    }
    setFiltersSelected(prevState);
  };

  return (
    <Container className="events-container">
      <Box className="events-top">
        <Link to="/" className="events-back-button">
          <Button className="events-back-button">
            <ChevronLeft /> Eventos
          </Button>
        </Link>
        <IconButton onClick={handleFilterClick}>
          <TuneOutlined className="events-filter-icon" />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuPopover title=" FILTRAR POR:">
            <MenuPopoverItem
              title="deporte"
              options={sportsOptions}
              selectedValue={filtersSelected["sportCode"]}
              onSelectionChange={(value) => handleSelectionChange("sportCode", value)}
            />
            <MenuPopoverItem
              title="categoría"
              options={categories}
              selectedValue={filtersSelected["categoryCode"]}
              onSelectionChange={(value) => handleSelectionChange("categoryCode", value)}
            />
            <MenuPopoverDateItem
              selectedValue={filtersSelected["toDate"]}
              onSelectionChange={(value) => handleSelectionChange("toDate", value)}
              disablePast
            />
            <MenuPopoverItem
              title="tipo de evento"
              options={eventTypesOptions}
              selectedValue={filtersSelected["type"]}
              onSelectionChange={(value) => handleSelectionChange("type", value)}
            />
          </MenuPopover>
        </Popover>
      </Box>
      {/* Filters */}
      <Grid container columnSpacing={2} rowSpacing={2} marginTop={1}>
        <Grid item md={2} xs={0}>
          <Select
            fullWidth
            displayEmpty
            className="input-select events-select-field"
            value={filtersSelected["sportCode"]}
            renderValue={(selected) => {
              if (!selected) {
                return <>Deporte</>;
              }

              return <>{selected as string}</>;
            }}
            onChange={(value) => handleSelectionChange("sportCode", value.target.value as string)}
          >
            <MenuItem value="">
              <>Deporte</>
            </MenuItem>
            {sports?.map((sport) => (
              <MenuItem key={sport.id} value={sport.code}>
                {sport.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={2} xs={0}>
          <Select
            fullWidth
            displayEmpty
            className="input-select events-select-field"
            value={filtersSelected["categoryCode"]}
            renderValue={(selected) => {
              if (!selected) {
                return <>Categorías</>;
              }

              return <>{selected as string}</>;
            }}
            onChange={(value) =>
              handleSelectionChange("categoryCode", value.target.value as string)
            }
          >
            <MenuItem value="">
              <>Categorías</>
            </MenuItem>
            {categories.map((category) => (
              <MenuItem key={category.value} value={category.value}>
                {category.label}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={2.5} xs={0}>
          <DatePicker
            format="DD/MM/YYYY"
            value={
              filtersSelected["toDate"] && filtersSelected["toDate"] !== ""
                ? dayjs(filtersSelected["toDate"]).toDate()
                : null
            }
            className="input-select events-select-field events-date-picker"
            onChange={(value) => handleSelectionChange("toDate", value?.toISOString())}
            slotProps={{
              field: {
                clearable: true,
                onClear: () => handleSelectionChange("toDate", ""),
              },
            }}
            disablePast
          />
        </Grid>
        <Grid item md={2} xs={0}>
          <Select
            fullWidth
            displayEmpty
            className="input-select events-select-field"
            value={filtersSelected["type"]}
            renderValue={(selected) => {
              if (!selected) {
                return <>Tipo de evento</>;
              }

              return <>{selected as string}</>;
            }}
            onChange={(value) => handleSelectionChange("type", value.target.value as string)}
          >
            <MenuItem value="">
              <>Tipo de evento</>
            </MenuItem>
            {eventTypes?.map((type) => (
              <MenuItem key={type.id} value={type.name}>
                {type.name}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item md={3.5} xs={12}>
          <SearchBar
            className="events-search-bar"
            onSearch={(value) => handleSelectionChange("search", value)}
          />
        </Grid>
      </Grid>

      <Typography className="events-count">
        Eventos: {isLoading ? "Cargando..." : data?.length}
      </Typography>
      {/* Listado de eventos */}
      {isLoading || isRefetching ? (
        <Loading />
      ) : data && data.length > 0 ? (
        <Box className="events-list">
          {data.map((event, i) => {
            return <EventCard key={event.name + `-${i}`} event={event} />;
          })}
        </Box>
      ) : (
        <Typography className="events-no-events">No hay eventos disponibles</Typography>
      )}
    </Container>
  );
};

export default Events;
