import "./personal-data.scss";

import { Link } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Checkbox,
  Container,
  Divider,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import Button from "../../../components/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import Loading from "../../../components/loader/Loading";
import { updateUserData } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import useQueryUser from "../../../utils/hooks/useQueryUser";
import { TUser, addUserSchema } from "../../../utils/schemas/user";
import BasicInfo from "./basicInfo/BasicInfo";
import ContactInfo from "./contactInfo/ContactInfo";
import ResponsibleData from "./responsiblesData/ResponsiblesData";
import SportData from "./sportData/SportData";

export default function PersonalData() {
  const { data, error, isLoading, isRefetching, refetch } = useQueryUser();
  const [openDialog, setOpenDialog] = useState<boolean>(false);
  const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false);
  const [selectedAllergies, setSelectedAllergies] = useState<string[]>([]);
  const [isDisabled, setIsDisabled] = useState<boolean>(true);

  const dispatch = useAppDispatch();

  const formMethods = useForm<TUser>({ mode: "all", resolver: zodResolver(addUserSchema) });
  const { setValue, watch } = formMethods;

  useEffect(() => {
    if (data) {
      formMethods.reset(data);
      if (data.allergy) {
        setSelectedAllergies(data.allergy.split(","));
      }
    }
  }, [data, formMethods]);

  const handleCheckboxChange = (value: string) => {
    if (selectedAllergies.includes(value)) {
      setSelectedAllergies(selectedAllergies.filter((item) => item !== value));
      setValue("allergy", selectedAllergies.join(","));
    } else {
      setSelectedAllergies([...selectedAllergies, value]);
      setValue("allergy", selectedAllergies.join(","));
    }
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleSubmitInfo = async () => {
    setLoadingSubmit(true);
    const userData = formMethods.getValues();
    const updateUserResponse = await dispatch(
      updateUserData({ data: userData, id: userData.id || "" })
    );
    if (updateUserResponse.type.includes("fulfilled")) {
      toastMessageSuccess("Datos actualizados correctamente");
      refetch();
    } else if (updateUserResponse.type.includes("rejected")) {
      toastMessageError("Error al actualizar los datos");
    }
    setOpenDialog(false);
    setLoadingSubmit(false);
  };

  const handleButtonEdit = () => {
    if (isDisabled) {
      setIsDisabled(false);
    } else {
      handleOpenDialog();
      setIsDisabled(true);
    }
  };

  // Condición para manejar la lógica de carga o la ausencia de clubId
  if (isLoading || isRefetching) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <Container className="personalData-container">
      <Link to="/profile" className="personalData-back-button">
        <Button className="personalData-back-button">
          <ChevronLeft /> Datos personales
        </Button>
      </Link>
      <FormProvider {...formMethods}>
        <Box className="personalData-avatar-container">
          <Avatar
            className="personalData-avatar"
            alt="Imagen avatar del perfil del usuario"
            src={data?.avatarUrl || ""}
          />
          <Button
            className="personalData-avatar-button"
            rounded="md"
            onClick={() => handleButtonEdit()}
          >
            {isDisabled ? "Editar" : "Guardar"}
          </Button>
        </Box>
        <BasicInfo isDisabled={isDisabled} />
        <Divider sx={{ my: 2, display: { xs: "none", sm: "block" }, width: "100%" }} />
        <ContactInfo isDisabled={isDisabled} />
        <Divider sx={{ my: 2, display: { xs: "none", sm: "block" }, width: "100%" }} />
        <SportData isDisabled={isDisabled} />
        <Divider sx={{ my: 2, display: { xs: "none", sm: "block" }, width: "100%" }} />
        <Typography className="personalData-title">Alergias(para picnics)</Typography>
        <FormGroup className="allergies-container">
          <FormControlLabel
            control={
              <Checkbox
                className="check-radio-orange"
                onChange={() => handleCheckboxChange("lactosa")}
                checked={selectedAllergies.includes("lactosa")}
                readOnly={isDisabled}
              />
            }
            label="Lactosa"
          />
          <FormControlLabel
            control={
              <Checkbox
                className="check-radio-orange"
                onChange={() => handleCheckboxChange("gluten")}
                checked={selectedAllergies.includes("gluten")}
                readOnly={isDisabled}
              />
            }
            label="Gluten"
          />
        </FormGroup>
        {data?.responsibles && (
          <ResponsibleData responsibles={data.responsibles} isDisabled={isDisabled} />
        )}
        {!isDisabled && (
          <Button
            className="personalData-save-button"
            onClick={handleOpenDialog}
            rounded="md"
            fullWidth
          >
            Guardar
          </Button>
        )}
      </FormProvider>
      <CustomDialog
        isOpen={openDialog}
        onAccept={handleSubmitInfo}
        onClose={handleCloseDialog}
        title="Editar Usuario"
        content="¿Estás seguro que deseas editar tu usuario?"
        isDisabled={loadingSubmit}
      />
    </Container>
  );
}
