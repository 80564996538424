import { Home as HomeIcon } from "@mui/icons-material";

import Calendario from "../pages/calendario/Calendario";
import Events from "../pages/events/Events";
import Home from "../pages/home/Home";
import Profile from "../pages/profile/Profile";
import Ranking from "../pages/ranking/Ranking";

const routes = [
  {
    path: "/",
    element: <Home />,
    id: "Inicio",
    icon: <HomeIcon />,
    roles: [""],
    dashboard: true,
  },
  {
    path: "/calendar",
    element: <Calendario />,
    id: "Calendario",
    icon: <HomeIcon />,
    roles: [""],
    dashboard: true,
  },
  {
    path: "/ranking",
    element: <Ranking />,
    id: "Ranking",
    icon: <HomeIcon />,
    roles: [""],
    dashboard: true,
  },
  {
    path: "/profile",
    element: <Profile />,
    id: "Perfil",
    icon: <HomeIcon />,
    roles: [""],
    dashboard: true,
  },
  {
    path: "/events",
    element: <Events />,
    id: "Events",
    icon: <HomeIcon />,
    roles: [""],
    dashboard: true,
  },
];

export default routes;
