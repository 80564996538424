import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getAllFederationsList } from "../../services/federations";
import { TFederationsList } from "../schemas/federation";

export default function useQueryFederationsList() {
  return useQuery<Array<TFederationsList>, Error>([RQ_KEY.FEDERATIONS_LIST], () =>
    getAllFederationsList()
  );
}
