import axios from "axios";

import { API_URL, SERVICE } from "../constants/api";
import { TEventFilters } from "../utils/schemas/event";
import dayjs from "dayjs";

export const getEvents = async (filters?: TEventFilters) => {
  try {
    const params = new URLSearchParams();
    if (filters?.search && filters.search !== "") params.append("search", filters.search);
    if (filters?.categoryCode && filters.categoryCode !== "")
      params.append("categoryCode", filters.categoryCode);
    if (filters?.fromDate && filters.fromDate !== "")
      params.append("fromDate", dayjs(filters.fromDate).format("YYYY-MM-DD"));
    if (filters?.toDate && filters.toDate !== "")
      params.append("toDate", dayjs(filters.toDate).format("YYYY-MM-DD"));
    if (filters?.type && filters.type !== "") params.append("type", filters.type);
    if (filters?.sportCode && filters.sportCode !== "") params.append("sport", filters.sportCode);
    const { data } = await axios.get(`${API_URL}${SERVICE.EVENTS}?${params}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
};

export async function getEvent(eventId: string) {
  try {
    const { data } = await axios.get(`${API_URL}${SERVICE.EVENTS}/${eventId}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}

export async function getEventResults(eventId: string) {
  try {
    const { data } = await axios.get(`${API_URL}${SERVICE.RESULTS}/event/${eventId}`);

    return data;
  } catch (error) {
    throw new Error("Error en servidor");
  }
}
