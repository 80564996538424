import "./login.scss";

import { FormEvent, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { z } from "zod";

import {
  AlternateEmail,
  ArrowBack,
  Password,
  Visibility,
  VisibilityOff,
} from "@mui/icons-material";
import {
  Checkbox,
  Container,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Link as MuiLink,
  TextField,
  Typography,
} from "@mui/material";

import Button from "../../components/button/Button";
import { loginUser } from "../../context/auth/authActions";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";

const Login = () => {
  const formSchema = z.object({
    email: z.string().email({ message: "Ingrese un email valido" }),
    password: z.string().min(3, { message: "Ingrese una contraseña valida" }),
    // .regex(/^(?=.*[A-Z])(?=.*[-!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,15}$/, {
    //   message: "La contraseña no cumple los requisitos",
    // }),
  });

  const [errors, setErrors] = useState<Record<string, string>>({});
  const [remember, setRemember] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading } = useAppSelector((state) => state.auth);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    setErrors({});
    const formData = new FormData(event.currentTarget);
    let email = formData.get("email")?.toString();
    let password = formData.get("password")?.toString();

    const data = { email, password };
    try {
      const validatedData = formSchema.parse(data);
      const response = await dispatch(loginUser({ data: validatedData, remember: remember }));
      if (typeof response.payload === "string") {
        setErrors({ noValid: response.payload });
      } else {
        navigate("/");
      }
    } catch (error: any) {
      if (error instanceof z.ZodError) {
        if (error instanceof z.ZodError) {
          const errors: Record<string, string> = {};
          for (const issue of error.issues) {
            if (issue.path[0]) {
              errors[issue.path[0]] = issue.message;
            }
          }
          setErrors(errors);
        }
      }
    }
  };

  return (
    <Container
      className="login-container"
      maxWidth="xl"
      component="form"
      noValidate
      onSubmit={handleSubmit}
    >
      <Grid container justifyContent="flex-start">
        <IconButton onClick={() => navigate("/")}>
          <ArrowBack className="icon-color-yellow" />
        </IconButton>
      </Grid>
      <Container className="login-logo" component={"div"} onClick={() => navigate("/")} />
      <Typography className="login-title" component="h1" variant="h5">
        REAL FEDERACIÓN ESPAÑOLA DE JUDO Y DEPORTES ASOCIADOS
      </Typography>
      <TextField
        className="text-field login-text-field"
        name="email"
        id="email"
        autoComplete="email"
        autoFocus
        variant="outlined"
        placeholder="Email*"
        error={!!errors.email}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AlternateEmail />
            </InputAdornment>
          ),
        }}
      />
      <TextField
        className="text-field login-text-field"
        type={showPassword ? "text" : "password"}
        id="password"
        name="password"
        autoComplete="current-password"
        placeholder="Contraseña*"
        error={!!errors.password}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Password />
            </InputAdornment>
          ),
          endAdornment: (
            <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={handleClickShowPassword}
                edge="end"
              >
                {showPassword ? <VisibilityOff /> : <Visibility />}
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
      <FormControlLabel
        className="login-checkbox-container"
        control={
          <Checkbox
            value="remember"
            className="login-checkbox"
            checked={remember}
            onChange={(e) => setRemember(e.target.checked)}
          />
        }
        label="Mantener sesión iniciada"
      />
      {Object.values(errors).map((error, i) => (
        <Typography key={`error-${i}`} className="login-error-text" component="p">
          {error}
        </Typography>
      ))}
      <Button
        size="large"
        type="submit"
        rounded="md"
        width="fit-content"
        className="login-button-login"
        disabled={loading}
      >
        {loading ? "Cargando" : "Iniciar sesión"}
      </Button>
      <MuiLink component={Link} to="/forget-password" className="login-forget-text">
        ¿Olvidaste tu Contraseña?
      </MuiLink>
      <MuiLink component={Link} to="/register-user" className="login-link-create">
        ¿Todavía no tienes una cuenta? Regístrate aquí.
      </MuiLink>
      <Grid container justifyContent="space-between" className="login-logos-container">
        <Grid item xs={6}>
          <img
            src="images/logos/EU_financiation.png"
            alt="Imagen Financiado por la Unión Europea"
            className="login-image"
          />
        </Grid>
        <Grid item xs={6}>
          <img
            src="images/logos/Plan_recuperacion.png"
            alt="Plan de recuperación, Transformación y Resiliencia"
            className="login-image"
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default Login;
