import { Box, FormControl, Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../context/hooks/hooks";
import { TInscriptionData } from "../../../utils/schemas/inscription";
import "./direction.scss";

type Props = {
  isDisabled?: boolean;
};

export default function Direction({ isDisabled }: Props) {
  const { register, formState, watch, control } = useFormContext<TInscriptionData>();
  const { errors } = formState;
  const { countries, autonomies, provinces, municipalities } = useAppSelector(
    (state) => state.formsOptions
  );

  const province = watch("address.provinceCode");

  return (
    <Grid>
      <Typography className="direction-title" mb={2}>
        Dirección
      </Typography>
      <Grid container columnSpacing={4} rowGap={2}>
        <Grid item sm={6} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Dirección*</Typography>
            <TextField
              className="input-text text-field"
              variant="outlined"
              placeholder="Dirección"
              required
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.address?.address}
              {...register("address.address")}
            />
            {errors?.address && (
              <Typography className="form-input-error">{errors?.address?.message}</Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">País*</Typography>
            <Controller
              name="address.countryCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.countryCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>País</em>
                  </MenuItem>
                  {countries?.map((country) => (
                    <MenuItem key={country.code} value={country.code}>
                      {country.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.countryCode && (
              <Typography className="form-input-error">
                {errors?.address?.countryCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Autonomía*</Typography>
            <Controller
              name="address.autonomyCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.autonomyCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Autonomía</em>
                  </MenuItem>
                  {autonomies?.map((autonomy) => (
                    <MenuItem key={autonomy.autonomy_code} value={autonomy.autonomy_code}>
                      {autonomy.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
            {errors?.address?.autonomyCode && (
              <Typography className="form-input-error">
                {errors?.address?.autonomyCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Provincia*</Typography>
            <Controller
              name="address.provinceCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.provinceCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Provincia / Región</em>
                  </MenuItem>
                  {provinces
                    ?.filter((province) => province.autonomy_code == watch("address.autonomyCode"))
                    .map((province) => (
                      <MenuItem key={province.province_code} value={province.province_code}>
                        {province.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.provinceCode && (
              <Typography className="form-input-error">
                {errors?.address.provinceCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">Ciudad*</Typography>
            <Controller
              name="address.municipalityCode"
              control={control}
              defaultValue={""}
              render={({ field }) => (
                <Select
                  className="input-select form-select-field"
                  error={!!errors?.address?.municipalityCode}
                  {...field}
                  displayEmpty
                  defaultValue={""}
                  required
                  slotProps={{ input: { readOnly: isDisabled } }}
                >
                  <MenuItem value={""}>
                    <em>Ciudad</em>
                  </MenuItem>
                  {municipalities
                    ?.filter((municipality) => municipality.province_code == province)
                    .map((municipality) => (
                      <MenuItem
                        key={municipality.municipality_code}
                        value={municipality.municipality_code}
                      >
                        {municipality.name}
                      </MenuItem>
                    ))}
                </Select>
              )}
            />
            {errors?.address?.municipalityCode && (
              <Typography className="form-input-error">
                {errors?.address.municipalityCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
        <Grid item sm={3} xs={12}>
          <FormControl fullWidth>
            <Typography className="form-input-label">CP*</Typography>
            <TextField
              className="input-text text-field"
              variant="outlined"
              placeholder="CP"
              required
              InputProps={{
                readOnly: isDisabled,
              }}
              error={!!errors?.address?.postalCode}
              {...register("address.postalCode")}
            />
            {errors?.address?.postalCode && (
              <Typography className="form-input-error">
                {errors?.address?.postalCode?.message}
              </Typography>
            )}
          </FormControl>
        </Grid>
      </Grid>
    </Grid>
  );
}
