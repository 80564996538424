import "./inscription-detail.scss";

import { useEffect, useState } from "react";
import { FormProvider, SubmitHandler, useForm } from "react-hook-form";

import { Divider, Typography } from "@mui/material";

//TODO: primero que se pueda descargar el anexo en movil
import dayjs from "dayjs";
import { useNavigate, useParams } from "react-router-dom";
import Button from "../../../components/common/button/Button";
import { toastMessageError, toastMessageSuccess } from "../../../components/common/toastMessage";
import ContactInfo from "../../../components/formComponents/contactInfo/ContactInfo";
import Direction from "../../../components/formComponents/direction/Direction";
import InscriptionConsents from "../../../components/formComponents/inscriptionConsents/InscriptionConsents";
import PersonalInfo from "../../../components/formComponents/personalInfo/PersonalInfo";
import SportInfo from "../../../components/formComponents/sportInfo/SportInfo";
import Loading from "../../../components/loader/Loading";
import { sendUserInscriptionData } from "../../../context/forms/formsActions";
import { useAppDispatch } from "../../../context/hooks/hooks";
import useConcentScript from "../../../utils/hooks/useConcent";
import useQueryInscriptionId from "../../../utils/hooks/useQueryInscriptionId";
import useQueryUserInscription from "../../../utils/hooks/useQueryUserInscriprion";
import { TInscriptionData } from "../../../utils/schemas/inscription";
import { getEvent } from "../../../services/events";

type Props = {
  isRealized?: boolean;
};

export default function InscriptionDetail({ isRealized }: Props) {
  const { recogerConsentimiento } = useConcentScript();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { data, error, isLoading } = useQueryUserInscription();

  const { inscriptionId } = useParams();
  const { data: inscription } = useQueryInscriptionId(inscriptionId || "");

  const [event, setEvent] = useState(() => {
    const saved = localStorage.getItem("event");
    return saved ? JSON.parse(saved) : null;
  });

  const getEventData = async () => {
    const eventData = await getEvent(inscription?.eventId ?? "");
    setEvent(eventData);
  };

  useEffect(() => {
    if (!event) {
      getEventData();
    }
  }, []);

  const formMethods = useForm<TInscriptionData>({
    mode: "all",
  });
  const watch = formMethods.watch;
  const setValue = formMethods.setValue;

  const onSubmit: SubmitHandler<TInscriptionData> = async (data) => {
    const body = {
      eventId: event.id,
      categoryId: data.categoryId,
      userId: data.id,
      privacyPolicyConsent: data.privacyPolicyConsent,
      obligationsAndConditionsConsent: data.obligationsAndConditionsConsent,
      thirdPartiesCompulsoryConsent: data.thirdPartiesCompulsoryConsent,
      minorDataTreatmentConsent: data.minorDataTreatmentConsent,
      imageConsent: data.imageConsent,
      communicationsConsent: data.communicationsConsent,
      thirdPartiesNonCompulsoryConsent: data.thirdPartiesNonCompulsoryConsent,
    };
    const createResponse = await dispatch(sendUserInscriptionData(body));
    if (createResponse.type.includes("fulfilled")) {
      recogerConsentimiento(
        document.getElementById("rfejyda-inscription")!,
        "rfejyda-inscription",
        "app.rfejudo.com/inscriptions"
      );
      toastMessageSuccess("Inscripción enviada");
      setTimeout(() => {
        navigate("/profile/my_inscriptions");
      }, 2000);
    } else if (createResponse.type.includes("rejected")) {
      toastMessageError("Error al enviar inscripción");
    }
  };

  const onError = (error: any) => {
    console.log("error en el formulario", error);
  };

  useEffect(() => {
    if (data) {
      formMethods.reset(data);
    }
    if (inscription) {
      setValue("categoryId", inscription.categoryId);
      setValue("privacyPolicyConsent", inscription.privacyPolicyConsent);
      setValue("obligationsAndConditionsConsent", inscription.obligationsAndConditionsConsent);
      setValue("thirdPartiesCompulsoryConsent", inscription.thirdPartiesCompulsoryConsent);
      setValue("minorDataTreatmentConsent", inscription.minorDataTreatmentConsent);
      setValue("imageConsent", inscription.imageConsent);
      setValue("communicationsConsent", inscription.communicationsConsent);
      setValue("thirdPartiesNonCompulsoryConsent", inscription.thirdPartiesNonCompulsoryConsent);
    }
  }, [data, inscription, formMethods, setValue]);

  const isPrivacyPolicyChecked =
    watch("privacyPolicyConsent") !== "" && !!watch("privacyPolicyConsent");
  const isObligationsChecked =
    watch("obligationsAndConditionsConsent") !== "" && !!watch("obligationsAndConditionsConsent");
  const isCommunicationsChecked =
    watch("communicationsConsent") !== "" && !!watch("communicationsConsent");
  const isCategoryChecked = watch("categoryId") !== "" && !!watch("categoryId");

  const isRequiredChecked =
    isPrivacyPolicyChecked && isObligationsChecked && isCategoryChecked && isCommunicationsChecked;

  // Condición para manejar la lógica de carga o la ausencia de clubId
  if (isLoading || !data) return <Loading />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <>
      <Typography variant="h4" color="text.secondary" component="div" className="headerh3">
        Mi inscripción a {event?.name}
      </Typography>
      <div className="container">
        <FormProvider {...formMethods}>
          <form
            id="inscription-form"
            onSubmit={formMethods.handleSubmit(onSubmit, onError)}
            className="form-container"
          >
            <PersonalInfo isDisabled />
            <Divider sx={{ my: 2 }} />

            <ContactInfo isDisabled />
            {watch("responsibles") && watch("responsibles").length > 0 ? (
              <Divider sx={{ my: 2 }} />
            ) : null}

            <Direction isDisabled />
            <Divider sx={{ my: 2 }} />

            <SportInfo
              stocks={event?.stocks}
              categoryId={inscription?.categoryId}
              inscriptionId={inscription?.id}
              isRealized={isRealized}
              weighingChangeLimitDate={event?.weighingChangeLimitDate}
            />
            <Divider sx={{ my: 2 }} />

            <InscriptionConsents
              watch={formMethods.watch}
              setValue={formMethods.setValue}
              isRealized={isRealized}
            />
            {!isRealized && (
              <div style={{ padding: "20px", marginBottom: "70px" }}>
                <Button
                  style={{ margin: "auto" }}
                  type="submit"
                  variant="contained"
                  disabled={!isRequiredChecked}
                >
                  Siguiente
                </Button>
              </div>
            )}
          </form>
        </FormProvider>
        {/* Datos para enviar a consentimientos */}
        <form id="rfejyda-inscription" className="rfejyda-inscription-minors">
          <label htmlFor="id">Id</label>
          <input name="id" value={watch("id")} />
          <label htmlFor="eventName">Nombre evento</label>
          <input name="eventName" value={event?.name} />
          <label htmlFor="name">Nombre deportista</label>
          <input name="name" value={`${watch("name")}, ${watch("surname")}`} />
          <label htmlFor="phone">Teléfono deportista</label>
          <input name="phone" value={watch("phone")} />
          <label htmlFor="privacyPolicyConsent">Política privacidad</label>
          <input name="privacyPolicyConsent" value={watch("privacyPolicyConsent")} />
          <label htmlFor="obligationsAndConditionsConsent">Obligaciones y condiciones</label>
          <input
            name="obligationsAndConditionsConsent"
            value={watch("obligationsAndConditionsConsent")}
          />
          <label htmlFor="imageConsent">Autorización imagen</label>
          <input name="imageConsent" value={watch("imageConsent") || "NO"} />
          <label htmlFor="thirdPartiesNonCompulsoryConsent">
            Cesión datos a terceras entidades(necesarias)
          </label>
          <input
            name="thirdPartiesNonCompulsoryConsent"
            value={watch("thirdPartiesNonCompulsoryConsent")}
          />
          <label htmlFor="thirdPartiesCompulsoryConsent">Cesión datos a terceras entidades</label>
          <input
            name="thirdPartiesCompulsoryConsent"
            value={watch("thirdPartiesCompulsoryConsent") || "NO"}
          />
          <label htmlFor="communicationsConsent">Comunicaciones</label>
          <input name="communicationsConsent" value={watch("communicationsConsent") || "NO"} />
          {watch("bornAt") && dayjs().diff(watch("bornAt"), "year") < 18 ? (
            <>
              <label htmlFor="minorDataTreatmentConsent">Tratamiento datos del menor</label>
              <input name="minorDataTreatmentConsent" value={watch("minorDataTreatmentConsent")} />
              <label htmlFor="nameTutor">Nombre tutor legal</label>
              <input name="nameTutor" value={watch("responsibles")[0]?.name} />
              <label htmlFor="email">Email tutor legal</label>
              <input name="email" value={watch("responsibles")[0]?.email} />
            </>
          ) : (
            <>
              <label htmlFor="email">Email</label>
              <input name="email" value={watch("email")} />{" "}
            </>
          )}
        </form>
      </div>
    </>
  );
}
