import { SportsKabaddiOutlined, SportsMartialArtsOutlined } from "@mui/icons-material";
import { Grid, InputAdornment, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Controller, useFormContext } from "react-hook-form";
import { useAppSelector } from "../../../../context/hooks/hooks";
import { TUser } from "../../../../utils/schemas/user";

type Props = {
  isDisabled: boolean;
};

const SportData = ({ isDisabled }: Props) => {
  const { control, register } = useFormContext<TUser>();

  const { belts } = useAppSelector((state) => state.formsOptions);

  return (
    <>
      <Typography variant="h4" component="h4" className="personalData-title">
        Datos deportivos
      </Typography>
      <Grid container columnSpacing={4} rowGap={2}>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Federación autónoma*"
            {...register("federation.name")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SportsKabaddiOutlined />
                </InputAdornment>
              ),
              readOnly: true,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <TextField
            placeholder="Club*"
            {...register("club.name")}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SportsMartialArtsOutlined />
                </InputAdornment>
              ),
              readOnly: true,
            }}
            className="input-text text-field"
            variant="outlined"
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <Controller
            name="currentBeltId"
            control={control}
            defaultValue={""}
            render={({ field }) => (
              <Select
                className="input-select results-select-field personal-data-select-field"
                variant="outlined"
                fullWidth
                {...field}
                readOnly={isDisabled}
              >
                <MenuItem value="">Cinturón*</MenuItem>
                {belts?.map((belt) => (
                  <MenuItem key={belt.id} value={belt.id}>
                    {belt.name}
                  </MenuItem>
                ))}
              </Select>
            )}
          />
        </Grid>
      </Grid>
    </>
  );
};
export default SportData;
