import { Container, Typography } from "@mui/material";
import './terms.scss';

function Terms() {
    return (
        <>
            <Container className="terms-container" maxWidth="xl">
                <Typography className="terms-title" component="h1">
                    TÉRMINOS Y CONDICIONES DE USO LEGAL APP
                </Typography>
                <Typography className="terms-subtitle" component="h1">
                    1. Titulares o prestadores del servicio
                </Typography>
                <Typography className="terms-contenido" component="h4">
                    En cumplimiento del artículo 10 de la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de la Información y Comercio Electrónico (LSSICE), a continuación, se exponen los datos identificativos del prestador de servicio de la App denominada “APP RFEJYDA”.
                    <br />
                    Responsable: REAL FEDERACIÓN ESPAÑOLA DE JUDO Y DEPORTES ASOCIADOS
                    <br />
                    CIF: Q2878023G
                    <br />
                    Dirección: Calle Ferraz, 16 – 28008 Madrid (España)
                    <br />
                    Tfno.: 915 594 876
                    <br />
                    Email: info@rfejudo.com
                    <br />
                    Contacto DPD: dpo@gfmservicios.com
                </Typography>

                <Typography className="terms-subtitle" component="h4">

                    2. Condiciones de uso
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La persona usuaria se obliga a usar los contenidos ofrecidos en la APP de la RFEJYDA (“Contenidos”) de forma diligente, correcta y lícita y, en particular, se compromete a abstenerse de (a) utilizar los Contenidos de forma, con fines o efectos contrarios a la ley, a la moral y a las buenas costumbres generalmente aceptadas o al orden público; (b) reproducir o copiar, distribuir, permitir el acceso del público a través de cualquier modalidad de comunicación pública, transformar o modificar los Contenidos, a menos que se cuente con la autorización del titular de los correspondientes derechos o ello resulte legalmente permitido; (c) suprimir, eludir o manipular el 'copyright' y demás datos identificativos.
                    La utilización de la APP por una tercera persona le atribuye la condición de persona usuaria y, supone la aceptación plena por dicha persona usuaria, de todas y cada una de las condiciones que se incorporan en el presente Aviso legal.<br />
                    La persona usuaria se compromete a utilizar la APP de conformidad con la Ley, las presentes Condiciones de Uso, y demás avisos, reglamentos de uso e instrucciones puestos en su conocimiento, así como con la moral y las buenas costumbres generalmente aceptadas y el orden público.
                    Queda prohibido realizar sin la debida autorización cualquier tipo de publicidad o información comercial directamente o de forma encubierta el envío de correos masivos ('spaming').<br />
                    La persona usuaria se compromete a no introducir programas, virus, macros, applets, controles ActiveX o cualquier otro dispositivo lógico o secuencia de caracteres que causen o sean susceptibles de causar cualquier tipo de alteración en los sistemas informáticos de ________________________________
                </Typography>
                <Typography className="terms-subtitle" component="h4">

                    3. Objeto de la App:
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La REAL FEDERACIÓN ESPAÑOLA DE JUDO Y DEPORTES ASOCIADOS (RFEJYDA)  ha contratado los servicios del Proveedor ZARAMICRO, que es una empresa dedicada al diseño de productos informáticos de gestión empresarial, tanto para empresas como para organizaciones y organismos públicos.
                    La aplicación desarrollada para la RFEJYDA permite a la Federación gestionar la relación entre la propia Federación y los demás entes intervinientes en la relación federativa (federaciones autonómicas, clubes y deportistas federados). A partir de esta aplicación, todos los intervinientes tienen la capacidad de gestionar la información relativa a la licencia federativa de los y las deportistas y su participación en las distintas competiciones organizadas a nivel nacional y/o territorial. La aplicación permite, además, estudiar los resultados de las actividades para sacar estadísticas y poder mejorar el rendimiento de los y las deportistas.
                    La aplicación permite al deportista la descarga en dispositivo móvil para consultar su información como deportista federado, así como gestionar la inscripción y participación en las distintas actividades relacionadas con la disciplina deportiva en la que esté interesado/a.
                   <br/> Funcionalidades de la aplicación:<br/>
                    APP RFEJYDA  es una herramienta que permite a los intervinientes en la relación federativa y en la realización y organización de actividades relacionadas con el objeto de la actividad que lleva a cabo la RFEJYDA de acuerdo con lo previsto en la Ley 39/2022, del Deporte, sus Estatutos y Reglamento.
                    Entre estos intervinientes se encontrarán las propias federaciones española y territoriales, clubes, deportistas y entidades organizadoras de competiciones en las que participan las personas federadas.
                    Esta APP es una solución que permite contar con un lugar único para la gestión de la información de los intervinientes, así como para compartir esta información de forma ágil y actualizada. Además, permite realizar los trámites necesarios para la inscripción en las actividades y competiciones organizadas por la propia RFEJYDA o por otras entidades organizadoras.
                    <br />En la aplicación se gestionan las siguientes áreas:<br />
                    <ul>
                        <li>Datos generales de los deportistas, así como de otros usuarios que representen a las federaciones territoriales y clubes.
                        </li>
                        <li>Características, clasificaciones y demás información relativa a las actividades, cursos y competiciones.
                        </li>
                        <li>Categoría y participaciones de los/las deportistas.
                        </li>
                        <li>Datos generales de clubes.
                        </li>
                    </ul>
                    <br/>Estadísticas e información relacionada con el rendimiento de los y las deportistas.<br />
                    <br /> La descarga de esta APP en los dispositivos móviles de los y las deportistas les permitirá realizar las siguientes acciones:<br />
                    <ul>
                        <li>Consultar su ficha federativa y los datos de pertenencia a un club y federación.
                        </li>
                        <li>Consultar sus estadísticas y otros datos relativos al rendimiento.
                        </li>
                        <li>Consultar e inscribirse en las diferentes actividades, cursos y competiciones, incluidas en la misma.
                        </li>
                    </ul>
                    <br />Seguridad en la información<br />
                    La información de los/las deportistas está almacenada en servidores incloud de AWS,  permitiendo un balance de carga eficiente y una escalabilidad a medida que el proyecto crece. La captura de la información se realiza mediante llamadas https, el almacenamiento es en la nube, en el RDS de Amazon, dentro la VPC con acceso mediante VPN.
                    La arquitectura está basada en microservicios utilizando el API Gateway de Amazon Web Services (AWS) y las Lambdas de AWS para manejar las solicitudes entrantes. Todo esto se encapsula en contenedores Docker para facilitar su despliegue y escalabilidad.
                    La base de datos del proyecto es SQL, donde todos los datos se almacenan de manera cifrada para garantizar la mayor privacidad y seguridad. Un sistema de registro de logs está en su lugar para facilitar el mantenimiento y la detección de posibles incidencias.
                    <br />Se utilizan los siguientes servicios:<br />
                    <ul>
                        <li>VPC (Virtual Private Cloud): Permite a los usuarios de AWS lanzar recursos de AWS en una red virtual que ellos mismos han definido. Esta red virtual es aislada del resto de la red de AWS, permitiendo a los usuarios controlar el entorno de red, incluyendo la selección de su propio rango de direcciones IP, creación de subredes, configuración de tablas de rutas, y configuración de puertas de enlace de red.
                        </li>
                        <li>EC2 (Elastic Compute Cloud): Ofrece capacidad de computación escalable en la nube de AWS. Los usuarios pueden lanzar instancias virtuales con una variedad de sistemas operativos, configurar la memoria, la CPU, el almacenamiento, y más según la necesidad de su aplicación. EC2 permite escalar hacia arriba o hacia abajo para manejar cambios en los requisitos o picos en la popularidad.
                        </li>
                        <li>CIDR (Classless Inter-Domain Routing): Aunque no es un servicio de AWS específicamente, CIDR es un método utilizado para asignar direcciones IP en bloques más eficientes y flexibles que los sistemas de direccionamiento tradicionales basados en clases. AWS utiliza la notación CIDR para especificar rangos de IP dentro de las VPCs y subredes.
                        </li>
                        <li>ACM (AWS Certificate Manager): Es un servicio que facilita a los usuarios la provisión, gestión e implementación de certificados SSL/TLS para usar con los servicios de AWS. Esto permite a los usuarios asegurar la comunicación entre sus clientes y los recursos de AWS, como los sitios web.
                        </li>
                        <li>VPN (Virtual Private Network): AWS ofrece la posibilidad de establecer una VPN para conectar de forma segura tu red corporativa o centro de datos con la infraestructura en AWS. Esto permite extender las redes existentes a la nube de forma segura a través de Internet o mediante una conexión Direct Connect. Actualmente Tenemos 2 entornos con 2 redes VPN diferentes, TEST y PRODUCCION.
                        </li>
                        <li>RDS (Relational Database Service) es un servicio de AWS que facilita la configuración, operación y escalado de una base de datos relacional en la nube. Proporciona una gestión eficiente y escalable de bases de datos SQL.
                        </li>
                    </ul>
                </Typography>
                <Typography className="terms-subtitle" component="h4">

                    4.  Acceso a la App y registro de usuarios
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    En un primer lugar, es la propia RFEJYDA quien creará los usuarios de las federaciones territoriales para el volcado de los datos de éstas en la aplicación. Posteriormente, serán los usuarios creados por cada federación territorial quienes darán de alta los diferentes clubes en el ámbito territorial correspondiente a cada una de ellas.
                    Una vez realizado esto, los usuarios dados de alta por los clubes, darán de alta a los deportistas que forman parte de cada uno de estos clubes, asignándolos al mismo.
                    Realizadas estas primeras gestiones, el o la deportista recibirá un correo electrónico en el que se le solicitará que confirme los datos con los que se le ha dado de alta; fijando, entre otras, cosas la contraseña con la que podrá acceder a su área privada.
                    Desde el momento en que el o la deportista ha establecido su cuenta, tendrá la posibilidad de descargarse en sus dispositivos móviles la APP para acceder a aquellas funcionalidades descritas en apartados anteriores, a través de Google Play Store (Android) o de Apple Store (iOS).
                    En el momento en que el o la deportista recibe el mail para el establecimiento de sus datos en su perfil, deberá aceptar los Términos y Condiciones de Uso, así como autorizar o rechazar aquellas autorizaciones que sean necesarias según las actividades realizadas por la RFEJYDA u otras entidades intervinientes. La entidad se reserva el derecho de poder dar de alta o baja a las personas usuarias.
                    <br />Tipología de Usuarios<br />
                    Existen diferentes tipos (Roles)  de Usuarios con acceso a la información:<br />
                    <ul>
                        <li>RFEJYDA: ver y editar cualquier información que se encuentre dentro del software o aplicación.<br />
                        </li>
                        <li>Federación Territorial: Solo podrá ver información relacionada con los clubes y deportistas relacionados con su ámbito territorial. Solo puede editar información relativa a los clubes de su ámbito territorial.<br />
                        </li>
                        <li>Club: Solo podrá ver información relacionada con los deportistas inscritos en su club. Solo puede editar información relativa a los deportistas que forman parte de su club.<br />
                        </li>
                        <li>Deportista: Solo puede ver y editar su perfil de deportista.<br />
                        </li>
                        <li>Persona IT: persona responsable de un evento, solo puede ver la información de aquellos deportistas inscritos al evento concreto.<br />
                        </li>

                    </ul>
                    Cualquier usuario, independientemente de su rol, puede consultar toda la información que sea pública, es decir: ficha de deportistas, de club o de federación, así como, ficha de cada evento que se organice o se celebre.<br />
                </Typography>
                <Typography className="terms-subtitle" component="h4">

                    5. Régimen de responsabilidad
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    <br /><strong>5.1. Responsabilidad por el Uso de la APP</strong>
                    <br />La persona usuaria es la única responsable de las infracciones en las que pueda incurrir o de los perjuicios que pueda causar por la utilización de la APP, quedando la RFEJYDA exonerada de cualquier clase de responsabilidad que se pudiera derivar por las acciones de la persona usuaria.
                    La RFEJYDA empleará todos los esfuerzos y medios razonables para facilitar información actualizada y fehaciente en la APP de la persona usuaria. No obstante, no asume ninguna garantía en relación con la ausencia de errores, o de posibles inexactitudes y/u omisiones en ninguno de los contenidos accesibles a través de la APP.
                    La persona usuaria es la única responsable frente a cualquier reclamación o acción legal, judicial o extrajudicial, iniciada por terceras personas contra la RFEJYDA  basada en la utilización por la persona usuaria de la APP.

                    <br /> <strong>5.2. Responsabilidad por el funcionamiento de la APP</strong>
                    <br />La RFEJYDA excluye toda responsabilidad que se pudiera derivar de interferencias, omisiones, interrupciones, virus informáticos, averías telefónicas o desconexiones en el funcionamiento operativo del sistema electrónico, motivado por causas ajenas a la RFEJYDA
                    Asimismo, también excluye cualquier responsabilidad que pudiera derivarse por retrasos o bloqueos en el funcionamiento operativo de este sistema electrónico causado por deficiencias o sobre carga en las líneas telefónicas o en Internet, así como de daños causados por terceras personas mediante intromisiones ilegítimas fuera del control de la RFEJYDA.
                    La RFEJYDA  está facultada para suspender temporalmente, y sin previo aviso, la accesibilidad a la APP con motivo de operaciones de desarrollo, mantenimiento, reparación, actualización o mejora.

                </Typography>
                <Typography className="terms-subtitle" component="h4">
                    6. Protección de Datos
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    Responsable del tratamiento: REAL FEDERACIÓN ESPAÑOLA DE JUDO Y DEPORTES ASOCIADOS
                    <br />NIF: Q2878023G
                    <br />Dirección: Calle Ferraz, 16 – 28008 Madrid (España)
                    <br />Tfno.: 915 594 876
                    <br />Email: info@rfejudo.com
                    <br />Contacto (DPO): dpo@gfmservicios.com
                    <br />
                    <br /> Finalidad del tratamiento:
                    La RFEJYDA  tratará sus datos personales para facilitar el uso y los servicios propios de la aplicación APP RFEJYDA y, en caso de que acepte los términos y condiciones y así lo autorice de forma expresa, tratará sus datos para aquellas finalidades que expresamente haya autorizado de acuerdo con las autorizaciones que haya otorgado y que se facilitan en el momento de su registro como usuario o, posteriormente, en el perfil de usuario.
                    <br />Legitimación:
                    <ul>
                        <li>La ejecución de un contrato.</li>
                        <li>El consentimiento del interesado/a.</li>
                        <li>El interés legítimo de la persona responsable.</li>
                    </ul>
                    <br />Destinatarios:
                    <br />Sus datos no podrán ser comunicados a terceros a menos que lo autorice de forma expresa, prestando su consentimiento.
                    Los datos se cederán únicamente a empresas y organizaciones relacionadas con la RFEYJDA, necesarios para la organización y celebración de eventos deportivos en los que esté interesado participar el deportista que se encuentra federado.
                    <br />
                    <br />Conservación de los datos
                    <br />La RFEJYDA conservará sus Datos Personales mientras usted tenga descargada la aplicación en su dispositivo, no revoque su consentimiento, y/o no ejerza su derecho de supresión, oposición o limitación del tratamiento. Conservamos sus Datos Personales durante el tiempo que los necesitemos para las finalidades indicadas en esta política de privacidad, para satisfacer sus necesidades y para cumplir con nuestras obligaciones legales.
                    Derechos e información adicional:
                    Puede acceder, rectificar y suprimir los datos, así como ejercer el resto de los derechos que la normativa le reconoce, tal y como se explica en la información adicional sobre protección de datos en el formulario web creado al efecto “Ejercicio de derechos ArSol” en nuestra página www.rfejudo.es
                </Typography>
                <Typography className="terms-subtitle" component="h4">
                    7. Exclusión de garantías; limitación de responsabilidad
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La RFJYDA, como entidad que gestiona organizativamente la APP, manifiesta que:
                    <ul>
                        <li>a) La RFEJYDA operará la APP con un grado razonable de profesionalidad y diligencia, no realizará ninguna otra promesa ni otorga ninguna otra garantía acerca de la APP y, en particular, no garantiza que:
                            <ul>
                                <li>
                                    i.	el uso que la persona usuaria haga de la APP estará libre de interrupciones o errores. La persona usuaria acepta que la RFEJYDA  pueda interrumpir ocasionalmente el funcionamiento de la APP, por periodos de tiempo indefinidos o cerrar la APP de en cualquier momento por razones técnicas u operativas, en cuyo caso le comunicará dicha circunstancia en la medida de lo posible;
                                </li>
                                <li>
                                    ii.	la APP estará libre de pérdidas, corrupciones, ataques, virus, interferencias, actos de piratería informática u otras intromisiones en la seguridad, que constituirán circunstancias de fuerza mayor, declinando la RFEJYDA  cualquier responsabilidad en relación con tales circunstancias.
                                </li>
                            </ul>
                        </li>
                        <li>
                            b) La RFEJYDA  tratará por todos los medios posibles proteger la información enviada por la persona usuaria a la App, incluyendo la protección frente a cualquier tipo de uso fraudulento, pudiendo la RFEJYDA  solicitar el cambio de contraseña en cualquier momento a efectos de garantizar la seguridad.

                        </li>
                    </ul>
                </Typography>
                <Typography className="terms-subtitle" component="h4">

                    8. Derecho a realizar modificaciones en la APP por parte de la RFEYJDA.
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La RFEJYDA  se reserva el derecho a efectuar, sin previo aviso, las modificaciones que consideren oportunas en la APP, pudiendo cambiar, suprimir o añadir tanto los Contenidos, Materiales y servicios que presta, como la forma en la que estos aparezcan presentados o localizados.
                    En este sentido, el acceso, los Contenidos, Materiales y/o servicios ofrecidos a través de la APP tienen, en principio, una duración indefinida, salvo que se disponga lo contrario en las Condiciones o en la legislación aplicable en cada momento. No obstante, la persona usuaria acepta que la RFEYJDA  pueda dar por terminada, suspender o interrumpir unilateralmente, en cualquier momento sin necesidad de preaviso, la disponibilidad de la APP y/o de cualquiera de los servicios, sin posibilidad de exigir por parte de la persona usuaria indemnización alguna. Tras dicha extinción, la persona usuaria deberá destruir cualquier material que hubiera obtenido respecto de la APP y/o del portal o aplicación en el que se ofrecen los Materiales y/o Contenidos, incluyendo todo el software y documentación correspondiente, así como cualquier copia, impresión e instalación.
                    Si la APP se cancela o termina, la RFEJYDA conservará indefinidamente los datos personales contenidos en la ficha del deportista, con finalidades estadísticas y conservación de los resultados de las distintas competiciones en las que haya participado el o la deportista.
                </Typography>
                <Typography className="terms-subtitle" component="h4">

                    9. Modificaciones de las Condiciones.
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La RFEJYDA  podrá sustituir, en cualquier momento, por motivos técnicos o por cambios en la prestación del servicio o en la normativa, así como modificaciones que pudieran derivarse de códigos tipo aplicables o, en su caso, por decisiones corporativas estratégicas, las Condiciones y la Política de Privacidad que, según los casos, sustituirán, complementaran y/o modificaran las Condiciones y Política de Privacidad de la APP
                    Cuando se sustituya o modifique las Condiciones o la Política de Privacidad, se publicarán en la APP y/o se avisará de ello a través del mismo, y si la persona usuaria continúa utilizando el servicio, se entenderá que han aceptado las modificaciones introducidas salvo que sea necesario solicitar a la persona usuaria su aceptación expresa o su consentimiento conforme a la normativa que sea de aplicación, en cuyo caso se solicitará a la persona usuaria tal aceptación o consentimiento. En el supuesto de que la persona usuaria no estuviera de acuerdo con las modificaciones efectuadas, podrá darse de baja en la APP siguiendo el procedimiento habilitado para ello o comunicándolo al Departamento de Personas de la RFEYDA.
                    Se recomienda a la persona usuaria la consulta periódica de las Condiciones y Política de Privacidad, ya que pueden sufrir modificaciones. Pudiendo consultarse la versión vigente en el apartado “Condiciones de Uso de la APP”. La vigencia temporal de estas Condiciones coincide, por lo tanto, con el tiempo de su exposición, hasta el momento en que sean modificadas total o parcialmente.
                </Typography>

                <Typography className="terms-subtitle" component="h4">
                    10. Ley aplicable y jurisdicción
                </Typography>
                <Typography className="terms-contenido" component="h4">

                    La RFEYDA  y las personas usuarias de la APP se someten a la jurisdicción de los Juzgados y Tribunales de Madrid.
                </Typography>

            </Container>
        </>
    );
}

export default Terms;