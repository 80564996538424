import { API_URL, SERVICE } from "../constants/api";
import http from "../constants/axios";

export const getAllUtils = async () => {
  try {
    const { data } = await http.get(`${API_URL}${SERVICE.All_UTILS}`);

    return data;
  } catch (error) {
    console.log("ERROR =>", error);
    throw new Error("Error en servidor");
  }
};
