import {
  AlternateEmailOutlined,
  AssignmentIndOutlined,
  LocalPhoneOutlined,
  Person2Outlined,
} from "@mui/icons-material";
import {
  Divider,
  Grid,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { Fragment } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { TUser } from "../../../../utils/schemas/user";

type Props = {
  responsibles: TUser["responsibles"];
  isDisabled: boolean;
};

const ResponsibleData = ({ responsibles, isDisabled }: Props) => {
  const { control, register } = useFormContext<TUser>();

  return (
    <>
      {responsibles?.map((_, index) => (
        <Fragment key={index}>
          <Divider sx={{ my: 2, display: { xs: "none", sm: "block" }, width: "100%" }} />
          <Typography variant="h4" component="h4" className="personalData-title">
            Responsable
          </Typography>
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Nombre del responsable*"
                {...register(`responsibles.${index}.name`)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person2Outlined />
                    </InputAdornment>
                  ),
                  readOnly: isDisabled,
                }}
                className="input-text text-field"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Apellidos del responsable*"
                {...register(`responsibles.${index}.surname`)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Person2Outlined />
                    </InputAdornment>
                  ),
                  readOnly: isDisabled,
                }}
                className="input-text text-field"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="DNI del responsable*"
                {...register(`responsibles.${index}.dni`)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AssignmentIndOutlined />
                    </InputAdornment>
                  ),
                  readOnly: isDisabled,
                }}
                className="input-text text-field"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <Controller
                name={`responsibles.${index}.familiarRelationship`}
                control={control}
                defaultValue={""}
                render={({ field }) => (
                  <Select
                    className="input-select form-select-field"
                    {...field}
                    displayEmpty
                    defaultValue={""}
                    readOnly={isDisabled}
                  >
                    <MenuItem value={""}>
                      <em>Representante</em>
                    </MenuItem>
                    <MenuItem value={"madre"}>Madre</MenuItem>
                    <MenuItem value={"padre"}>Padre</MenuItem>
                    <MenuItem value={"tutor legal"}>Tutor legal</MenuItem>
                  </Select>
                )}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Número de teléfono del responsable*"
                {...register(`responsibles.${index}.phone`)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <LocalPhoneOutlined />
                    </InputAdornment>
                  ),
                  readOnly: isDisabled,
                }}
                className="input-text text-field"
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                placeholder="Email del responsable*"
                {...register(`responsibles.${index}.email`)}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <AlternateEmailOutlined />
                    </InputAdornment>
                  ),
                  readOnly: isDisabled,
                }}
                className="input-text text-field"
                variant="outlined"
              />
            </Grid>
          </Grid>
        </Fragment>
      ))}
    </>
  );
};

export default ResponsibleData;
