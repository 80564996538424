import "./register.scss";

import { useFormContext } from "react-hook-form";

import {
  AlternateEmail,
  AssignmentIndOutlined,
  BadgeOutlined,
  Event,
  Person2Outlined,
} from "@mui/icons-material";
import { Container, FormControl, InputAdornment, TextField, Typography } from "@mui/material";

import { MobileDatePicker } from "@mui/x-date-pickers";
import dayjs, { Dayjs } from "dayjs";
import Button from "../../components/button/Button";
import { useAppSelector } from "../../context/hooks/hooks";
import { checkUserCnr, checkUserEmail } from "../../services/users";
import { TUserSchema } from "../../utils/schemas/register";
import { TUtilsSchema } from "../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
  handleBack: () => void;
};

function Register({ handleStep, handleBack, data }: Props) {
  const { register, formState, setValue, watch, setError, trigger } = useFormContext<TUserSchema>();

  const { loading } = useAppSelector((state) => state.auth);

  const handleVerify = async () => {
    let isValid = await trigger(["name", "surname", "email", "identityNumber", "cnr", "bornAt"]);
    if (!isValid) {
      return;
    }
    //Check CNR and email
    try {
      const cnrResponse = await checkUserCnr(watch("cnr"));
      const emailResponse = await checkUserEmail(watch("email"));
      if (!cnrResponse) {
        setError("cnr", { message: "CNR ya registrado" });
        return;
      }
      if (!emailResponse) {
        setError("email", { message: "Email ya registrado" });
        return;
      }
      handleStep();
    } catch (error: any) {
      console.log("❌Error en registro", error);
    }
  };

  //Update date
  const handleSetDate = (value: Date | undefined) => {
    setValue("bornAt", value ? value : new Date());
  };

  const formatBirthDate = watch("bornAt") ? dayjs(watch("bornAt")) : null;

  return (
    <Container className="register-container" maxWidth="xl">
      <Typography className="register-subtitle" component="h4">
        Escribe tu nombre, apellido y CNR para verificarte para el registro.
      </Typography>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="Nombre*"
          {...register("name")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person2Outlined />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.name && (
          <Typography className="register-input-error">
            {formState.errors?.name?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="Apellidos"
          {...register("surname")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Person2Outlined />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.surname && (
          <Typography className="register-input-error">
            {formState.errors?.surname?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <MobileDatePicker
          className="input-text register-text-field"
          format="DD/MM/YYYY"
          maxDate={dayjs()}
          value={formatBirthDate}
          onChange={(value: Dayjs | null) => handleSetDate(value?.toDate())}
          slots={{
            textField: (textFieldProps) => (
              <TextField
                {...textFieldProps}
                InputProps={{
                  startAdornment: <Event className="register-calendar-icon" />,
                  endAdornment: null,
                }}
              />
            ),
          }}
        />
        {formState?.errors?.bornAt && (
          <Typography className="register-input-error">
            {formState.errors?.bornAt?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          autoComplete="email"
          variant="outlined"
          placeholder="Email*"
          {...register("email")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AlternateEmail />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.email && (
          <Typography className="register-input-error">
            {formState.errors?.email?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="DNI*"
          {...register("identityNumber")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <BadgeOutlined />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.identityNumber && (
          <Typography className="register-input-error">
            {formState.errors?.identityNumber?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <TextField
          className="text-field register-text-field"
          variant="outlined"
          placeholder="CNR*"
          {...register("cnr")}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AssignmentIndOutlined />
              </InputAdornment>
            ),
          }}
        />
        {formState?.errors?.cnr && (
          <Typography className="register-input-error">{formState.errors?.cnr?.message}</Typography>
        )}
      </FormControl>

      <Button
        size="large"
        rounded="md"
        width="md"
        className="register-button"
        disabled={loading}
        onClick={handleVerify}
      >
        {loading ? "Cargando" : "Verificar"}
      </Button>
    </Container>
  );
}

export default Register;
