import "./my-docs.scss";

import { Link } from "react-router-dom";

import { ChevronLeft, FileUpload } from "@mui/icons-material";
import { Container, Grid } from "@mui/material";

import Button from "../../../components/button/Button";

export default function MyDocs() {
  return (
    <Container className="documents-container">
      <Link to="/profile" className="documents-back-button">
        <Button className="documents-back-button">
          <ChevronLeft /> Documentación
        </Button>
      </Link>
      <Grid
        className="documents-grid-container"
        container
        columnSpacing={4}
        rowGap={{ xs: 2, md: 4 }}
        sx={{ my: 2 }}
      >
        <Grid item md={7} xs={7}></Grid>
        <Grid item md={5} xs={5}>
          <input
            type="file"
            accept="pdf"
            id="file-input"
            style={{ display: "none" }}
            //   onChange={handleFileInput}
            //   multiple={multi}
          />
          <label htmlFor="file-input">
            <Button
              component="span"
              width="full-width"
              rounded="md"
              className="documents-btn"
            >
              <FileUpload /> Subir
            </Button>
          </label>
        </Grid>
      </Grid>
    </Container>
  );
}
