import "./profile.scss";

import { Link, Navigate, useNavigate } from "react-router-dom";

import AssignmentOutlinedIcon from "@mui/icons-material/AssignmentOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import EventAvailableOutlinedIcon from "@mui/icons-material/EventAvailableOutlined";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import { Avatar } from "@mui/material";

import { BadgeOutlined } from "@mui/icons-material";
import Button from "../../components/common/button/Button";
import { removeUser } from "../../context/auth/authSlice";
import { useAppDispatch, useAppSelector } from "../../context/hooks/hooks";
import useSetPageName from "../../utils/hooks/useSetPageName";

export default function Profile() {
  const { userData } = useAppSelector((state) => state.auth);

  useSetPageName([{ name: "Profile", path: "/profile" }]);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    localStorage.removeItem("remember");
    dispatch(removeUser());
    navigate("/login");
  };

  return (
    <>
      {!userData ? (
        <Navigate to={"/login"} />
      ) : (
        <div className="profileContainer">
          <div className="profileHeader">
            <Avatar
              className="profile-avatar"
              alt="Imagen perfil del usuario"
              src={userData.avatarUrl ? userData.avatarUrl : "images/avatar/1.jpg"}
              sx={{ width: 147, height: 147 }}
            />
            <h2 className="title">{userData.name}</h2>
            <h4 className="subtitle">{userData.email}</h4>
            <p className="subtitle">{userData.cnr}</p>
          </div>
          <div className="profile-link-container">
            <Link to="/profile/personal_data" className="profile-link">
              <PersonOutlineOutlinedIcon className="profile-link-icon" />
              <p>Datos personales</p>
            </Link>
            <Link to="/profile/my_docs" className="profile-link">
              <AssignmentOutlinedIcon className="profile-link-icon" />
              <p>Documentación</p>
            </Link>
            <Link to="/profile/my_inscriptions" className="profile-link">
              <EventAvailableOutlinedIcon className="profile-link-icon" />
              <p>Mis inscripciones</p>
            </Link>
            <Link to="/profile/sport_sheet" className="profile-link">
              <BadgeOutlined className="profile-link-icon" />
              <p>Ficha deportista</p>
            </Link>
            <Link to="/profile/my_results" className="profile-link">
              <EmojiEventsOutlinedIcon className="profile-link-icon" />
              <p>Mis resultados</p>
            </Link>
            <Button
              className="profile-logout-button"
              size="large"
              rounded="md"
              onClick={handleLogout}
            >
              Cerrar sesión
            </Button>
          </div>
        </div>
      )}
    </>
  );
}
