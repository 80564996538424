import "./inscription-card.scss";

import { Box, Grid, Paper, Typography } from "@mui/material";

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import Button from "../../../../components/button/Button";
import { INSCRIPTION_STATUS } from "../../../../constants/constants";
import { TInscriptionsList } from "../../../../utils/schemas/inscription";
import ConsentsDialog from "./dialogCheckboxs/ConsentsDialog";

type InscriptionCardProps = {
  inscription: TInscriptionsList;
  refetchInscriptions: () => void;
};

export default function InscriptionCard({
  inscription,
  refetchInscriptions,
}: InscriptionCardProps) {
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const navigate = useNavigate();

  return (
    <Box className="inscription-card-container" component={Paper}>
      <Grid className="inscription-card-grid-container" container columnSpacing={4}>
        <Grid item md={3} xs={3}>
          <img
            src={inscription.eventPosterUrl}
            alt="inscription-cover"
            className="inscription-card-cover"
          />
        </Grid>
        <Grid item md={9} xs={9}>
          <Box className="inscription-card-details-container">
            <Grid container justifyContent="space-between">
              <Typography className="inscription-card-title">{inscription.eventName}</Typography>
              {inscription.userValidation == null && (
                <Button size="small" rounded="md" onClick={() => setOpenConfirmDialog(true)}>
                  Confirmar asistencia
                </Button>
              )}
            </Grid>
            <Box className="inscription-card-status-container">
              <Typography className="inscription-card-subtitle">Estado</Typography>
              <Button
                size="small"
                rounded="md"
                width="full-width"
                className={`inscription-card-button ${inscription.status}`}
                onClick={() => navigate(`/profile/my_inscriptions/${inscription.id}`)}
              >
                {inscription.status === INSCRIPTION_STATUS.PENDING
                  ? "Pendiente de validar"
                  : inscription.status === INSCRIPTION_STATUS.APPROVED
                  ? "Inscripción completa"
                  : inscription.status === INSCRIPTION_STATUS.REJECTED
                  ? "Inscripción rechazada"
                  : inscription.status === INSCRIPTION_STATUS.RESERVED
                  ? "En reserva"
                  : ""}
              </Button>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <ConsentsDialog
        inscriptionId={inscription.id}
        isOpen={openConfirmDialog}
        onClose={() => setOpenConfirmDialog(false)}
        refetchInscriptions={refetchInscriptions}
      />
    </Box>
  );
}
