import "./register-user.scss";

import { useState } from "react";
import {
  FieldValues,
  FormProvider,
  SubmitErrorHandler,
  SubmitHandler,
  useForm,
} from "react-hook-form";

import { zodResolver } from "@hookform/resolvers/zod";
import { ArrowBack, CircleOutlined, RadioButtonChecked } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  Step,
  StepConnector,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";

import { useNavigate } from "react-router-dom";
import { toastMessageError, toastMessageSuccess } from "../../components/common/toastMessage";
import RegisterLayout from "../../components/registerLayout/RegisterLayout";
import { useAppDispatch } from "../../context/hooks/hooks";
import { sendUserData } from "../../context/register/registerActions";
import useConcentScript from "../../utils/hooks/useConcent";
import useQueryAllUtils from "../../utils/hooks/useQueryUtils";
import { TUserSchema, registerUserSchema } from "../../utils/schemas/register";
import Register from "../register/Register";
import ContactInfo from "./contactInfo/ContactInfo";
import LegalResponsible from "./legalResponsible/LegalResponsible";
import MainData from "./mainData/MainData";
import OptionalInfo from "./optionalInfo/OptionalInfo";
import SetPassword from "./setPassword/SetPassword";
import SportData from "./sportData/SportData";

function RegisterUser() {
  const { data } = useQueryAllUtils();
  const [activeStep, setActiveStep] = useState(0);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { recogerConsentimiento } = useConcentScript();

  const formMethods = useForm<TUserSchema>({
    mode: "all",
    defaultValues: {
      responsibles: [
        {
          dni: "",
          name: "",
          email: "",
          familiarRelationship: "",
          phone: "",
          surname: "",
        },
      ],
    },
    resolver: zodResolver(registerUserSchema),
  });
  const { watch } = formMethods;

  const handleStep = (step?: number) => {
    if (step === 10) {
      formMethods.handleSubmit(onSubmit, onError)();
    } else {
      setActiveStep(step !== undefined ? step : activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      navigate("/login");
    }
    setActiveStep(activeStep - 1);
  };

  const manageConcents = async (payload: any) => {
    const idInput = document.getElementById("id-rfejyda-register") as HTMLInputElement;
    if (idInput) {
      idInput.value = payload.id;
    }
    recogerConsentimiento(
      document.getElementById("rfejyda-register"),
      "rfejyda-register",
      "app.rfejudo.com/rfejyda-register"
    );
  };

  const onSubmit: SubmitHandler<TUserSchema> = async (data) => {
    const createResponse = await dispatch(sendUserData(data));
    if (createResponse.meta.requestStatus === "fulfilled") {
      const { payload } = createResponse;
      await manageConcents(payload);
      toastMessageSuccess("Usuario creado correctamente. Revise la bandeja de entrada de su email");
      setTimeout(() => {
        navigate("/login");
      }, 2000);
    } else if (createResponse.meta.requestStatus === "rejected") {
      toastMessageError(createResponse.payload ?? "Error al crear el usuario");
    }
  };
  const onError: SubmitErrorHandler<FieldValues> = (error) => {
    console.log("Errores del form", error);
  };
  const isAdult = false;
  const steps = [
    {
      label: "Información deportista",
      isAdult: true,
      Component: Register,
    },
    {
      label: "Datos principales",
      isAdult: true,
      Component: MainData,
    },
    {
      label: "Contacto deportista",
      isAdult: true,
      Component: ContactInfo,
    },
    {
      label: "Datos representante legal",
      isAdult: false,
      Component: LegalResponsible,
    },
    {
      label: "Datos deportivos",
      isAdult: true,
      Component: SportData,
    },
    {
      label: "Mas información optional",
      isAdult: true,
      Component: OptionalInfo,
    },
    {
      label: "Establecer contraseña",
      isAdult: true,
      Component: SetPassword,
    },
  ];

  const stepCount = isAdult ? steps.filter((step) => step.isAdult) : steps;

  return (
    <RegisterLayout>
      <Container className="register-user-container">
        <Stepper
          nonLinear
          activeStep={activeStep}
          connector={<StepConnector className="register-user-stepper-connector" />}
          className="register-user-stepper-container"
        >
          {stepCount.map((_, index) => (
            <Step key={`stepper-${index}`}>
              <StepLabel
                className={`register-user-step-label`}
                onClick={() => handleStep(index)}
                StepIconComponent={activeStep === index ? RadioButtonChecked : CircleOutlined}
              ></StepLabel>
            </Step>
          ))}
        </Stepper>
        <Grid container justifyContent="flex-start">
          <IconButton onClick={handleBack}>
            <ArrowBack className="icon-color-yellow" />
          </IconButton>
        </Grid>
        <FormProvider {...formMethods}>
          <form
            onSubmit={formMethods.handleSubmit(onSubmit, onError)}
            className="form-container"
            id="register-form"
          >
            <Typography className="register-form-title" component="h4">
              {`${activeStep + 1}/${stepCount.length} ${stepCount[activeStep].label}`}
            </Typography>
            {data ? (
              stepCount.map((step, index) => (
                <Box
                  key={step.label}
                  sx={{
                    display: activeStep === index ? "block" : "none",
                    width: "100%",
                  }}
                >
                  <step.Component data={data} handleStep={handleStep} handleBack={handleBack} />
                </Box>
              ))
            ) : (
              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                }}
              ></Box>
            )}
          </form>
        </FormProvider>
      </Container>
      {/* Datos para enviar a consentimientos */}
      <form id="rfejyda-register" className="rfejyda-register-hidden">
        <label htmlFor="id">Id</label>
        <input name="id" id="id-rfejyda-register" />
        <label htmlFor="nombre">Nombre</label>
        <input name="nombre" id="nombre" value={`${watch("name")}, ${watch("surname")} `}></input>
        <label htmlFor="email">Email</label>
        <input name="email" id="email" value={watch("email")}></input>
        <label htmlFor="termsAndConditionsConsent">Términos y Condiciones</label>
        <input
          name="termsAndConditionsConsent"
          id="termsAndConditionsConsent"
          value={watch("termsAndConditionsConsent")}
        ></input>
        <label htmlFor="dataTreatmentConsent">Tratamiento de datos</label>
        <input
          name="dataTreatmentConsent"
          id="dataTreatmentConsent"
          value={watch("dataTreatmentConsent")}
        ></input>
      </form>
    </RegisterLayout>
  );
}

export default RegisterUser;
