export const CATEGORY_NAMES = [
  "absoluto",
  "alevin",
  "benjamin",
  "cadete",
  "chupetin",
  "infantil",
  "junior",
  "master",
  "mini-benjamin",
  "veteranos",
];

export const GENRES = {
  MEN: "m",
  MIXTO: "mixto",
  WOMEN: "w",
};

export const INSCRIPTION_STATUS = {
  APPROVED: "approved",
  PENDING: "pending",
  REJECTED: "rejected",
  RESERVED: "reserved",
};

export const INSCRIPTIONS_STATUSES = [
  { value: "", label: "Estado: Todos" },
  { value: INSCRIPTION_STATUS.APPROVED, label: "Inscripción confirmada" },
  { value: INSCRIPTION_STATUS.PENDING, label: "Pendiente de validar" },
  { value: INSCRIPTION_STATUS.REJECTED, label: "Denegada" },
  { value: INSCRIPTION_STATUS.RESERVED, label: "En reserva" },
];
