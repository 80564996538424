import "./event-card.scss";

import { Link } from "react-router-dom";

import { Box, Grid, Paper, Typography } from "@mui/material";

import { formatShortDate } from "../../utils/date";
import { TEventSimple } from "../../utils/schemas/event";

type Props = {
  event: TEventSimple;
};

const EventCard = ({ event }: Props) => {
  return (
    <Link to={`/events/${event.id}`}>
      <Paper className="event-card-container" elevation={4}>
        <Grid container sx={{ height: "100%" }}>
          <Grid item xs={4} md={1.5}>
            <img className="event-card-image" src={event.posterUrl} alt="event.posterUrl" />
          </Grid>
          <Grid item sx={{ height: "100%" }} xs={8} md={10.5}>
            <Grid container sx={{ height: "100%" }}>
              <Box className="event-card-inner-container">
                <Grid item sx={{ height: "100%" }} xs={12} md={4}>
                  <Box className="event-card-detail-container">
                    <Typography className="event-card-name">{event.name}</Typography>
                    <Typography className="event-card-data">{event.organizatorName}</Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ height: "100%" }} xs={12} md={1}>
                  <Box className="event-card-detail-container">
                    <Typography className="event-card-title">Categoría:</Typography>
                    <Typography className="event-card-data">{event.categoryCode}</Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ height: "100%" }} xs={12} md={3}>
                  <Box className="event-card-detail-container">
                    <Typography className="event-card-title">Fecha: </Typography>
                    <Typography className="event-card-data">
                      {formatShortDate(event.startDate)}
                    </Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ height: "100%" }} xs={12} md={2}>
                  <Box className="event-card-detail-container">
                    <Typography className="event-card-title">Tipo de Evento:</Typography>
                    <Typography className="event-card-data">{event.eventType}</Typography>
                  </Box>
                </Grid>
                <Grid item sx={{ height: "100%" }} xs={12} md={1}>
                  <Box className="event-card-detail-container">
                    <Typography className="event-card-title">Deporte:</Typography>
                    <Typography className="event-card-data">{event.sportCode}</Typography>
                  </Box>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Link>
  );
};

export default EventCard;
