import "./home-event-card.scss";

import { Link } from "react-router-dom";

import { Box, Card, Grid, Typography } from "@mui/material";

import { formatShortDate } from "../../../utils/date";

type HomeEventCardProps = {
  event: {
    id: string;
    name: string;
    organizatorName: string;
    startDate: string;
    status: string;
    posterUrl: string;
  };
};

function HomeEventCard({
  event: { posterUrl, name, organizatorName, startDate, status, id },
}: HomeEventCardProps) {
  return (
    <Card component={Link} to={`/events/${id}`}>
      <Box className="home-event-card-container">
        <Grid
          container
          className="home-event-card-inner-container"
          columnSpacing={4}
        >
          <Grid item md={6} xs={12}>
            <img
              src={posterUrl}
              alt={`${name} - poster`}
              className="home-event-card-image"
            />
          </Grid>
          <Grid item md={6} xs={12}>
            <Box className="home-event-card-details">
              <Typography component="h4" className="home-event-card-title">
                {name}
              </Typography>
              <Typography component="h4" className="home-event-card-subtitle">
                {formatShortDate(startDate)}
              </Typography>
              <Typography component="h4" className="home-event-card-subtitle">
                {organizatorName}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default HomeEventCard;
