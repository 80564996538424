import { Box, FormControl, MenuItem, Select, Typography } from "@mui/material";
import { useState } from "react";
import { useFormContext } from "react-hook-form";
import Button from "../../../components/button/Button";
import { CATEGORY_NAMES } from "../../../constants/constants";
import { TUserSchema } from "../../../utils/schemas/register";
import { TUtilsSchema } from "../../../utils/schemas/utils";

type Props = {
  data: TUtilsSchema;
  handleStep: () => void;
  handleBack: () => void;
};

function MainData({ data, handleStep }: Props) {
  const { categories, sports, countries } = data;
  const { register, formState, trigger, setValue } = useFormContext<TUserSchema>();

  const [selectedSport, setSelectedSport] = useState<{ category: string; sport: string }>({
    category: "",
    sport: "",
  });

  const handleButtonClick = async () => {
    let isValid = await trigger(["currentCategoryId", "gender", "nationality", "sport"]);

    if (isValid) {
      handleStep();
    }
  };

  return (
    <Box className="register-form-data-container">
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue=""
          value={selectedSport.sport}
          onChange={(e) => {
            setSelectedSport({ ...selectedSport, sport: e.target.value as string });
            setValue("sport", e.target.value as string);
          }}
          required
        >
          <MenuItem value="">
            <em>Deporte*</em>
          </MenuItem>
          {sports?.map((sport) => (
            <MenuItem key={sport?.id} value={sport.code}>
              {sport.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.sport && (
          <Typography className="register-input-error">
            {formState.errors?.sport?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("gender")}
        >
          <MenuItem value="">
            <em>Genero*</em>
          </MenuItem>
          <MenuItem key={`gender-m`} value={"m"}>
            Hombre
          </MenuItem>
          <MenuItem key={`gender-w`} value={"w"}>
            Mujer
          </MenuItem>
        </Select>
        {formState?.errors?.gender && (
          <Typography className="register-input-error">
            {formState.errors?.gender?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          value={selectedSport.category}
          onChange={(e) =>
            setSelectedSport({ ...selectedSport, category: e.target.value as string })
          }
        >
          <MenuItem value="">
            <em>Categoría*</em>
          </MenuItem>
          {CATEGORY_NAMES.map((category) => (
            <MenuItem key={category} value={category}>
              {category}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.currentCategoryId && (
          <Typography className="register-input-error">
            {formState.errors?.currentCategoryId?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("currentCategoryId")}
        >
          <MenuItem value="">
            <em>Peso*</em>
          </MenuItem>
          {categories
            ?.filter(
              (category) =>
                category.sportCode === selectedSport.sport &&
                category.categoryName === selectedSport.category
            )
            .sort()
            .map((category) => (
              <MenuItem key={category.id} value={category.id}>
                {category.weight}
              </MenuItem>
            ))}
        </Select>
        {formState?.errors?.currentCategoryId && (
          <Typography className="register-input-error">
            {formState.errors?.currentCategoryId?.message}
          </Typography>
        )}
      </FormControl>
      <FormControl fullWidth>
        <Select
          fullWidth
          displayEmpty
          className="input-select register-select-field"
          defaultValue={""}
          {...register("nationality")}
          //   onChange={(value) =>
          //     setValue("nationality", value.target.value as string)
          //   }
        >
          <MenuItem value="">
            <em>Nacionalidad*</em>
          </MenuItem>
          {countries?.map((country) => (
            <MenuItem key={`country-${country.code}`} value={country.code}>
              {country.name}
            </MenuItem>
          ))}
        </Select>
        {formState?.errors?.nationality && (
          <Typography className="register-input-error">
            {formState.errors?.nationality?.message}
          </Typography>
        )}
      </FormControl>
      <Button className="register-button" onClick={handleButtonClick} rounded="md">
        Siguiente
      </Button>
    </Box>
  );
}

export default MainData;
