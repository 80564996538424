import { useEffect, useRef } from 'react';

export function useDebounceFunction<T>(callback: (x: T) => any, delay: number) {
	const timerRef = useRef<NodeJS.Timeout | null>(null);

	useEffect(() => {
		return () => {
			if (timerRef.current !== null) clearTimeout(timerRef.current);
		};
	}, []);

	return (params: T) => {
		if (timerRef.current !== null) clearTimeout(timerRef.current);

		timerRef.current = setTimeout(() => {
			callback(params);
		}, delay);
	};
}
