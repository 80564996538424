import { MIN_WIDTH_VISIBLE } from "../constants/layout";

export function showSectionBarBottom(width: number | undefined) {
	return width !== undefined && width < MIN_WIDTH_VISIBLE;
}

export function downloadPdfFromBase64(base64: string, fileName: string = 'document.pdf') {
	// Convertir base64 a un array buffer para poder crear el Blob
	const pdfBlob = base64ToBlob(base64, 'application/pdf');
  
	// Crear un URL para el Blob
	const url = URL.createObjectURL(pdfBlob);
  
	// Crear un elemento <a> para iniciar la descarga
	const a = document.createElement('a');
	a.href = url;
	a.download = fileName; // Nombre del archivo para la descarga
	document.body.appendChild(a); // Se agrega el elemento al DOM para asegurar su funcionalidad en ciertos navegadores
	a.click(); // Iniciar la descarga
  
	// Limpiar: eliminar el elemento <a> y liberar el objeto URL
	document.body.removeChild(a);
	URL.revokeObjectURL(url);
  };
  
  // Función auxiliar para convertir base64 a Blob
export function base64ToBlob(base64: string, contentType: string = '', sliceSize: number = 512) {
	const byteCharacters = atob(base64); // Decodificar base64
	const byteArrays = [];
  
	for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
	  const slice = byteCharacters.slice(offset, offset + sliceSize);
  
	  const byteNumbers = new Array(slice.length);
	  for (let i = 0; i < slice.length; i++) {
		byteNumbers[i] = slice.charCodeAt(i);
	  }
  
	  const byteArray = new Uint8Array(byteNumbers);
	  byteArrays.push(byteArray);
	}
  
	const blob = new Blob(byteArrays, { type: contentType });
	return blob;
  };
  