import { createAsyncThunk } from "@reduxjs/toolkit";

import bcrypt from "bcryptjs";
import { API_URL, SALT, SERVICE } from "../../constants/api";
import http from "../../constants/axios";
import { TUserSchema } from "../../utils/schemas/register";

export const sendUserData = createAsyncThunk(
  "forms/sendUserData",
  async (data: TUserSchema, { rejectWithValue }) => {
    try {
      if (data.responsibles && data.responsibles[0].name === "") {
        delete data.responsibles;
      }
      //encode con el salt
      data.password = bcrypt.hashSync(data.password, SALT);

      const request = await http.post(`${API_URL}${SERVICE.USERS}`, data);
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const setUserPassword = createAsyncThunk(
  "auth/setUserPassword",
  async (data: { body: { password: string }; token: string }, { rejectWithValue }) => {
    const bodyToSend = {
      password: bcrypt.hashSync(data?.body?.password, SALT),
    };
    try {
      const request = await http.put(`${API_URL}${SERVICE.USERS}/verify/${data.token}`, bodyToSend);
      return request.data;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue("Error al cambiar la contraseña");
    }
  }
);

export const verifyUser = createAsyncThunk(
  "auth/verifyUser",
  async (token: string, { rejectWithValue }) => {
    try {
      const request = await http.put(`${API_URL}${SERVICE.USERS}/verify/${token}`);
      return request.data;
    } catch (error) {
      return rejectWithValue("Error al verificar usuario");
    }
  }
);
