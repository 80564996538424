import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getAllUtils } from "../../services/utils";
import { TUtilsSchema } from "../schemas/utils";

export default function useQueryAllUtils() {
  // return data
  return useQuery<TUtilsSchema, Error>([RQ_KEY.UTILS], () => getAllUtils(), {
    refetchOnWindowFocus: false,
  });
}
