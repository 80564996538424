import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";

import Button from "../../../../../components/common/button/Button";
import { useAppDispatch } from "../../../../../context/hooks/hooks";
import { confirmInscription } from "../../../../../context/forms/formsActions";
import {
  toastMessageError,
  toastMessageSuccess,
} from "../../../../../components/common/toastMessage";

import "./consents-dialog.scss";
import InscriptionConsents from "../../../../../components/formComponents/inscriptionConsents/InscriptionConsents";
import { useState } from "react";
import { useForm, useFormContext } from "react-hook-form";
import { TInscriptionData } from "../../../../../utils/schemas/inscription";

type Props = {
  isOpen: boolean;
  onClose: () => void;
  inscriptionId: string;
  refetchInscriptions: () => void;
};

function ConsentsDialog({ isOpen, onClose, inscriptionId, refetchInscriptions }: Props) {
  const dispatch = useAppDispatch();
  const { watch, setValue } = useForm<TInscriptionData>();

  const handleConfirmInscription = async () => {
    const body = {
      privacyPolicyConsent: watch("privacyPolicyConsent"),
      obligationsAndConditionsConsent: watch("obligationsAndConditionsConsent"),
      thirdPartiesCompulsoryConsent: watch("thirdPartiesCompulsoryConsent"),
      minorDataTreatmentConsent: watch("minorDataTreatmentConsent"),
      imageConsent: watch("imageConsent"),
      communicationsConsent: watch("communicationsConsent"),
      thirdPartiesNonCompulsoryConsent: watch("thirdPartiesNonCompulsoryConsent"),
    };
    const confirmResponse = await dispatch(
      confirmInscription({ body: body, inscriptionId: inscriptionId })
    );
    if (confirmResponse.meta.requestStatus.includes("fullfilled")) {
      toastMessageSuccess("Inscripción confirmada");
    } else if (confirmResponse.meta.requestStatus.includes("rejected")) {
      toastMessageError("Error al confirmar inscripción");
    }
    refetchInscriptions();
    onClose();
  };

  const isRequiredChecked =
    watch("privacyPolicyConsent") != "" &&
    !!watch("privacyPolicyConsent") &&
    watch("obligationsAndConditionsConsent") != "" &&
    !!watch("obligationsAndConditionsConsent") &&
    watch("communicationsConsent") != "" &&
    !!watch("communicationsConsent");

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle className="dialog-title">Confirmación de la inscripción</DialogTitle>
      <DialogContent>
        <DialogContentText className="dialog-text">
          ¿Estas seguro de que quieres confirmar la inscripción al evento?
        </DialogContentText>
        <InscriptionConsents watch={watch} setValue={setValue} />
      </DialogContent>
      <DialogActions className="dialog-actions">
        <Button type="submit" className="button-cancel" onClick={onClose}>
          CANCELAR
        </Button>
        <Button
          type="submit"
          className="button-accept"
          onClick={handleConfirmInscription}
          disabled={!isRequiredChecked}
        >
          ACEPTAR
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default ConsentsDialog;
