import "./my-inscriptions.scss";

import { useState } from "react";
import { Link } from "react-router-dom";

import { ChevronLeft, TuneOutlined } from "@mui/icons-material";
import {
  Box,
  Container,
  Grid,
  IconButton,
  MenuItem,
  Popover,
  Select,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import Loading from "../../../components/loader/Loading";
import MenuPopover from "../../../components/menuPopover/MenuPopover";
import MenuPopoverItem from "../../../components/menuPopover/menuPopoverItem/MenuPopoverItem";
import SearchBar from "../../../components/search-bar/search-bar";
import { INSCRIPTIONS_STATUSES } from "../../../constants/constants";
import useQueryInscriptions from "../../../utils/hooks/useQueryInscriprions";
import InscriptionCard from "./inscriptionCard/InscriptionCard";

export default function MyInscriptions() {
  const [filtersSelected, setFiltersSelected] = useState({
    status: "",
    types: "",
    search: "",
  });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleFilterClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelectionChange = (
    position: "types" | "status" | "search",
    value: string | undefined
  ) => {
    const prevState = { ...filtersSelected };
    if (prevState[position] === value || !value) {
      prevState[position] = "";
    } else {
      prevState[position] = value;
    }
    setFiltersSelected(prevState);
  };
  const types = [
    { value: "", label: "Tipo: Todos" },
    { value: "next", label: "Próximos" },
    { value: "previous", label: "Ya realizados" },
  ];
  const {
    data: inscriptions,
    isLoading,
    isRefetching,
    refetch: refetchInscriptions,
  } = useQueryInscriptions(filtersSelected);

  return (
    <Container className="inscriptions-container">
      <Box className="inscriptions-top">
        <Link to="/profile" className="inscriptions-back-button">
          <Button className="inscriptions-back-button">
            <ChevronLeft /> Mis inscripciones
          </Button>
        </Link>
        <IconButton onClick={handleFilterClick}>
          <TuneOutlined className="inscriptions-filter-icon" />
        </IconButton>
        <Popover
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "left",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={!!anchorEl}
          onClose={handleClose}
        >
          <MenuPopover title=" FILTRAR POR:">
            <MenuPopoverItem
              title="tipo"
              options={types}
              selectedValue={filtersSelected["types"]}
              onSelectionChange={(value) => handleSelectionChange("types", value)}
            />
            <MenuPopoverItem
              title="estado"
              options={INSCRIPTIONS_STATUSES}
              selectedValue={filtersSelected["status"]}
              onSelectionChange={(value) => handleSelectionChange("status", value)}
            />
          </MenuPopover>
        </Popover>
      </Box>
      <Box className="inscriptions-search-container">
        <Grid container columnSpacing={2} rowSpacing={2}>
          <Grid item md={3} xs={0}>
            <Select
              fullWidth
              displayEmpty
              className="input-select inscriptions-select-field"
              value={filtersSelected["types"]}
              renderValue={(selected) => {
                return (
                  <>
                    {
                      Object.values(types).filter((type) => type.value === selected)[0]
                        .label as string
                    }
                  </>
                );
              }}
              onChange={(value) => handleSelectionChange("types", value.target.value as string)}
            >
              {types.map((type) => (
                <MenuItem key={type.value} value={type.value}>
                  {type.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item md={3} xs={0}>
            <Select
              fullWidth
              displayEmpty
              className="input-select inscriptions-select-field"
              value={filtersSelected["status"]}
              renderValue={(selected) => {
                return (
                  <>
                    {
                      Object.values(INSCRIPTIONS_STATUSES).filter(
                        (status) => status.value === selected
                      )[0].label as string
                    }
                  </>
                );
              }}
              onChange={(value) => handleSelectionChange("status", value.target.value as string)}
            >
              {INSCRIPTIONS_STATUSES.map((status) => (
                <MenuItem key={status.value} value={status.value}>
                  {status.label}
                </MenuItem>
              ))}
            </Select>
          </Grid>
          <Grid item md={6} xs={12}>
            <SearchBar
              className="inscriptions-search-bar"
              onSearch={(value) => handleSelectionChange("search", value)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box className="inscriptions-inner-container">
        {isLoading || isRefetching ? (
          <Loading />
        ) : inscriptions && inscriptions.data.length > 0 ? (
          inscriptions.data.map((inscription) => (
            <InscriptionCard
              key={inscription.id}
              inscription={inscription}
              refetchInscriptions={refetchInscriptions}
            />
          ))
        ) : (
          <Typography variant="h6">No hay inscripciones</Typography>
        )}
      </Box>
    </Container>
  );
}
