import {
  ArrowBackIosNew,
  FacebookOutlined,
  HomeOutlined,
  Instagram,
  LanguageOutlined,
  PhoneOutlined,
  Twitter,
  WatchLaterOutlined,
  WhatsApp,
  YouTube,
} from "@mui/icons-material";
import {
  Avatar,
  Divider,
  Grid,
  IconButton,
  Link,
  Paper,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import Loading from "../../components/loader/Loading";
import { useAppSelector } from "../../context/hooks/hooks";
import useQueryFederationId from "../../utils/hooks/useQueryFederationId";

import TikTokIcon from "../../components/icons/TikTokIcon";
import { formatHours } from "../../utils/date";
import "./federation.scss";
import Map from "../../components/map/Map";

type WeekScheduleItem = {
  start: string;
  end: string;
};

type WeekSchedule = {
  [day: string]: WeekScheduleItem[];
};

const FederationInfo = () => {
  const { userInfo } = useAppSelector((state) => state.auth);
  const navigate = useNavigate();
  const matches = useMediaQuery("(width > 769px)");
  const params = useParams();
  const { id } = params as { id: string };

  const {
    data: federationData,
    isLoading,
    isRefetching,
  } = useQueryFederationId(id ? id : userInfo?.federationId || "");

  const weekSchedule =
    federationData?.timetable && (JSON.parse(federationData?.timetable) as WeekSchedule);

  return isRefetching || isLoading ? (
    <Loading />
  ) : (
    <Grid container className="federation" component={matches ? Paper : Grid}>
      <Grid item xs={12} className="federation__first-container" container>
        <IconButton
          onClick={() => {
            navigate("/home");
          }}
          className="federation__first-container__button"
        >
          <ArrowBackIosNew /> Ficha {federationData?.shortCode}
        </IconButton>
        <Grid className="federation__first-container__image-container">
          <Avatar
            className="federation__first-container__image-container__avatar"
            src={federationData?.logoUrl}
          />
        </Grid>
        <Grid item className="federation__first-container__text-container">
          <Typography className="federation__first-container__text-container__name">
            {federationData?.name}
          </Typography>
          <Typography className="federation__first-container__text-container__name">
            {matches && "CIF: "}
            {federationData?.cif}
          </Typography>
        </Grid>
      </Grid>
      <Grid item className="federation__second-container" xs={12} sm={8}>
        <Grid className="federation__second-container__text">
          {matches ? (
            <Typography className="federation__second-container__text__bold">Dirección:</Typography>
          ) : (
            <HomeOutlined />
          )}{" "}
          <Typography>{federationData?.address?.address}</Typography>
        </Grid>
        {matches && <Divider />}
        <Grid className="federation__second-container__text">
          {matches ? (
            <Typography className="federation__second-container__text__bold">Teléfono:</Typography>
          ) : (
            <PhoneOutlined />
          )}
          <Typography>{federationData?.phone}</Typography>
        </Grid>
        {federationData?.website && (
          <>
            {matches && <Divider />}
            <Grid className="federation__second-container__text">
              {matches ? (
                <Typography className="federation__second-container__text__bold">Web:</Typography>
              ) : (
                <LanguageOutlined />
              )}{" "}
              <Link
                underline="hover"
                href={federationData?.website}
                target="_blank"
                rel="noreferrer noopener"
                className="federation__second-container__text__link"
              >
                {federationData?.website}
              </Link>
            </Grid>
          </>
        )}
        {federationData?.socialMedia && (
          <>
            {matches && <Divider />}
            {matches && (
              <Typography className="federation__second-container__text__bold">
                Redes Sociales:
              </Typography>
            )}
            {federationData?.socialMedia?.facebookUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.facebookUrl}
                target="_blank"
              >
                <FacebookOutlined /> {federationData?.socialMedia?.facebookUrl}
              </Link>
            )}
            {federationData?.socialMedia?.instagramUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.instagramUrl}
                target="_blank"
              >
                <Instagram /> {federationData?.socialMedia?.instagramUrl}
              </Link>
            )}
            {federationData?.socialMedia?.youtubeUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.youtubeUrl}
                target="_blank"
              >
                <YouTube /> {federationData?.socialMedia?.youtubeUrl}
              </Link>
            )}
            {federationData?.socialMedia?.tiktokUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.tiktokUrl}
                target="_blank"
              >
                <TikTokIcon /> {federationData?.socialMedia?.tiktokUrl}
              </Link>
            )}
            {federationData?.socialMedia?.twitterUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.twitterUrl}
                target="_blank"
              >
                <Twitter /> {federationData?.socialMedia?.twitterUrl}
              </Link>
            )}
            {federationData?.socialMedia?.whatsappUrl && (
              <Link
                className="federation__second-container__text"
                underline="hover"
                rel="noopener noreferrer"
                href={federationData?.socialMedia?.whatsappUrl}
                target="_blank"
              >
                <WhatsApp /> {federationData?.socialMedia?.whatsappUrl}
              </Link>
            )}
          </>
        )}
        {matches && <Divider />}
        {weekSchedule && (
          <Grid className="federation__second-container__text">
            {matches ? (
              <Typography className="federation__second-container__text__bold">Horario:</Typography>
            ) : (
              <>
                <WatchLaterOutlined />
                <Typography className="federation__second-container__text__bold">
                  Horario:
                </Typography>
              </>
            )}{" "}
          </Grid>
        )}
        <Grid container className="federation__second-container__weekSchedule">
          {weekSchedule &&
            Object.keys(weekSchedule).map((day: string) => (
              <Grid item key={day}>
                {day}:
                {weekSchedule[day] &&
                  weekSchedule[day].length > 0 &&
                  weekSchedule[day].map((schedule: any, index: number) => (
                    <span key={index}>
                      {" "}
                      {`${formatHours(schedule.start)} - ${formatHours(schedule.end)}`}
                      {index !== weekSchedule[day].length - 1 && ", "}
                    </span>
                  ))}
              </Grid>
            ))}
        </Grid>
      </Grid>
      {federationData?.mapsUrl && (
        <Grid className="federation__container-map" item xs={12}>
          <Typography className="federation__second-container__text">Mapa:</Typography>
          {/* <Grid className="federation__container-map__map">
            <iframe
              src={federationData?.mapsUrl}
              width="80%"
              height="100%"
              aria-hidden="false"
              title="google maps iframe"
            />
          </Grid> */}
          <Grid className="map_container">
            <Map lat={federationData.latitude} lng={federationData.longitude} />
          </Grid>
        </Grid>
      )}
    </Grid>
  );
};

export default FederationInfo;
