import { Checkbox, FormControlLabel, FormGroup } from "@mui/material";
import dayjs from "dayjs";
import { UseFormSetValue, UseFormWatch } from "react-hook-form";
import { TInscriptionData } from "../../../utils/schemas/inscription";
import "./inscription-consents.scss";

type Props = {
  watch: UseFormWatch<TInscriptionData>;
  setValue: UseFormSetValue<TInscriptionData>;
  isRealized?: boolean;
};

const InscriptionConsents = ({ watch, setValue, isRealized }: Props) => {
  const today = dayjs().format("YYYY-MM-DD");

  return (
    <FormGroup className="inscription-checkbox-container">
      <FormControlLabel
        required
        control={
          <Checkbox
            className="check-radio-orange"
            checked={watch("privacyPolicyConsent") !== "" && !!watch("privacyPolicyConsent")}
            onChange={(e) => setValue("privacyPolicyConsent", e.target.checked ? today : "")}
            disabled={isRealized}
          />
        }
        label="He leído la política de privacidad."
      />
      <FormControlLabel
        required
        control={
          <Checkbox
            className="check-radio-orange"
            checked={
              watch("obligationsAndConditionsConsent") !== "" &&
              !!watch("obligationsAndConditionsConsent")
            }
            onChange={(e) =>
              setValue("obligationsAndConditionsConsent", e.target.checked ? today : "")
            }
            disabled={isRealized}
          />
        }
        label="Declaro que conozco la obligaciones y condiciones de participación en esta actividad."
      />
      {watch("bornAt") && dayjs().diff(watch("bornAt"), "year") < 18 && (
        <FormControlLabel
          control={
            <Checkbox
              className="check-radio-orange"
              checked={
                watch("minorDataTreatmentConsent") !== "" && !!watch("minorDataTreatmentConsent")
              }
              onChange={(e) => setValue("minorDataTreatmentConsent", e.target.checked ? today : "")}
              disabled={isRealized}
              required={watch("bornAt") && dayjs().diff(watch("bornAt"), "year") < 18}
            />
          }
          label="Autorizo el tratamiento de los datos del menor de edad para la participación en esta actividad."
        />
      )}
      <FormControlLabel
        control={
          <Checkbox
            className="check-radio-orange"
            checked={
              watch("thirdPartiesCompulsoryConsent") !== "" &&
              !!watch("thirdPartiesCompulsoryConsent")
            }
            onChange={(e) =>
              setValue("thirdPartiesCompulsoryConsent", e.target.checked ? today : "")
            }
            disabled={isRealized}
          />
        }
        label="Autorizo la publicación de mi imagen y/o vídeos en medios de comunicación de la RFEJyDA, así como en medios externos (periódicos, revistas, redes sociales, televisión, entre otros)"
      />
      <FormControlLabel
        required
        control={
          <Checkbox
            className="check-radio-orange"
            checked={watch("communicationsConsent") !== "" && !!watch("communicationsConsent")}
            onChange={(e) => setValue("communicationsConsent", e.target.checked ? today : "")}
            disabled={isRealized}
          />
        }
        label="Autorizo la cesión de mis datos a terceras entidades, directamente relacionadas con la RFEJyDA, con finalidades necesarias para el desarrollo de actividades y funciones de la Federación"
      />
      <FormControlLabel
        control={
          <Checkbox
            className="check-radio-orange"
            checked={
              watch("thirdPartiesNonCompulsoryConsent") !== "" &&
              !!watch("thirdPartiesNonCompulsoryConsent")
            }
            onChange={(e) =>
              setValue("thirdPartiesNonCompulsoryConsent", e.target.checked ? today : "")
            }
            disabled={isRealized}
          />
        }
        label="Autorizo la cesión de mis datos a terceras entidades no necesarias para el desarrollo de las actividades y funciones de la Federación."
      />
      <FormControlLabel
        control={
          <Checkbox
            className="check-radio-orange"
            checked={watch("imageConsent") !== "" && !!watch("imageConsent")}
            onChange={(e) => setValue("imageConsent", e.target.checked ? today : "")}
            disabled={isRealized}
          />
        }
        label="Autorizo el envío de comunicaciones por vías electrónicas, tales como whatsapp, SMS, correo electrónico o similares."
      />
    </FormGroup>
  );
};

export default InscriptionConsents;
