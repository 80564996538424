import "./footer-mobile-bar.scss";

import { NavLink } from "react-router-dom";

import { footerSections } from "../../constants/sections";
import { useWindowSize } from "../../utils/hooks/useWindowSize";
import { showSectionBarBottom } from "../../utils/utils";

type Props = {
  userInfo: { claims: []; id: string; roles: string[]; email: string } | null;
};

export default function FooterMobileBar({ userInfo }: Props) {
  const { width } = useWindowSize();

  if (!showSectionBarBottom(width)) {
    return null;
  }

  return (
    <div className="footer-mobile-container">
      {footerSections.map((section) => {
        return (
          <NavLink
            key={section.label}
            to={section.route}
            className={`footer-mobile-link userInfo ${
              userInfo && section.label == "Perfil" ? "login-ball" : ""
            }`}
          >
            {section.icon}
            {section.label}
          </NavLink>
        );
      })}
    </div>
  );
}
