import { useEffect, useState } from 'react';

type WindowSize = {
	width: number | undefined;
	height: number | undefined;
};

export function useWindowSize() {
	const [windowSize, setWindowSize] = useState<WindowSize>({
		width: 0,
		height: 0,
	});

	useEffect(() => {
		// Handler to update window size when the browser window is resized
		const handleResize = () => {
			setWindowSize({
				width: window.innerWidth,
				height: window.innerHeight,
			});
		};

		// Add event listener for window resize
		window.addEventListener('resize', handleResize);

		// Call the handler initially to set the initial window size
		handleResize();

		// Clean up the event listener on unmount
		return () => window.removeEventListener('resize', handleResize);
	}, []);

	return windowSize;
}
