import {
  AccountCircleOutlined,
  BarChart,
  SportsKabaddi,
  SportsMartialArts,
  ViewListOutlined,
} from "@mui/icons-material";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";

import { ROUTE_LINKS } from "../routes/route_links";

export const footerSections = [
  {
    icon: <HomeOutlinedIcon className="footer-mobile-icon" />,
    label: "Home",
    route: ROUTE_LINKS.HOME,
  },
  {
    icon: <SportsKabaddi className="footer-mobile-icon" />,
    label: "Federación",
    route: ROUTE_LINKS.FEDERATION,
  },
  {
    icon: <SportsMartialArts className="footer-mobile-icon" />,
    label: "Club",
    route: ROUTE_LINKS.CLUBS,
  },
  {
    icon: <BarChart className="footer-mobile-icon" />,
    label: "Ranking",
    route: ROUTE_LINKS.RANKING,
  },
  {
    icon: <ViewListOutlined className="footer-mobile-icon" />,
    label: "Resultados",
    route: ROUTE_LINKS.RESULTS,
  },
  {
    icon: <AccountCircleOutlined className="footer-mobile-icon" />,
    label: "Perfil",
    route: ROUTE_LINKS.PROFILE,
  },
];
