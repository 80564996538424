import { createSlice } from "@reduxjs/toolkit";

import { TUserSchema } from "../../utils/schemas/register";
import { sendUserData } from "./registerActions";

type InitialStateProps = {
  loading?: boolean;
  error?: string;
};

const initialState: Partial<TUserSchema> & InitialStateProps = {
  loading: false,
};

const registerOptions = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setOptions: (state, action) => ({ ...state, ...action.payload }),
  },
  extraReducers(builder) {
    builder
      .addCase(sendUserData.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        sendUserData.fulfilled,
        (state, { payload }: { payload: Partial<TUserSchema> }) => {
          state = payload;
          state.loading = false;
        }
      )
      .addCase(sendUserData.rejected, (state, { error }) => {
        state.loading = false;
        state.error = error.message;
      });
  },
});

export const { setOptions } = registerOptions.actions;

export default registerOptions.reducer;
