import "./my-results.scss";

import { Link } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Divider,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import Loading from "../../../components/loader/Loading";
import { useAppSelector } from "../../../context/hooks/hooks";
import useQueryResultsUser from "../../../utils/hooks/useQueryResultUser";

export default function MyResults() {
  const { data: results, isLoading, isRefetching } = useQueryResultsUser();
  const { userData } = useAppSelector((state) => state.auth);

  const countFirstPosition = results?.filter((result) => result.position_numeric === 1).length;
  const countSecondPosition = results?.filter((result) => result.position_numeric === 2).length;
  const countThirdPosition = results?.filter((result) => result.position_numeric === 3).length;

  return (
    <Container className="results-container">
      <Box className="results-top">
        <Link to="/profile" className="results-back-button">
          <Button className="results-back-button">
            <ChevronLeft /> Mis resultados
          </Button>
        </Link>
        <Select className="input-select results-select-field" defaultValue="Judo">
          <MenuItem value="Judo">Judo</MenuItem>
          <MenuItem value="Jiujitsu">Jiujitsu</MenuItem>
        </Select>
      </Box>
      <Avatar className="results-avatar" src={userData?.avatarUrl} />
      <Typography variant="h4" component="h4" className="results-name">
        {userData?.name} {userData?.surname}
      </Typography>
      {isLoading || isRefetching ? (
        <Loading />
      ) : results && results.length > 0 ? (
        <>
          <Box className="results-scores-container">
            <Box className="results-scores-details-container">
              <Typography variant="h4" component="h4" className="results-title">
                Puesto
              </Typography>

              <Typography variant="h4" component="h4" className="results-score-points">
                4
              </Typography>
            </Box>
            <Divider orientation="vertical" className="results-divider" />
            <Box className="results-scores-details-container">
              <Typography variant="h4" component="h4" className="results-title">
                Puntos
              </Typography>
              <Typography variant="h4" component="h4" className="results-points">
                {results.reduce((acc, result) => acc + Number(result.points ?? 0), 0)}
              </Typography>
            </Box>
          </Box>
          <Box className="results-medals-container">
            <Typography variant="h4" component="h4" className="results-title">
              Medallero
            </Typography>
            <Box className="results-medals-inner-container">
              <Box className="results-medal-container">
                <img
                  src="/images/extras/medalla_gold_1.png"
                  alt="medalla oro"
                  className="results-medal-img"
                />
                <Typography component="p" className="results-score-medal-score">
                  {countFirstPosition}
                </Typography>
              </Box>
              <Box className="results-medal-container">
                <img
                  src="/images/extras/medalla_silver_1.png"
                  alt="medalla oro"
                  className="results-medal-img"
                />
                <Typography component="p" className="results-score-medal-score">
                  {countSecondPosition}
                </Typography>
              </Box>
              <Box className="results-medal-container">
                <img
                  src="/images/extras/medalla_bronce_1.png"
                  alt="medalla oro"
                  className="results-medal-img"
                />
                <Typography component="p" className="results-score-medal-score">
                  {countThirdPosition}
                </Typography>
              </Box>
            </Box>
          </Box>
          <Box className="results-last-scores-container">
            <Box className="results-last-scores-top">
              <Typography component="p" className="results-last-scores-title">
                Puntos últimos 12 meses
              </Typography>
            </Box>
            <Box className="results-last-scores-table-container">
              <TableContainer className="results-last-scores-table-container">
                <Table size="small" className="results-last-scores-table">
                  <TableBody>
                    {results?.map((result) => (
                      <TableRow key={result.id}>
                        <TableCell className="results-table-cell">{result.position}</TableCell>
                        <TableCell className="results-table-cell">
                          {result.points ?? 0} pts
                        </TableCell>
                        <TableCell className="results-table-cell">{result.event_name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Box>
        </>
      ) : (
        <Typography component="p" className="results-no-results">
          No hay resultados disponibles.
        </Typography>
      )}
    </Container>
  );
}
