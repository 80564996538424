import { useQuery } from "react-query";

import { RQ_KEY } from "../../constants/query";
import { getUserDataForInscription } from "../../services/users";
import { TInscriptionData } from "../schemas/inscription";

export default function useQueryUserInscription() {
  // return data
  return useQuery<TInscriptionData, Error>(
    [RQ_KEY.USER],
    () => getUserDataForInscription(),
    { refetchOnWindowFocus: false }
  );
}
