import { useEffect, useState } from "react";
import { Navigate, Route, Routes } from "react-router-dom";

import { createTheme, ThemeProvider } from "@mui/material/styles";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Layout from "./components/layout/Layout";
import { setUser } from "./context/auth/authSlice";
import { getFormOptions } from "./context/forms/formsActions";
import { useAppDispatch, useAppSelector } from "./context/hooks/hooks";
import Calendario from "./pages/calendario/Calendario";
import ClubInfo from "./pages/club/ClubInfo";
import EventDetail from "./pages/eventDetail/EventDetail";
import InscriptionDetail from "./pages/eventDetail/inscriptionDetail/InscriptionDetail";
import Events from "./pages/events/Events";
import FederationInfo from "./pages/federation/FederationInfo";
import ForgetPassword from "./pages/forgetPassword/ForgetPassword";
import Home from "./pages/home/Home";
import Login from "./pages/login/Login";
import NewPassword from "./pages/newPassword/NewPassword";
import MyDocs from "./pages/profile/myDocs/MyDocs";
import MyInscriptions from "./pages/profile/myInscriptions/MyInscriptions";
import MyResults from "./pages/profile/myResults/MyResults";
import PersonalData from "./pages/profile/personalData/PersonalData";
import Profile from "./pages/profile/Profile";
import SportSheet from "./pages/profile/sportSheet/SportSheet";
import Ranking from "./pages/ranking/Ranking";
import RegisterUser from "./pages/registerUser/RegisterUser";
import Results from "./pages/results/Results";
import SetPassword from "./pages/setPaswword/SetPassword";
import Terms from "./pages/terms/Terms";
import VerifyUser from "./pages/verifyApp/VerifyUser";
import ProtectedRoute from "./routes/protectedRoutes";
import { getUser } from "./services/users";
import Loading from "./components/loader/Loading";

function App() {
  const defaultTheme = createTheme();
  const dispatch = useAppDispatch();
  //NO QUITAR
  const { userInfo } = useAppSelector((state) => state.auth);
  let user = localStorage.getItem("user");
  const token = localStorage.getItem("token");
  const remember = localStorage.getItem("remember");
  const [isDispatchDone, setIsDispatchDone] = useState(false);

  useEffect(() => {
    if (user && remember) {
      const dispatchUser = async (localUser: string) => {
        const userInfo = JSON.parse(localUser);
        //Info of the user
        const userData = await getUser(userInfo.id);
        try {
          dispatch(setUser({ userInfo, token, userData }));
        } catch (error) {
          console.error("Error al despachar usuario:", error);
        } finally {
          setIsDispatchDone(true);
        }
      };
      dispatchUser(user);
    } else {
      setIsDispatchDone(true);
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      localStorage.removeItem("remember");
    }
  }, [dispatch, user, token]);

  useEffect(() => {
    dispatch(getFormOptions());
  }, [dispatch]);

  if (!isDispatchDone) {
    return <Loading />;
  }
  return (
    <ThemeProvider theme={defaultTheme}>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
        style={{ zIndex: "100001" }}
      />
      <Routes>
        <Route path="/" element={<ProtectedRoute component={<Layout />} />}>
          <Route path="/" element={<Home />} />
          <Route path="/calendar" element={<Calendario />} />
          <Route path="/ranking" element={<Ranking />} />
          <Route path="/events" element={<Events />} />
          <Route path="/results" element={<Results />} />
          <Route path="/events/:eventId" element={<EventDetail />} />
          <Route path="federation" element={<FederationInfo />} />
          <Route path="federation/:id" element={<FederationInfo />} />
          <Route path="/club" element={<ClubInfo />} />
          <Route path="/club/:id" element={<ClubInfo />} />
          <Route path="/pdo" element={<></>} />
          <Route
            path="/events/:eventId/inscription"
            element={
              <ProtectedRoute
                component={<InscriptionDetail />}
                isAllowed={!!user}
                redirectTo="/login"
              />
            }
          />
          <Route
            path="/profile"
            element={
              <ProtectedRoute component={<Profile />} isAllowed={!!user} redirectTo="/login" />
            }
          />
          <Route
            path="/profile/personal_data"
            element={
              <ProtectedRoute component={<PersonalData />} isAllowed={!!user} redirectTo="/login" />
            }
          />
          <Route
            path="/profile/my_docs"
            element={
              <ProtectedRoute component={<MyDocs />} isAllowed={!!user} redirectTo="/login" />
            }
          />
          <Route
            path="/profile/my_inscriptions"
            element={
              <ProtectedRoute
                component={<MyInscriptions />}
                isAllowed={!!user}
                redirectTo="/login"
              />
            }
          />
          <Route
            path="/profile/my_inscriptions/:inscriptionId"
            element={
              <ProtectedRoute
                component={<InscriptionDetail isRealized />}
                isAllowed={!!user}
                redirectTo="/login"
              />
            }
          />
          <Route
            path="/profile/my_results"
            element={
              <ProtectedRoute component={<MyResults />} isAllowed={!!user} redirectTo="/login" />
            }
          />
          <Route
            path="/profile/sport_sheet"
            element={
              <ProtectedRoute component={<SportSheet />} isAllowed={!!user} redirectTo="/login" />
            }
          />
        </Route>
        <Route path="/login" element={<ProtectedRoute isAllowed={!user} component={<Login />} />} />
        <Route
          path="/forget-password"
          element={<ProtectedRoute isAllowed={!user} component={<ForgetPassword />} />}
        />
        <Route
          path="/reset_password"
          element={<ProtectedRoute isAllowed={!user} component={<NewPassword />} />}
        />
        <Route
          path="/verify-app"
          element={<ProtectedRoute isAllowed={!user} component={<VerifyUser />} />}
        />
        {/* user from dashboard */}
        <Route
          path="/verify"
          element={<ProtectedRoute isAllowed={!user} component={<SetPassword />} />}
        />
        <Route
          path="/register-user"
          element={<ProtectedRoute isAllowed={!user} component={<RegisterUser />} />}
        />
        {/* <Route
          path="/verify-register-complete"
          element={<ProtectedRoute isAllowed={!user} component={<VerifyRegisterComplete />} />}
        /> */}
        <Route path="/terms" element={<ProtectedRoute component={<Terms />} />} />
        <Route path="/*" element={<Navigate to="/" />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
