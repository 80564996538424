import "./sport-sheet.scss";

import { Fragment } from "react";
import { Link } from "react-router-dom";

import { ChevronLeft } from "@mui/icons-material";
import {
  Avatar,
  Box,
  Container,
  Divider,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
  useMediaQuery,
} from "@mui/material";

import Button from "../../../components/button/Button";
import Loading from "../../../components/loader/Loading";
import { GENRES } from "../../../constants/constants";
import { useAppSelector } from "../../../context/hooks/hooks";
import useQuerySportCard from "../../../utils/hooks/useQuerySportCard";
import { mockUser } from "../../../utils/mocks/user";

function SportSheet() {
  const matches = useMediaQuery("(max-width:769px)");
  const { userInfo, userData } = useAppSelector((state) => state.auth);

  const { data: user, isLoading, isRefetching } = useQuerySportCard(userInfo?.id || "");

  const { countryCode, sports } = mockUser;
  return (
    <Container className="sport-sheet-container">
      <Link to="/profile" className="sport-sheet-back-button">
        <Button className="sport-sheet-back-button">
          <ChevronLeft /> Ficha deportista
        </Button>
      </Link>
      {isLoading || isRefetching ? (
        <Loading />
      ) : user ? (
        <Box
          className="sport-sheet-inner-container"
          sx={{ borderRadius: matches ? 0 : 2, padding: matches ? 2 : 4 }}
          component={matches ? Box : Paper}
        >
          <Grid container columnSpacing={4} rowSpacing={2}>
            <Grid item md={4} xs={12}>
              <Avatar className="sport-sheet-avatar" src={userData?.avatarUrl} />
            </Grid>
            <Grid item md={4} xs={12}>
              <TableContainer>
                <Table className="sport-sheet-profile-table">
                  <TableBody>
                    <TableRow>
                      <TableCell
                        className="sport-sheet-table-title"
                        sx={{ borderBottom: matches ? 0 : 1 }}
                      >
                        Apellidos:
                      </TableCell>
                      <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                        {user?.userInfo?.surname}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="sport-sheet-table-title"
                        sx={{ borderBottom: matches ? 0 : 1 }}
                      >
                        Nombre:
                      </TableCell>
                      <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                        {user?.userInfo?.name}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="sport-sheet-table-title"
                        sx={{ borderBottom: matches ? 0 : 1 }}
                      >
                        Genero
                      </TableCell>
                      <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                        {user?.userInfo?.genre === GENRES.MEN ? "Masculino" : "Femenino"}
                      </TableCell>
                    </TableRow>
                    <TableRow>
                      <TableCell
                        className="sport-sheet-table-title"
                        sx={{ borderBottom: matches ? 0 : 1 }}
                      >
                        Edad:
                      </TableCell>
                      <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                        {user?.userInfo?.age}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
            <Grid item md={4} xs={12}>
              <Box className="sport-sheet-flag-container">
                {/* <img
                  src={`/images/flags/${countryCode.toLowerCase()}_h.png`}
                  alt="flag"
                  className="sport-sheet-flag"
                /> */}
                <Link
                  to={`/federation/${user?.userInfo.federation_id}`}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  {" "}
                  Federación: {user?.userInfo.federation_code}{" "}
                </Link>{" "}
                <Link
                  to={`/club/${user?.userInfo.club_id}`}
                  style={{ textDecoration: "none", color: "#000" }}
                >
                  Club: {user?.userInfo.club_code}
                </Link>
              </Box>
            </Grid>
          </Grid>
          <Divider className="sport-sheet-divider" />
          {user.resultsData && user.resultsData.length > 0 ? (
            <>
              <Typography>Deportes</Typography>
              <Box className="sport-sheet-sport-table-container">
                {user.resultsData.map((sport, index) => (
                  <Fragment key={`${sport.sport} - ${index}`}>
                    <TableContainer className="sport-sheet-inner-sport-table">
                      <Table className="sport-sheet-sport-table">
                        <TableBody>
                          <TableRow>
                            <TableCell
                              className="sport-sheet-table-title"
                              sx={{ borderBottom: matches ? 0 : 1 }}
                            >
                              Deporte:
                            </TableCell>
                            <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                              {sport.sport}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="sport-sheet-table-title"
                              sx={{ borderBottom: matches ? 0 : 1 }}
                            >
                              Categoría:
                            </TableCell>
                            <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                              {sport.weights.join(", ")}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="sport-sheet-table-title"
                              sx={{ borderBottom: matches ? 0 : 1 }}
                            >
                              Cinturón:
                            </TableCell>
                            <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                              {sport.belt}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              className="sport-sheet-table-title"
                              sx={{ borderBottom: matches ? 0 : 1 }}
                            >
                              Club:
                            </TableCell>
                            <TableCell sx={{ borderBottom: matches ? 0 : 1 }}>
                              {sport.club_code}
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell className="sport-sheet-table-title" sx={{ border: 0 }}>
                              Posición Ranking:
                            </TableCell>
                            <TableCell sx={{ border: 0 }}>
                              {/* {sport.position.map((p) => (
                                <p key={p.category}>
                                  {p.category} {p.position} lugar ({p.points} pts)
                                </p>
                              ))} */}
                            </TableCell>
                          </TableRow>
                        </TableBody>
                      </Table>
                    </TableContainer>
                    {matches && sports.length - 1 !== index && (
                      <Divider className="sport-sheet-divider" />
                    )}
                  </Fragment>
                ))}
              </Box>
            </>
          ) : null}
        </Box>
      ) : (
        <Typography>No se encontraron datos</Typography>
      )}
    </Container>
  );
}

export default SportSheet;
