import { createAsyncThunk } from "@reduxjs/toolkit";
import bcrypt from "bcryptjs";

import { API_URL, SALT, SERVICE } from "../../constants/api";
import http from "../../constants/axios";
import { TInscription } from "../../utils/schemas/inscription";
import { TUserSchema } from "../../utils/schemas/register";
import { TUser } from "../../utils/schemas/user";

export const getFormOptions = createAsyncThunk("forms/options", async (_, { rejectWithValue }) => {
  try {
    const request = await http.get(`${API_URL}${SERVICE.All_UTILS}`);
    const response = await request.data;
    return { ...response };
  } catch (error) {
    return rejectWithValue("Error en servidor");
  }
});

//Inscriptions
export const sendUserInscriptionData = createAsyncThunk(
  "forms/sendUserInscriptionData",
  async (data: TInscription, { rejectWithValue }) => {
    try {
      const request = await http.post(`${API_URL}${SERVICE.INSCRIPTIONS}`, data);
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const confirmInscription = createAsyncThunk(
  "forms/confirmInscription",
  async ({ body, inscriptionId }: { body: any; inscriptionId: string }, { rejectWithValue }) => {
    try {
      const request = await http.put(
        `${API_URL}${SERVICE.INSCRIPTIONS}/validate/${inscriptionId}`,
        body
      );
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const changeCategory = createAsyncThunk(
  "forms/options",
  async ({ data, id }: { data: { newCategoryId: string }; id: string }, { rejectWithValue }) => {
    try {
      const request = await http.put(
        `${API_URL}${SERVICE.INSCRIPTIONS}/change-category/${id}`,
        data
      );
      const response = await request.data;
      return response;
    } catch (error: any) {
      return rejectWithValue(error.response.data.error ?? "Error en servidor");
    }
  }
);

//Users
export const registerUser = createAsyncThunk(
  "forms/registerUser",
  async (data: TUserSchema, { rejectWithValue }) => {
    try {
      const dataToSend = data;
      dataToSend.is_active = 0;
      const request = await http.post(`${API_URL}${SERVICE.USERS}`, dataToSend);
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const updateUserData = createAsyncThunk(
  "forms/updateUserData",
  async ({ data, id }: { data: TUser; id: string }, { rejectWithValue }) => {
    try {
      const dataToSend = data;
      dataToSend.isActive = dataToSend.isActive === 1;
      const request = await http.put(`${API_URL}${SERVICE.USERS}/${id}`, dataToSend);
      const response = await request.data;
      return response;
    } catch (error) {
      return rejectWithValue("Error en servidor");
    }
  }
);

export const resetPassword = createAsyncThunk(
  "auth/resetPassword",
  async (data: { password: string; token: string }, { rejectWithValue }) => {
    try {
      const bodyToSend = {
        password: bcrypt.hashSync(data.password, SALT),
        token: data.token,
      };
      const request = await http.post(`${API_URL}${SERVICE.RESET_PASSWORD}`, bodyToSend);
      return request.data;
    } catch (error) {
      return rejectWithValue(error ?? "Error al cambiar la contraseña");
    }
  }
);

export const forgetPassword = createAsyncThunk(
  "auth/forgetPassword",
  async (data: { email: string; site_verification_url: string }, { rejectWithValue }) => {
    try {
      const request = await http.post(`${API_URL}${SERVICE.FORGET_PASSWORD}`, data);
      return request.data;
    } catch (error) {
      console.log("Error", error);
      return rejectWithValue("Email no encontrado");
    }
  }
);
