import "./ranking-card.scss";

import { Link } from "react-router-dom";

import {
  Box,
  Container,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from "@mui/material";

import Button from "../../../components/button/Button";
import Loading from "../../../components/loader/Loading";
import { CATEGORY_NAMES, GENRES } from "../../../constants/constants";
import { useAppSelector } from "../../../context/hooks/hooks";
import { ROUTE_LINKS } from "../../../routes/route_links";
import { dateToFullDateString } from "../../../utils/date";
import { TRanking, TRankingFilters } from "../../../utils/schemas/rankings";

type RankingCardProps = {
  name: string;
  date?: Date;
  list: TRanking[] | undefined;
  rankingFilters: TRankingFilters;
  handleFilterChange: (
    genre: string,
    filter: keyof TRankingFilters,
    value: string | undefined | null | number
  ) => void;
  isLoading: boolean;
  isRefetching: boolean;
  handleChangeCategory: (id: string, genre: string) => void;
  selectedCategory: string | null;
  genre: string;
};

function RankingCard({
  name,
  date = new Date(),
  list,
  handleFilterChange,
  rankingFilters,
  isLoading,
  isRefetching,
  handleChangeCategory,
  selectedCategory,
  genre,
}: RankingCardProps) {
  const { categories, sports } = useAppSelector((state) => state.formsOptions);

  const getNumericWeight = (weight: string) => {
    const numericPart = weight.match(/[\d.]+/);
    return numericPart ? parseFloat(numericPart[0]) : 0;
  };

  const menCategories = categories
    ?.filter(
      (category) =>
        category.genre === GENRES.MEN &&
        category.sportCode === rankingFilters.sportCode &&
        category.categoryName === rankingFilters.category
    )
    .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight));
  const womenCategories = categories
    ?.filter(
      (category) =>
        category.genre === GENRES.WOMEN &&
        category.sportCode === rankingFilters.sportCode &&
        category.categoryName === rankingFilters.category
    )
    .sort((a, b) => getNumericWeight(a.weight) - getNumericWeight(b.weight));

  return (
    <Container className="ranking-card-container">
      <Box className="ranking-card-header">
        <Box className="ranking-card-header-top">
          <Box className="ranking-card-header-titles">
            <Typography component="h4" className="ranking-card-name">
              {name}
            </Typography>
            <Typography component="h4" className="ranking-card-date">
              Version: {dateToFullDateString(date)}
            </Typography>
          </Box>
          <Box className="ranking-card-filters">
            <Select
              className="input-select ranking-card-select-field"
              defaultValue={""}
              value={rankingFilters["sportCode"]}
              onChange={(e) => handleFilterChange(genre, "sportCode", e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Deporte: Todos</MenuItem>
              {sports?.map((sport) => (
                <MenuItem value={sport.code} key={sport.id}>
                  {sport.name}
                </MenuItem>
              ))}
            </Select>
            <Select
              className="input-select ranking-card-select-field"
              defaultValue={""}
              value={rankingFilters["category"]}
              onChange={(e) => handleFilterChange(genre, "category", e.target.value)}
              fullWidth
              displayEmpty
            >
              <MenuItem value="">Categoría: Todas</MenuItem>
              {CATEGORY_NAMES?.map((sport) => (
                <MenuItem value={sport} key={sport}>
                  {sport}
                </MenuItem>
              ))}
            </Select>
            <Select
              className="input-select ranking-card-select-field"
              defaultValue=""
              value={selectedCategory}
              fullWidth
              displayEmpty
              onChange={(e) => handleChangeCategory(e.target.value ?? "", genre)}
            >
              <MenuItem value="">Peso: Todos</MenuItem>
              {rankingFilters.genre === GENRES.MEN &&
                menCategories?.map((category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.weight}
                  </MenuItem>
                ))}
              {rankingFilters.genre === GENRES.WOMEN &&
                womenCategories?.map((category) => (
                  <MenuItem value={category.id} key={category.id}>
                    {category.weight}
                  </MenuItem>
                ))}
            </Select>
          </Box>
        </Box>
      </Box>
      <Box className="ranking-card-table-container">
        {isLoading || isRefetching ? (
          <Loading />
        ) : list && list.length > 0 ? (
          <TableContainer>
            <Table className="ranking-card-table" size="small">
              <TableBody>
                {list?.map((row, index) => (
                  <TableRow
                    key={row.name}
                    className="ranking-card-table-row"
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      {index + 1}
                    </TableCell>
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      {row.weight}
                    </TableCell>
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      {row.name}
                    </TableCell>
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      {row.surname}
                    </TableCell>
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      <Link
                        to={`/federation/${row.federationId}`}
                        style={{ textDecoration: "none", color: "#000" }}
                      >
                        {row.federationShortCode}
                      </Link>
                    </TableCell>
                    <TableCell className="ranking-card-table-cell" size="small" align="center">
                      {row.score} pts
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography>No se han encontrado resultados</Typography>
        )}
      </Box>
      <Box className="ranking-card-see-more">
        <Link to={ROUTE_LINKS.RANKING} className="ranking-card-see-more-button">
          <Button width="full-width" rounded="none">
            VER MAS
          </Button>
        </Link>
      </Box>
    </Container>
  );
}

export default RankingCard;
