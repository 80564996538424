import axios from "axios";
import { API_URL } from "./api";

const http = axios.create({
  baseURL: API_URL,
  timeout: 4000,
  headers: { Accept: "application/json" },
});

export function setHttpBearerToken(token: string) {
  if (token !== "") {
    http.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  } else {
    delete http.defaults.headers.common["Authorization"];
  }
}

// Para la permanencia del header cuando se recarga la página
const storedToken = localStorage.getItem("token");
if (storedToken) {
  setHttpBearerToken(storedToken);
}

// Función para obtener el token de alguna forma, por ejemplo, desde el localStorage
const getToken = () => localStorage.getItem("token");

http.interceptors.request.use(
  (config) => {
    const token = getToken();
    if (token) {
      config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default http;
